import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
// import logo from '../../Images/Menu/logo.svg';
import NavigationMenu from '../../Components/NavigationMenu';
import AreasForm from './AreasForm';
import AreaBlock from './AreaBlock';
import '../Service/service.css'
import ModalDelete from '../../Components/ModalDelete';

const AreasPage = ({accessToken}) => {

  // const navigate = useNavigate();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  const handleButtonClick = () => {
    setIsFormOpen(!isFormOpen);
    setIsFormVisible(false);
  };

  const handleTransitionEnd = () => {
    if (isFormOpen) {
      setIsFormVisible(true);
    }
  };

  const handleFormSubmit = () => {
    // getDirections();
  };

  return(
    <>
      <div className='body-wrapper'>
      <div className={isMobile ? 'page-flex-wrapper page-flex-wrapper-mobile' : 'page-flex-wrapper'}>
        <NavigationMenu currentPage='service' accessToken={accessToken}/>
        <main className={isMobile ? 'main-index__wrapper-mobile service-main-index' : 'service-main-index'}>
        <section className='service-container'>
            <section className='directions-container'>
              <div className='direction-general-info'>
                <h1>Участки</h1>
                <div style={{backgroundColor: '#9B69BA'}} className='direction-item-areas'>УГР</div>
                <button className='button-change-view'></button>
              </div>
              <div className={`areas-wrapper ${isFormOpen ? 'collapsed' : ''}`}>
                <AreaBlock onDeleteClick={handleOpenModal}/>
                <AreaBlock onDeleteClick={handleOpenModal}/>
                <AreaBlock onDeleteClick={handleOpenModal}/>
                <AreaBlock onDeleteClick={handleOpenModal}/>
              </div>
            </section>
        </section>
        <div className='button-form-wrapper'>
        <Link to='/service' className='button-close-areas'></Link>
        <button className={`button-add-direction button-add-direction-areas ${isFormOpen ? 'open' : ''}`} onClick={handleButtonClick} onTransitionEnd={handleTransitionEnd}>+</button>
        </div>

          {isFormVisible && (
            <>
              <button className='button-close-form button-close-form-areas' onClick={handleButtonClick}></button>
              <AreasForm onFormSubmit={handleFormSubmit} accessToken={accessToken} onClose={handleButtonClick}/>
            </>
          )
          }
          {
            isModalOpen && <ModalDelete message='Удалить участок?' action='Удалить' onClose={handleCloseModal}/>
          }
        </main>
      </div>
      </div>
    </>
  )
}
export default AreasPage;
