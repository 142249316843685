import apiConfig from "../../../apiConfig.json";

export const getAllProjects = (accessToken) => {
  // console.log('да не легко')
  return fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_ALL_PROJECTS}`, {
    method: "GET",
    headers: {
      'Authorization': `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
      'Content-Type': 'application/json',
    }
  })
  .then( (response) => response.json() ) 
  .then(data => {
    console.log(data.projects)
    return data.projects
  })
  .catch(error => {
    console.log(error)
    return [] 
  })
};
