import { useState, useRef, useEffect } from "react";
import {
  CloseBtn,
  EditBtn,
  DeleteBtn,
  ExpandBtn,
} from "@widgets/buttons/index";
// import { getImageForTaskType } from "@entities/task/index";
import { statuses } from "@entities/task/index";
import { Doughnut } from "react-chartjs-2";

export const DetailedTask = (props) => {
  const { onClose } = props;

  const [status, setStatus] = useState("planned");
  const [isStatusListExpanded, setIsStatusListExpanded] = useState(false);

  const onToggleList = () => {
    setIsStatusListExpanded((p) => !p);
  };

  const groupedData = [];
  const currentDate = new Date();
  const TASK = {
    startDate: "7.7.2024",
    endDate: "12.7.2024",
    doneApproved: "25%",
    done: "50%",
  };
  // const groupedData = user.shiftsInfo.reduce((acc, { shiftDate, tripNum }) => {
  //   if (!acc[shiftDate]) {
  //       acc[shiftDate] = 0;
  //   }
  //   acc[shiftDate] += tripNum;
  //   return acc;
  // }, {});

  const uniqueProductiveDays = [...new Set(Object.keys(groupedData))];

  const totalDaysInLastThreeMonths = [];
  for (let i = 0; i < 3; i++) {
    const month = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i,
      1
    );
    const daysInMonth = new Date(
      month.getFullYear(),
      month.getMonth() + 1,
      0
    ).getDate();
    totalDaysInLastThreeMonths.push(daysInMonth);
  }

  const totalDaysThreeMonth = totalDaysInLastThreeMonths.reduce(
    (acc, days) => acc + days,
    0
  );

  const productivePercent =
    (uniqueProductiveDays.length / totalDaysThreeMonth) * 100;
  const unproductivePercent = 100 - productivePercent;

  const DATA = {
    labels: ["Продуктивные дни", "Непродуктивные дни"],
    datasets: [
      {
        label: "Продуктивность",
        data: [productivePercent.toFixed(2), unproductivePercent.toFixed(2)],
        backgroundColor: ["#F0D07E", "#F0D07EB2"],
        hoverBackgroundColor: ["#F0D07E", "#F0D07EB2"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: "65%",
    plugins: {
      datalabels: {
        formatter: (value) => `${value}%`,
        color: "#211B27CC",
        font: {
          weight: "normal",
          size: 9,
        },
      },
      legend: {
        display: false,
        position: "top",
      },
    },
  };

  useEffect(() => {
    const createChart = () => {
      const task = document.querySelector(".detailed-task-progress-bar__bar");
      // const minLineHeight = chartWrapperHeight / tasksCount;

      // const left = filteredArrayStart[0].offsetLeft + 1;
      const width = '300'
      // filteredArrayEnd[0].offsetLeft + filteredArrayEnd[0].offsetWidth - left - 42;

      // el.style.left = `${left}px`;
      task.style.width = `${width}px`;
      task.style.backgroundColor = task.dataset.color;
      task.style.opacity = 1;

      const progressBar = task.querySelector(".task-progress-bar");
      const progressPercent = parseInt(task.dataset.done) || 0;
      // console.log(`${(width * progressPercent) / 100}px`)
      if (progressBar) {
        progressBar.style.width = `${(width * progressPercent) / 100}px`;
        progressBar.style.backgroundColor = task.dataset.donecolor;
      }

      const progressBarApproved = task.querySelector(
        ".task-progress-bar-approved"
      );
      const progressPercentApproved = parseInt(task.dataset.doneapproved) || 0;
      if (progressBarApproved) {
        progressBarApproved.style.width = `${
          (width * progressPercentApproved) / 100
        }px`;
        progressBarApproved.style.backgroundColor =
          task.dataset.doneapprovedcolor;
      }
    };
    createChart();
    window.addEventListener("resize", createChart);
    return () => {
      window.removeEventListener("resize", createChart);
    };
  }, []);

  return (
    <div className="modal-container" style={{ width: "100%", height: "100%" }}>
      <div className="detailed-task modal-container__wrapper">
        <header className="detailed-task-header">
          <div className="detailed-task-header-status">
            <div
              className={`detailed-task-header-status__field ${
                isStatusListExpanded &&
                "detailed-task-header-status__field_expanded"
              }`}
            >
              <div className={`status-icon status-icon_white__${status}`}></div>
              {/* <img src={getImageForTaskType(status)} alt="" className="detailed-task-header-status__img" /> */}
              <span className="detailed-task-header-status__text">
                Запланировано
              </span>
              <ExpandBtn
                blackOrWhite="white"
                onClick={onToggleList}
                style={{ width: "2rem" }}
              />
              {/* <button className="expand-button"></button> */}
            </div>

            {isStatusListExpanded && (
              <>
                <ul className="detailed-task-header-status__list">
                  {Object.keys(statuses).map((item) => (
                    <>
                      {status !== item && (
                        <li
                          key={item}
                          className="detailed-task-header-status__item"
                        >
                          <div
                            className={`status-icon status-icon_white__${item}`}
                          ></div>
                          {/* <img src={getImageForTaskType(item)} alt="" className="detailed-task-header-status__img" /> */}
                          {statuses[item]}
                        </li>
                      )}
                    </>
                  ))}
                </ul>
              </>
            )}
          </div>

          <div className="detailed-task-header__middle">
            <span className="detailed-task-header__name">
              Разработка грунта ковшом
            </span>
            <EditBtn
              style={{ width: "3.5rem", height: "3.5rem", marginRight: "1rem" }}
            />
            <DeleteBtn style={{ width: "3.5rem", height: "3.5rem" }} />
          </div>

          <CloseBtn onClick={onClose} />
        </header>

        <main className="detailed-task-main">
          <section className="detailed-task-section">
            <div className="detailed-task-section__block">
              <div className="detailed-task-section__inner-block">
                <span className="detailed-task-district detailed-task_box-shadow">
                  ДН-1
                </span>
                <div className="detailed-task-dates">
                  <span className="detailed-task-dates__start-date">
                    25.10.2024
                  </span>
                  <span className="detailed-task-dates__end-date">
                    25.10.2224
                  </span>
                </div>
              </div>
            </div>

            <div className="detailed-task-section__block detailed-task-staff">
              <ul className="detailed-task-staff__list">
                <li className="detailed-task-staff__item detailed-task_box-shadow">
                  <img
                    src=""
                    alt=""
                    className="detailed-task-staff__avatar"
                    width="25px"
                    height="25px"
                  />
                  <span className="detailed-task-staff__name">
                    Админов А.А.
                  </span>
                </li>
                <li className="detailed-task-staff__item detailed-task_box-shadow">
                  <img
                    src=""
                    alt=""
                    className="detailed-task-staff__avatar"
                    width="25px"
                    height="25px"
                  />
                  <span className="detailed-task-staff__name">
                    Админов А.А.
                  </span>
                </li>
                <li className="detailed-task-staff__item detailed-task_box-shadow">
                  <img
                    src=""
                    alt=""
                    className="detailed-task-staff__avatar"
                    width="25px"
                    height="25px"
                  />
                  <span className="detailed-task-staff__name">
                    Админов А.А.
                  </span>
                </li>
              </ul>

              <div className="detailed-task-staff__color-legend">
                <div className="">
                  <div
                    className="detailed-task-staff__color-legend-color"
                    style={{ backgroundColor: "#FFEFC5" }}
                  />
                  <span className="detailed-task-staff__color-legend-desc">
                    — сотрудники, закреплённые к другим задачам
                  </span>
                </div>
                <div className="">
                  <div
                    className="detailed-task-staff__color-legend-color"
                    style={{ backgroundColor: "#716380" }}
                  />
                  <span className="detailed-task-staff__color-legend-desc">
                    — проверяющие лица
                  </span>
                </div>
              </div>
            </div>

            <div className="detailed-task-section__block">
              <div className="detailed-task-section__inner-block">
                <div className="detailed-task-location">
                  <h3 className="detailed-task-section__header">Участок</h3>
                  <span className="detailed-task-location__district">
                    Стланник
                  </span>
                </div>
                <div className="detailed-task-section__geo">
                  <h3 className="detailed-task-section__header">Объект</h3>
                  <span className="detailed-task-location__facility">
                    Этыркен-Алонка
                  </span>
                </div>
              </div>
            </div>

            <div className="detailed-task-section__block detailed-task-status">
              <h3 className="detailed-task-section__header">Тип задачи</h3>
              <span className="detailed-task-type__type">Некий тип задачи</span>
            </div>

            <div className="detailed-task-section__block detailed-task-description">
              <h3 className="detailed-task-section__header">Тип задачи</h3>
              <span className="detailed-task-description__description">
                Очень длинное и подробное описание, как говорится, данной задачи
              </span>
            </div>

            <div className="detailed-task-section__block detailed-task-equipments">
              <ul className="detailed-task-equipments__list">
                <li className="detailed-task-equipments__item detailed-task_box-shadow">
                  <div className="detailed-task-equipments__icon" />
                  <span className="detailed-task-equipments__number">1223</span>
                </li>
              </ul>

              <div className="detailed-task-staff__color-legend">
                <div className="">
                  <div
                    className="detailed-task-staff__color-legend-color"
                    style={{ backgroundColor: "#FFEFC5" }}
                  />
                  <span className="detailed-task-staff__color-legend-desc">
                    — оборудование, закреплённое к другим задачам
                  </span>
                </div>
              </div>
            </div>

            {/* <div className="detailed-task-section__block">
              <button className="detailed-task-gpr-button">Дополнительная информация в ГПР</button>
            </div> */}
          </section>

          <section className="detailed-task-section">
            <div className="detailed-task-section__block">
              <div className="detailed-task-section__inner-block">
                <h3 className="detailed-task-section__header">Глава</h3>
                <span className="detailed-task-location__value">83664</span>
                <h3 className="detailed-task-section__header">Раздел</h3>
                <span className="detailed-task-location__value">83664</span>

                <div className="detailed-task-documentation-buttons">
                  <button className="detailed-task-documentation-button detailed-task_box-shadow">
                    ГПР
                  </button>
                  <button className="detailed-task-documentation-button detailed-task_box-shadow">
                    КС-6
                  </button>
                </div>
              </div>
            </div>

            <div className="detailed-task-section__block">
              <div className="detailed-task-section__inner-block">
                <div className="">
                  <h3 className="detailed-task-section__header">Кол-во</h3>
                  <span className="detailed-task-sevtion__value">83664</span>
                </div>

                <div className="">
                  <h3 className="detailed-task-section__header">Ед. изм.</h3>
                  <span className="detailed-task-location__value">м3</span>
                </div>

                <div className="">
                  <h3 className="detailed-task-section__header">
                    Стоимость за ед.
                  </h3>
                  <span className="detailed-task-location__value">
                    1 476,00
                  </span>
                </div>

                <div className="">
                  <h3 className="detailed-task-section__header">
                    Стоимость всего
                  </h3>
                  <span className="detailed-task-location__value">
                    123 488 064,00
                  </span>
                </div>
              </div>
            </div>

            <div className="detailed-task-section__block">
              <div className="detailed-task-section__inner-block">
                <div className="">
                  <h3 className="detailed-task-section__header">
                    Закрыто по КС-6а
                  </h3>
                  <span className="detailed-task-sevtion__value">6000</span>
                </div>

                <div className="">
                  <h3 className="detailed-task-section__header">
                    Остаток по КС-6а
                  </h3>
                  <span className="detailed-task-location__value">—</span>
                </div>
              </div>
            </div>

            <div className="detailed-task-section__block detailed-task-extra-info">
              <h3 className="detailed-task-section__header">
                Номер работ из реестра
              </h3>
              <span className="detailed-task-sevtion__value">
                00-02-2-01-03Р;
              </span>
            </div>

            <div className="detailed-task-section__block detailed-task-extra-info">
              <h3 className="detailed-task-section__header">
                Дополнительная информация о задаче
              </h3>
              <span className="detailed-task-sevtion__value">
                Плечо вывоза: 29; Емкость: 15; Кол-во самосвалов за сутки: 20;
                Норма рейсов в смену: 4; Кол-во рейсов в сутки: 56; Планируемый
                вывоз с/г м3 с карьера: 840; Среднее выполнение: 726,000;
                Необходимое кол-во дней для завершения СМР: 9;
              </span>
            </div>

            <div className="detailed-task-section__block detailed-task-current-month-plans">
              <h3 className="detailed-task-section__header">
                План на текущий месяц
              </h3>
              <div className="detailed-task-section__inner-block">
                <div className="detailed-task-setion__inner-block_column">
                  <div className="detailed-task-section__inner-block">
                    <div className="information-container__productivity detailed-task-setion__inner-block_column">
                      <div className="information-container__doughnut-wrapper"
                      style={{ width: "123px", height: "123px" }}>
                        <Doughnut
                          data={DATA}
                          options={options}
                          height={150}
                          width={150}
                        />
                        <div className="information-container__doughnut-info doughnut-info">
                          <p className="doughnut-info__num">
                            {uniqueProductiveDays.length}
                          </p>
                          <p>вып./отставание от плана</p>
                        </div>
                      </div>
                    </div>

                    <div className="detailed-task-setion__inner-block_column">
                      <div className="">
                        <h3 className="detailed-task-section__header detailed-task-current-month-plans__header">
                          В количественном выражении
                        </h3>
                      </div>

                      <div className="" style={{ marginBottom: "1.2rem" }}>
                        <h3 className="detailed-task-section__header detailed-task-current-month-plans__header">
                          Запланировано
                        </h3>
                        <span className="detailed-task-location__value">
                          16000,00
                        </span>
                      </div>

                      <div className="">
                        <h3 className="detailed-task-section__header detailed-task-current-month-plans__header">
                          Выполнено
                        </h3>
                        <span className="detailed-task-location__value">
                          2705,05
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="detailed-task-setion__inner-block_column">
                  <div className="detailed-task-section__inner-block">
                    <div className="detailed-task-setion__inner-block_column">
                      <div
                        className="information-container__doughnut-wrapper"
                        style={{ width: "123px", height: "123px" }}
                      >
                        <Doughnut
                          data={DATA}
                          options={options}
                          height={150}
                          width={150}
                        />
                        <div className="information-container__doughnut-info doughnut-info">
                          <p className="doughnut-info__num">
                            {uniqueProductiveDays.length}
                          </p>
                          <p>вып./отставание от плана</p>
                        </div>
                      </div>
                    </div>

                    <div className="detailed-task-setion__inner-block_column">
                      <div className="">
                        <h3 className="detailed-task-section__header detailed-task-current-month-plans__header">
                          В денежном выражении
                        </h3>
                      </div>

                      <div className="" style={{ marginBottom: "1.2rem" }}>
                        <h3 className="detailed-task-section__header detailed-task-current-month-plans__header">
                          Запланировано
                        </h3>
                        <span className="detailed-task-location__value">
                          16000,00
                        </span>
                      </div>

                      <div className="">
                        <h3 className="detailed-task-section__header detailed-task-current-month-plans__header">
                          Выполнено
                        </h3>
                        <span className="detailed-task-location__value">
                          2705,05
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="detailed-task-section__block detailed-task-progress-bar">
              <h3 className="detailed-task-section__header">Выполнено</h3>
              <div className="detailed-task-section__inner-block">
                <div
                  className="detailed-task-progress-bar__bar"
                  data-duration={`${TASK.startDate}-${TASK.endDate}`}
                  data-color={"#D9D9D9"}
                  data-donecolor={"#8869BA80"}
                  data-doneapprovedcolor={"#8869BA"}
                  data-done={TASK.done}
                  data-doneapproved={TASK.doneApproved}
                >
                  <div className="task-progress-bar">
                    <p>{TASK.done}</p>
                  </div>
                  <div className="task-progress-bar-approved">
                    <p>{TASK.doneApproved}</p>
                  </div>
                </div>
                <p>{TASK.doneApproved}</p>
              </div>
            </div>

            <div className="detailed-task-goto-buttons">
              <button
                className="detailed-task-goto-button goto-task-in-graph"
                onClick={onToggleList}
              />
              <button
                className="detailed-task-goto-button goto-contributon"
                onClick={onToggleList}
              />
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};
