import NavigationMenu from "../../../Components/NavigationMenu"
import { SwitcherBetweenPages } from "@widgets/SwitcherBetweenPages/index"
// import { SearchField } from "@widgets/search-field/ui/SearchField"
import { SearchField } from "@widgets/search-field/index"
import '../../Documentation/ui/_documentation-page.scss'
import '@shared/styles/_all.scss'
import { useState, useEffect, useCallback } from "react"
import { useSearchParams } from "react-router-dom"
import apiConfig from '../../../apiConfig.json'



const DocumentationSamplePage = ({accessToken}) => {

  const [searchParams] = useSearchParams()
  const [projectId, setProjectId] = useState('')
  const [projectName, setProjectName] = useState('')

  //
  const [projectsExpanded, setProjectsExpanded] = useState(false);
  const [tasksState, setTasksState] = useState({
    loading:false,
    projectInfo: null
  })
  const [allProjectState, setAllProjectState] = useState({
    loading: true,
    allProjectInfo: { projects: [] },
  });
  const [currentProjectId, setCurrentProjectId] = useState('');
  const [addChapterInputOpen, setAddChapterInputOpen] = useState(false);
  const [newChapterName, setNewChapterName] = useState('');
  const [newSectionName, setNewSectionName] = useState('');
  const initialTaskState = {
      start: "2025-01-16T16:42:12.502Z",
      end: "2025-01-16T16:42:12.502Z",
      qualifier: "PROJECT",
      qualifier_id: `${currentProjectId}`,
      header: "",
      work_number: "",
      description: "",
      estimated_volume: 0,
      monthly_volume: 0,
      volume_metrics: "",
      metric_cost: 0,
      total_cost: 0,
      equipments: [
        0
      ],
      performers: [
        "string"
      ],
      evaluators: [
        "string"
      ],
      additional_info: {
        "additionalProp1": "string",
        "additionalProp2": "string",
        "additionalProp3": "string"
      }
  };
  const [newTask, setNewTask] = useState(initialTaskState);
  const [addSectionInputOpen, setAddSectionInputOpen] = useState(null);
  const [addTaskOpen, setAddTaskOpen] = useState(null);

  const TABLEHEADER = [
    'Дата начала',
    'Дата конца',
    'Наименование работ',
    'Номер работ из реестра',
    'Описание работы',
    'Ед. изм.',
    'Стоимость единицы',
    'Общая стоимость',
  ]


  const onProjectsExpanded = () => {
    setProjectsExpanded(!projectsExpanded);
  }

  const handleProjectSelect = (project) => {
    setCurrentProjectId(project.id);
    setProjectName(project.name)
    refreshProject(project.id);
    setProjectsExpanded(false);
  };

  const onAddChapterClick = () => {
    setAddChapterInputOpen(true);
  }

  const handleAddChapter = async (chapterName) => {
    if (chapterName.length === 0) { return }
    // setChapters([...chapters, { name: chapterName, sections: [] }]);
    setNewChapterName(''); // Очистить поле ввода
    setAddChapterInputOpen(false); // Закрыть поле ввода
    // console.log(chapters)
    //fetch chapter name
      try {
        const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.POST_CHAPTER}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name: chapterName, project_id: currentProjectId }),
        });

        if (!response.ok) {
          throw new Error('Failed to add chapter');
        }

        const responseText = await response.text();
        // console.log('Response text:', responseText);

        // Попытка парсинга JSON
        let newChapter;
        try {
          newChapter = JSON.parse(responseText);
        } catch (parseError) {
          throw new Error('Failed to parse JSON response');
        }
        // Обновление локального состояния
        setTasksState((prevState) => ({
          ...prevState,
          projectInfo: {
            ...prevState.projectInfo,
            chapters: [...prevState.projectInfo.chapters, newChapter],
          },
        }));

        setNewChapterName(''); // Очистить поле ввода
        setAddChapterInputOpen(false); // Закрыть поле ввода
      } catch (error) {
        console.error('Error adding chapter:', error);
      }
      refreshProject(currentProjectId)
  };

  const handleAddSection = async (chapterIndex, sectionName) => {
    setAddSectionInputOpen(null);
    setNewSectionName('');
    // console.log(chapterIndex)
    try {
      const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.POST_SECTION}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: sectionName, chapter_id: chapterIndex }),
      });

      if (!response.ok) {
        throw new Error('Failed to add section');
      }

      const responseText = await response.text();
      // console.log('Response text:', responseText);

      let newChapter;
      try {
        newChapter = JSON.parse(responseText);
      } catch (parseError) {
        throw new Error('Failed to parse JSON response');
      }

      setTasksState((prevState) => ({
        ...prevState,
        projectInfo: {
          ...prevState.projectInfo,
          chapters: [...prevState.projectInfo.chapters, newChapter],
        },
      }));

      setNewChapterName('');
      setAddChapterInputOpen(false);
    } catch (error) {
      console.error('Error adding chapter:', error);
    }
    refreshProject(currentProjectId)
  };

  const handleAddTaskToSection = async(chapterIndex, sectionIndex) => {
    setAddTaskOpen(null);
    setNewTask(initialTaskState);
    const taskData = {
      ...newTask,
      qualifier: "SECTION",
      qualifier_id: `${sectionIndex}`,
    };
    // console.log(JSON.stringify(taskData))
    try {
      const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.POST_TASK}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(taskData),
      });

      if (!response.ok) {
        throw new Error('Failed to add task');
      }

      const contentType = response.headers.get('Content-Type');

      let responseData;
      if (contentType && contentType.includes('application/json')) {
        responseData = await response.json();
      } else {
        responseData = await response.text();
      }

      // console.log('Response data:', responseData);

      // Если ответ является JSON
      if (typeof responseData === 'object') {
        const createdTask = responseData;
        setTasksState((prevState) => {
          const updatedChapters = [...prevState.projectInfo.chapters];
          updatedChapters[chapterIndex].sections[sectionIndex].tasks = [
            ...updatedChapters[chapterIndex].sections[sectionIndex].tasks,
            createdTask,
          ];

          return {
            ...prevState,
            projectInfo: {
              ...prevState.projectInfo,
              chapters: updatedChapters,
            },
          };
        });
      } else {
        console.warn('Non-JSON response:', responseData);
      }
    } catch (error) {
      console.error('Error adding task to section:', error);
    }
    refreshProject(currentProjectId);
  };

  const handleAddTaskToChapter = async(chapterIndex) => {
    // console.log()
    setAddTaskOpen(null);
    setNewTask(initialTaskState);
    const taskData = {
      ...newTask,
      qualifier: "CHAPTER",
      qualifier_id: `${chapterIndex}`,
    };

    try {
      const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.POST_TASK}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(taskData),
      });

      if (!response.ok) {
        throw new Error('Failed to add task');
      }

      const contentType = response.headers.get('Content-Type');
      let createdTask;

      if (contentType && contentType.includes('application/json')) {
        createdTask = await response.json();
      } else {
        const responseText = await response.text();
        console.warn('Non-JSON response:', responseText);
        createdTask = { message: responseText };
      }

      setTasksState((prevState) => {
        const updatedChapters = [...prevState.projectInfo.chapters];

        // Проверяем, существует ли chapter и tasks, инициализируем если нужно
        if (!updatedChapters[chapterIndex]) {
          updatedChapters[chapterIndex] = { tasks: [] };
        } else if (!updatedChapters[chapterIndex].tasks) {
          updatedChapters[chapterIndex].tasks = [];
        }

        // Добавляем задачу
        updatedChapters[chapterIndex].tasks = [
          ...updatedChapters[chapterIndex].tasks,
          createdTask,
        ];

        return {
          ...prevState,
          projectInfo: {
            ...prevState.projectInfo,
            chapters: updatedChapters,
          },
        };
      });
      setNewTask(initialTaskState);
      setAddTaskOpen(null);
    } catch (error) {
      console.error('Error adding task to chapter:', error);
    }
    refreshProject(currentProjectId);
  };

  useEffect(() => {
    fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_ALL_PROJECTS}`, {
      method: 'GET',
      headers:{
        'Authorization' : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE',
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log(data)
      setAllProjectState({
        loading: false,
        allProjectInfo: data,
      });
    })
    .catch(error => {
      console.error('Error fetchinge:', error);
      setAllProjectState({
        loading: false,
        allProjectInfo: { projects: [] },
      });
    });
  }, []);

  // console.log(currentProjectId)
  useEffect(() => {
    // fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_PROJECT}?id=${searchParams.get("projId")}`,
    fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_ALL_KC6_TASKS}`, { //пришлось сделать так, поскольку при использовании projectId после перезагрузки страницы этот самый projectId девается из этого useEffect (надо переделать)
      method: 'GET',
      headers:{
        'Authorization' : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE',
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log(data)
      setTasksState({
          loading: false,
          projectInfo: data
      });
    })
    .catch(error => {
      console.error('Error fetchinge:', error);
      setTasksState({
        loading:false,
        projectInfo: null
      })
    });
  }, [projectId]);

  const refreshProject = useCallback((projectId) => {
    // console.log(projectId)
    fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.GET_PROJECT}?id=${projectId}`, {
      method: 'GET',
      headers:{
        'Authorization' : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE',
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log(data)
      setTasksState({
          loading: false,
          projectInfo: data
      });
    })
    .catch(error => {
      console.error('Error fetchinge:', error);
      setTasksState({
        loading:false,
        projectInfo: null
      })
    });
  }, [])

  useEffect(() => {
    if (currentProjectId) {
      refreshProject(currentProjectId);
    }
  }, [currentProjectId]);

  // console.log(allProjectState.allProjectInfo)
// Построение структуры
// console.log(tasksState.projectInfo.chapters)


  return (
    <>
      <div className="body-wrapper">
        <div className="body-wrapper--flex">
          <NavigationMenu currentPage="documentation" accessToken={accessToken}/>
          <main className="addition-main-index">
            <div className="documentation page-container--dark-bg">
              <div className="documentation__header">
                <h1 className="documentation__title">Документация</h1>
                <div className="documentation__projects-list-container">
                  <div className="documentation__list-header documentation__list-header--projects" onClick={onProjectsExpanded}>
                  <p>{currentProjectId ? `${projectName}` : 'Выберите проект'}</p>
                  </div>
                  {projectsExpanded && (
                    <div className="documentation__projects-list">
                      {allProjectState.allProjectInfo.projects.filter((project) => project.status !== "DELETED")
                      .map((project) => {
                        return (
                        <div
                          key={project.id}
                          className="documentation__projects-item"
                          onClick={() => handleProjectSelect(project)}
                        >
                          {project.name}
                        </div>
                        )
}                     )}
                    </div>
                  )}
                </div>
                {/* <div className="documentation__districts-list-container" onClick={onDisrictsExpanded}>
                  <div className="documentation__list-header documentation__list-header--districts">
                    <p>ДН-3</p>
                  </div>
                </div> */}
              </div>
              <div className="documentation__nav-section">
                <SwitcherBetweenPages
                currentPage="КС-6"
                firstPage="ГПР"
                secondPage="КС-6"
                firstLink="/documentation-samples"
                secondLink="/documentation"
                faq="false"/>
                <SearchField/>
                <div className="documentation__button-wrapper">
                  <button className="documentation__button button button--primary">Экспорт</button>
                  <button className="documentation__button button button--primary">Импорт</button>
                </div>
              </div>
              <div className="documentation__table-container">
                <div className="documentation__table-header-container">
                  <ul className="documentation__table-header-list">
                    {
                      TABLEHEADER.map((thead, index) => {
                        return(
                          <li key={index} className="documentation__table-header-item">
                            {thead}
                          </li>
                        )
                      })
                    }
                  </ul>
                  <button className="documentation__button button button--primary">Дополнит. информация</button>
                </div>
                <div className="documentation__table-body-container">
                  {
                    tasksState.projectInfo?.chapters &&
                    <>
                      <div className="documentation__chapter chapter">
                        <ul className="chapter__list">
                          {tasksState.projectInfo.chapters.map((chapter, chapterIndex) => (
                            <li className="chapter__item" key={chapter.id}>
                              <h2 className="chapter__title">{chapter.name}</h2>
                              <button className="chapter__button chapter__button--edit button button--secondary button--edit"></button>
                              <button className="chapter__button chapter__button--delete button button--secondary button--delete"></button>
                              <ul className="chapter__task-list task-list">
                                {tasksState.projectInfo?.tasks && tasksState.projectInfo.tasks
                                 .filter((task) => task.qualifier === 'CHAPTER' && task.qualifier_id === chapter.id)
                                 .map((task, taskIndex) => {
                                  console.log(task)
                                  return (
                                  <li className="task-list__item" key={task.id}>
                                    <p>{task.start}</p>
                                    <p>{task.end}</p>
                                    <p>{task.header}</p>
                                    <p>{task.work_number}</p>
                                    <p>{task.description}</p>
                                    <p>{task.estimated_volume}</p>
                                    <p>{task.monthly_volume}</p>
                                    <p>{task.volume_metrics}</p>
                                    <p>{task.metric_cost}</p>
                                    <p>{task.total_cost}</p>
                                  </li>
                                 )})
                                }
                              </ul>
                              {addTaskOpen?.chapterIndex === chapterIndex && addTaskOpen?.sectionIndex === null && (
                                <div className="chapter__input-wrapper chapter__input-wrapper--task">
                                  <input
                                    type="datetime-local"
                                    placeholder="Дата начала"
                                    value={newTask.start}
                                    onChange={(e) => setNewTask({ ...newTask, start: e.target.value })}
                                  />
                                  <input
                                    type="datetime-local"
                                    placeholder="Введите данные"
                                    value={newTask.end}
                                    onChange={(e) => setNewTask({ ...newTask, end: e.target.value })}
                                  />
                                  <input
                                    placeholder="Введите данные"
                                    value={newTask.header}
                                    onChange={(e) => setNewTask({ ...newTask, header: e.target.value })}
                                  />
                                  <input
                                    placeholder="Введите данные"
                                    value={newTask.work_number}
                                    onChange={(e) => setNewTask({ ...newTask, work_number: e.target.value })}
                                  />
                                  <input
                                    placeholder="Введите данные"
                                    value={newTask.description}
                                    onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                                  />
                                  <input
                                  // className="chapter__input--float"
                                    placeholder="Введите данные"
                                    type="number"
                                    value={newTask.estimated_volume}
                                    onChange={(e) => setNewTask({ ...newTask, estimated_volume: parseFloat(e.target.value) })}
                                  />
                                  <input
                                  // className="chapter__input--float"
                                    placeholder="Введите данные"
                                    type="number"
                                    value={newTask.monthly_volume}
                                    onChange={(e) => setNewTask({ ...newTask, monthly_volume: parseFloat(e.target.value) })}
                                  />
                                  <input
                                    placeholder="Введите данные"
                                    value={newTask.volume_metrics}
                                    onChange={(e) => setNewTask({ ...newTask, volume_metrics: e.target.value })}
                                  />
                                  <input
                                  // className="chapter__input--float"
                                    placeholder="Введите данные"
                                    type="number"
                                    value={newTask.metric_cost}
                                    onChange={(e) => setNewTask({ ...newTask, metric_cost: parseFloat(e.target.value) })}
                                  />
                                  <input
                                  // className="chapter__input--float"
                                    placeholder="Введите данные"
                                    type="number"
                                    value={newTask.total_cost}
                                    onChange={(e) => setNewTask({ ...newTask, total_cost: parseFloat(e.target.value) })}
                                  />
                                  <button className="chapter__button button button--secondary button--add" onClick={() => handleAddTaskToChapter(chapter.id)}></button>
                                  <button className="chapter__button button button--transparent button--close" onClick={() => setAddTaskOpen(null)}></button>
                                </div>
                              )}
                              <div className="documentation__section section">
                              {
                                tasksState.projectInfo?.sections && (
                                  <ul className="section__list">
                                    {tasksState.projectInfo.sections
                                    .filter(section => section.chapter_id === chapter.id)
                                    .map((section, sectionIndex) => (
                                      <>
                                        <li className="section__item" key={sectionIndex}>
                                          <h3>{section.name}</h3>
                                          <div className="section__button-wrapper">
                                            <button className="chapter__button button button--secondary button--add" onClick={() => setAddTaskOpen({chapterIndex, sectionIndex })}></button>
                                            <p>Добавить работу в раздел</p>
                                          </div>
                                          <ul className="section__task-list task-list">
                                            {tasksState.projectInfo.tasks && tasksState.projectInfo.tasks
                                            .filter((task) => task.qualifier === 'SECTION' && task.qualifier_id === section.id)
                                            .map((task, taskIndex) => (
                                              <li className="task-list__item" key={taskIndex}>
                                                <p>{task.start}</p>
                                                <p>{task.end}</p>
                                                <p>{task.header}</p>
                                                <p>{task.work_number}</p>
                                                <p>{task.description}</p>
                                                <p>{task.estimated_volume}</p>
                                                <p>{task.monthly_volume}</p>
                                                <p>{task.volume_metrics}</p>
                                                <p>{task.metric_cost}</p>
                                                <p>{task.total_cost}</p>
                                              </li>
                                            ))}
                                          </ul>
                                          {addTaskOpen?.chapterIndex === chapterIndex && addTaskOpen?.sectionIndex === sectionIndex && (
                                            <div className="section__input-wrapper section__input-wrapper--task">
                                              <input
                                                type="datetime-local"
                                                placeholder="Дата начала"
                                                value={newTask.start}
                                                onChange={(e) => setNewTask({ ...newTask, start: e.target.value })}
                                              />
                                              <input
                                                type="datetime-local"
                                                placeholder="Дата конца"
                                                value={newTask.end}
                                                onChange={(e) => setNewTask({ ...newTask, end: e.target.value })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                value={newTask.header}
                                                onChange={(e) => setNewTask({ ...newTask, header: e.target.value })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                value={newTask.work_number}
                                                onChange={(e) => setNewTask({ ...newTask, work_number: e.target.value })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                value={newTask.description}
                                                onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                type="number"
                                                value={newTask.estimated_volume}
                                                onChange={(e) => setNewTask({ ...newTask, estimated_volume: parseFloat(e.target.value) })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                type="number"
                                                value={newTask.monthly_volume}
                                                onChange={(e) => setNewTask({ ...newTask, monthly_volume: parseFloat(e.target.value) })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                value={newTask.volume_metrics}
                                                onChange={(e) => setNewTask({ ...newTask, volume_metrics: e.target.value })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                type="number"
                                                value={newTask.metric_cost}
                                                onChange={(e) => setNewTask({ ...newTask, metric_cost: parseFloat(e.target.value) })}
                                              />
                                              <input
                                                placeholder="Введите данные"
                                                type="number"
                                                value={newTask.total_cost}
                                                onChange={(e) => setNewTask({ ...newTask, total_cost: parseFloat(e.target.value) })}
                                              />
                                              <button className="chapter__button button button--secondary button--add" onClick={() => handleAddTaskToSection(chapter.id, section.id)}></button>
                                              <button className="chapter__button button button--transparent button--close" onClick={() => setAddTaskOpen(null)}></button>
                                            </div>
                                          )}
                                        </li>
                                      </>
                                    ))}
                                  </ul>
                                )
                              }
                              {
                                addSectionInputOpen === chapterIndex && (
                                  <div className="documentation__section section section--add">
                                    <div className="section__input-wrapper">
                                    <input
                                      className="section__input-name"
                                      placeholder="Введите название раздела"
                                      value={newSectionName}
                                      onChange={(e) => setNewSectionName(e.target.value)}
                                    />
                                    <button className="section__button button button--secondary button--add" onClick={() => handleAddSection(chapter.id, newSectionName)}></button>
                                    <button className="section__button button button--transparent button--close" onClick={() => setAddSectionInputOpen(null)}></button>
                                    </div>
                                  </div>
                                )
                              }
                              <div className="chapter__button-wrapper">
                                <button
                                className="chapter__button button button--secondary button--add"
                                onClick={() => setAddTaskOpen({ chapterIndex, sectionIndex: null })}></button>
                                <p>Добавить работу в главу</p>
                              </div>
                              <div className="chapter__button-wrapper">
                                <button
                                className="chapter__button button button--secondary button--add-transparent"
                                onClick={() => setAddSectionInputOpen(chapterIndex)}></button>
                                <p>ДОБАВИТЬ РАЗДЕЛ</p>
                              </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        {
                          addChapterInputOpen &&
                          <>
                            <div className="documentation__chapter chapter--add">
                              <div className="chapter__input-wrapper">
                              <input
                                className="chapter__input-name"
                                placeholder="Введите название главы"
                                value={newChapterName}
                                onChange={(e) => setNewChapterName(e.target.value)}
                              />
                              <button className="chapter__button button button--secondary button--add" onClick={() => handleAddChapter(newChapterName)}></button>
                              <button className="chapter__button button button--transparent button--close" onClick={() => setAddChapterInputOpen(false)}></button>
                              </div>
                            </div>
                          </>
                        }
                      </div>
                    </>
                  }
                </div>
                {/* {
                          addChapterInputOpen &&
                          <>
                            <div className="documentation__chapter chapter--add">
                              <div className="chapter__input-wrapper">
                              <input
                                className="chapter__input-name"
                                placeholder="Введите название главы"
                                value={newChapterName}
                                onChange={(e) => setNewChapterName(e.target.value)}
                              />
                              <button className="chapter__button button button--secondary button--add" onClick={() => handleAddChapter(newChapterName)}></button>
                              <button className="chapter__button button button--transparent button--close" onClick={() => setAddChapterInputOpen(false)}></button>
                              </div>
                            </div>
                          </>
                        } */}
                {/* <button className="documentation__button documentation__button--add-chapter button button--primary" onClick={onAddTaskClick}>Добавить работу</button> */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
export default DocumentationSamplePage;
