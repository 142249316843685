import config from "../../../apiConfig.json"

export const deleteProject = async (id) => {
  console.log('не бывало ли у вас такое...')
  const response = await fetch(`${config.BASE_URL}${config.CRM_ENDPOINTS.DELETE_PROJECT}${id}`, {
    method: "DELETE",
    headers: {
      'Authorization': `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxNzM3MjgwMzgxfQ.zC6cHe2Kg3p0YyoiqoW84gvR16Y9KUAsKDP1zCKzEbU`,
      'Content-Type': 'application/json',
    }
  })
  if (response.ok) {
    return "success"
  } else {
    return { status: response.status, text: response.statusText }
  }
}