import { useCallback, useEffect, useState } from "react";
import { DeleteBtn, GotoArrowBtn } from '@widgets/buttons/index';
import { colors } from '../index'
import { DetailedTask } from "@entities/task/index"
import { useTaskFields } from "@entities/task/index"
import { deleteTask } from "../index"

const TaskCard = (props) => {
  const { taskId, accessToken, district, start, end, performers } = props
  // const { district, start, end, performers } = useTaskFields()
  const [isDetailedCardOpened, setIsDetailedCardOpened] = useState(false)
  // const [deletionStatus, setDeletionStatus] = useState('')
  // district.set({name: 'ДН-1'})
  const onOpenDetailedCard = () => {
    setIsDetailedCardOpened(true);
  }

  const onCloseDetailedCard = () => {
    setIsDetailedCardOpened(false);
  }

  const onDelete = () => {
    const deletionResult = deleteTask(accessToken, taskId)
    
  }

  return (
    <div className="task-card">
      <section className="task-card__upper-section">
          <span style={{ backgroundColor: colors.direction}} className="direction">ДН-1</span>
          <span className="dates">10.10.1984 - 12.12.1984</span>
        <div className="task-card__actions">
          <button className="change-status-button task-card__button" />
          <DeleteBtn onClick={onDelete} />
        </div>
      </section>

      <p className="task-card__description">
        Разработка грунта ковшом
      </p>

      <section className="task-card__bottom-section">
        <div className="task-card__bottom-section_wrapper">
          <ul className="staff-list">
            <li className="staff-list__item">
              <img src="#" alt="" className="" />
            </li>
          </ul>
          <span className="task-card__location">
            Мустах
          </span>
        </div>
      <GotoArrowBtn style={{position: 'absolute', right: '0',
      bottom: '0'
    }} rotation="rb" onClick={onOpenDetailedCard} />
      </section>
    {/* <div className="task-card">
      <section className="task-card__upper-section">
          <span style={{ backgroundColor: colors.direction}} className="direction">{district}</span>
          <span className="dates">{start} - {end}</span>
        <div className="task-card__actions">
          <button className="change-status-button task-card__button" />
          <DeleteBtn onClick={onDelete} />
        </div>
      </section>

      <p className="task-card__description">
        Разработка грунта ковшом
      </p>

      <section className="task-card__bottom-section">
        <div className="task-card__bottom-section_wrapper">
          <ul className="staff-list">
            {performers.map(member => (
              <li className="staff-list__item">
                <img src="#" alt="" className="" />
              </li>
            ))}
          </ul>
          <span className="task-card__location">
            Мустах
          </span>
        </div>
      <GotoArrowBtn style={{position: 'absolute', right: '0',
      bottom: '0'
    }} rotation="rb" onClick={onOpenDetailedCard} />
      </section> */}

      {/* <button className='goto-button' onClick={onOpenDetailedCard} /> */}

      {isDetailedCardOpened &&
        <DetailedTask
          onClose={onCloseDetailedCard}
        />
      }

    </div>
  )
}

export {TaskCard};