import { useEffect, useState, useCallback } from "react";
import apiConfig from '../../apiConfig.json';
import '../ShiftsTable/role-suggestions.css'

const UserChangeForm = (props) => {

  const {accessToken, editingMode, setEditingMode, onFormSubmit, userName, userInfo} = props;
  const [equipmentList, setEquipmentList] = useState([]);
  const [directions, setDirections] = useState([]);
  const [newRole, setNewRole] = useState(userInfo.roles[0]);
  const [newRoleEn, setNewRoleEn] = useState(userInfo.roles[0].name);
  const [newPassword, setNewPassword] = useState();
  const [newDirectionId, setNewDirectionId] = useState(userInfo.directionId || null);
  const [newDirection, setNewDirection] = useState(userInfo.directionName || '');
  const [newPlaceId, setNewPlaceId] = useState(userInfo.placeId || null);
  const [newPlace, setNewPlace] = useState(userInfo.placeName || '');
  const [newImei, setNewImei] = useState(userInfo.imei);
  const [newEquipmentList, setNewEquipmentList] = useState(equipmentList);

  const [roles] = useState([
    { ru: 'Администратор', en: 'ADMIN' },
    { ru: 'Администратор направления', en: 'LOCAL_ADMIN_DIR' },
    { ru: 'Администратор участка', en: 'LOCAL_ADMIN_PLACE' },
    { ru: 'Рабочий', en: 'USER' },
    { ru: 'Механик', en: 'MECHA' },
    { ru: 'Аналитик', en: 'ANAL' },
    { ru: 'Работник с персоналом', en: 'HR' },
  ]);
  const [filteredRoles, setFilteredRoles] = useState([]);

  const EQUIPS = [
    'EXCAVATOR',
    'BULLDOZER',
    'CRANE',
    'GRADER',
    'RINK',
    'TRUCK',
    'REFUELER',
    'AUTO',
    'POOKER'
  ];

  useEffect(() => {
    if (newRole) {
      const lowerCaseInput = newRole.toLowerCase();
      const filtered = roles.filter(role => role.ru.toLowerCase().includes(lowerCaseInput));
      setFilteredRoles(filtered);
    } else {
      setFilteredRoles([]);
    }
  }, [newRole, roles]);

  console.log(userInfo)
  useEffect(() => {
    // Extract the type values from userInfo's equipment array
    const equipmentTypes = userInfo.specializations.map(equip => equip.type);
    setEquipmentList(equipmentTypes);
  }, [userInfo]);
  // console.log(equipmentList)

  const handleSwitchEquipment = (type) => {
    setEquipmentList(prevList => {
      if(!prevList.includes(type)) {
        return [...prevList, type];
      } else {
        return prevList.filter(item => item !== type );
      }
    });
  };

  const prepareUserUpdateData = () => {

    const updateData = {
      name: userName,
      password: newPassword || '',
      // ...(newPassword && { password: newPassword}),
      fullName: userInfo.fullName,
      imei: newImei || userInfo.imei,
      specializations: equipmentList.length > 0 ? equipmentList : [],
      roles: [newRoleEn] || userInfo.roles,
      // placeId: newPlaceId,
      // directionId: newDirectionId,
      ...(newPlace && { placeName: newPlace}),
      // placeName: newPlace,
      // directionName: newDirection || userInfo.directionName
    };

    if (newPassword) {
      updateData.password = newPassword;
    }

    return updateData
  };

  console.log(JSON.stringify(prepareUserUpdateData()))

  const handleEditingMode = () => {
    const updateData = prepareUserUpdateData();
    // console.log(updateData)
    const url = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.CHANGE_USER_INFORMATION}`;
    fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updateData)
    })
      .then(response => {
       onFormSubmit();
       editingMode();
       return response.json()
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }

  const getDirections = useCallback(() => {
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_DIRECTIONS}`, {
      method: 'GET',
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
   .then(response =>  {
     return response.json();
   })
   .then(data => {
    const dirs = [];

    data.forEach((dir) => {
      const places = [];
      const name = dir.name;
      const direction_id = dir.id;

      if(dir.place.length > 0) {
        dir.place.forEach((place) => {
          const name = place.name;
          const place_id = place.id;
          places.push({name, place_id});
        });
      } else {
      }

      dirs.push({name, direction_id, places});
    });
    setDirections(dirs);
   })
   .catch(error => {
     console.error('Error fetching data: ', error);
   });
 },[accessToken]);
//  console.log(directions)

 useEffect(() => {
   getDirections();
 }, [accessToken]);

  const handleInputRoleChange = (event) => {
    setNewRole(event.target.value);
  };

  const handleRoleSelect = (role) => {
    setNewRole(role.ru);
    setNewRoleEn(role.en);
  };

  const handleInputPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleInputDirectionChange = (event) => {
    setNewDirection(event.target.value)
    const inputDirection = event.target.value;
    const foundDirection = directions.find(dir => dir.name === inputDirection);
    setNewDirectionId(foundDirection ? foundDirection.direction_id : null);
    if (!foundDirection || foundDirection.direction_id !== newDirectionId) {
      setNewPlace('');
      setNewPlaceId(null);
    }
  };
  // console.log(newDirectionId)

  const handleInputPlaceChange = (event) => {
    setNewPlace(event.target.value);
    const inputPlace = event.target.value;
    const currentDirection = directions.find(dir => dir.direction_id === newDirectionId);
    if (currentDirection) {
      const foundPlace = currentDirection.places.find(place => place.name === inputPlace);
      setNewPlaceId(foundPlace ? foundPlace.place_id : null);
    } else {
      setNewPlaceId(null);
    }
  };
  // console.log(newPlaceId)


  const handleInputImeiChange = (event) => {
    setNewImei(event.target.value);
  };

  return(
    <>
      <div className='information-container__personal-info information-container__personal-info--editing personal-info'>
        <dl>
          <div>
            <dt className="personal-info__topic">Должность</dt>
              <input
            className="personal-info__input"
            value={newRole}
            onChange={handleInputRoleChange}></input>
            {filteredRoles.length > 0 && (
              <ul className="personal-info__input-role-suggestions role-suggestions">
                {filteredRoles.map((role, index) => (
                  <li key={index} onClick={() => handleRoleSelect(role)}>
                    {role.ru}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <dt className="personal-info__topic">Пароль</dt>
            <input
            className="personal-info__input"
            value={newPassword}
            onChange={handleInputPasswordChange}></input>
          </div>
          <div>
            <dt className="personal-info__topic">Направление</dt>
            <input
            className="personal-info__input"
            value={newDirection}
            onChange={handleInputDirectionChange}></input>
          </div>
          <div>
            <dt className="personal-info__topic">Участок</dt>
            <input
            className="personal-info__input"
            value={newPlace}
            onChange={handleInputPlaceChange}></input>
          </div>
          <div className="information-container__equiment information-container__equiment--editing">
            <dt className="personal-info__topic">Оборудование</dt>
            <div className="personal-info__equip-buttons">
            {EQUIPS.map((equip, index) => {
              return (
                <button key={index} type='button' className={`personal-info__equip-button modal-change-equipment technic-type-${equipmentList.includes(equip) ? 'white' : 'black'}-${equip.toLowerCase()} ${equipmentList.includes(equip) ? 'selected' : ''}`}
                onClick={(e) => {handleSwitchEquipment(e.target.value)}}
                value={equip}
                />
              )
            })}
            </div>
          </div>
        </dl>
      </div>
      <div className='information-container__other-parameters information-container__other-parameters--editing '>
        <div>
          <dt className="personal-info__topic personal-info__topic--imei">IMEI</dt>
          <div className="personal-info__imei-wrapper">
            <input
              className="personal-info__input personal-info__input--imei"
              placeholder={userInfo.imei}
              value={newImei}
              onChange={handleInputImeiChange}></input>
          </div>
        </div>
      </div>
      {
        editingMode && <button className="personal-info__button-safe" onClick={handleEditingMode}>Coxpaнить</button>
      }
    </>
  )
}
export default UserChangeForm;
