import { useState } from "react";
import { CloseBtn } from "@widgets/buttons/index";
import { checkEpmtyValues } from "@features/forms/index";
import { postNewProject } from "../api/post-new-project";
import { useProjectFields } from "../index";
import { formatToLocalDateTime } from "@features/formatters/index";
import { DistrictsList } from "@entities/districts/index";
import ModalNote from "../../../Components/ModalNote";

export const NewProjectForm = (props) => {
  const { onClose, accessToken } = props;
  const { name, start, end, description, position, district } =
    useProjectFields();
  // console.log(name, start, end, description)
  const [errors, setErrors] = useState({});
  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);
  // console.log(name.value, start.value, end.value, description.value);

  const checkIfEmpty = (value, field) => {
    const error = checkEpmtyValues(value);
    if (error.state) {
      setErrors((p) => ({
        ...p,
        [field]: error,
      }));
    }
  };

  const onChangeFieldValue = (value, field, setter) => {
    setter(value);
    checkIfEmpty(value, field);
  };

  const onSubmit = () => {
    // console.log(name.value, start.value, end.value, description.value);
    const formattedStartDate = formatToLocalDateTime(start.value);
    const formattedEndDate = formatToLocalDateTime(end.value);
    const submitResult = postNewProject({
      accessToken,
      name: name.value,
      start: formattedStartDate,
      end: formattedEndDate,
      description: description.value,
      direction: district.value
    });
    if (submitResult === "success") {
      // onClose();
      // setIsSuccessModalOpened(true);
    }
  };

  return (
    <>
      <div className="new-project-form modal-container">
        <form
          action="POST"
          className="new-project-form-form modal-container__wrapper"
        >
          <CloseBtn
            blackOrWhite="white"
            style={{ position: "absolute", top: "2%", right: "2%" }}
            onClick={onClose}
          />

          <div className="new-project-form-field">
            <label htmlFor="name" className="new-project-form-field__label">
              Название
            </label>
            <input
              className="new-project-form-field__input"
              id="name"
              placeholder="Введите название проекта"
              value={name.value}
              onChange={(e) =>
                onChangeFieldValue(e.target.value, name.value, name.set)
              }
            />
          </div>

          <div className="new-project-form-field">
            <label htmlFor="name" className="new-project-form-field__label">
              Направление
            </label>
            <DistrictsList accessToken={accessToken} district={district.value} setDistrict={district.set} arePlacesRequired={false} />
          </div>

          <div className="new-project-form-field">
            <label className="new-project-form-field__label">
              Период выполнения
            </label>
            <div className="new-project-form-row-container">
              <div className="new-project-form-field_date-wrapper">
                <input
                  type="date"
                  className="new-project-form-field__input"
                  id="date"
                  placeholder="Дата начала"
                  value={start.value}
                  onChange={(e) =>
                    onChangeFieldValue(e.target.value, start.value, start.set)
                  }
                />
              </div>
              <div className="new-project-form-field_date-wrapper">
                <input
                  type="date"
                  className="new-project-form-field__input"
                  id="date"
                  placeholder="Дата окончания"
                  value={end.value}
                  onChange={(e) =>
                    onChangeFieldValue(e.target.value, end.value, end.set)
                  }
                />
              </div>
            </div>
          </div>

          <div className="new-project-form-field new-project-form-extra-info">
            <label className="new-project-form-field__label">
              Дополнительная информация
            </label>
            <textarea
              className="new-project-form-field__textarea new-project-form-extra-info__textarea"
              value={description.value}
              onChange={(e) =>
                onChangeFieldValue(
                  e.target.value,
                  description.value,
                  description.set
                )
              }
              placeholder="Опишите проект"
            ></textarea>
          </div>

          <div className="new-project-form__button-wrapper">
            <button
              type="submit"
              className="new-project-form__create-project-button"
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              Новый проект
            </button>
          </div>
        </form>
      </div>

      {isSuccessModalOpened && (
        <ModalNote
          note={`Проект ${name} успешно создан`}
          onClose={() => setIsSuccessModalOpened(false)}
        />
      )}
    </>
  );
};
