import { useEffect, useState } from "react";
import apiConfig from "../../apiConfig.json";

const GalleryPopUp = ({show, onClose, imagesArray, image}) => {


  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [shownImage, setShownImage] = useState('');
  const [activePreview, setActivePreview] = useState('');
  const [index, setIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    if (show) {
      setShownImage(image);
      setActivePreview(image);
    }
  }, [show, image]);

  const handleImageClick = (event, img) => {
    event.preventDefault();
    setShownImage(img);
    setActivePreview(img);
  }

  const handleGalleryClose = (onClose) => {
    setShownImage('');
    setActivePreview('');
    onClose(onClose);
    setStartIndex(0);
  }

  const handleIndexChange = (newIndex) => {
    if (newIndex >= 0 && newIndex < imagesArray.length) {
      setIndex(newIndex);
      setShownImage(`${imagesArray[newIndex].value}`);
      setActivePreview(`${imagesArray[newIndex].value}`);
    }
  }

  const handleNextClick = () => {
    setStartIndex(prevIndex => prevIndex + 4);
  }

  const handlePrevClick = () => {
    setStartIndex(prevIndex => Math.max(prevIndex - 4, 0));
  }

  const isPrevDisabled = index <= 0;
  const isNextDisabled = index >= imagesArray.length - 1;
  const showNavigation = imagesArray.length > 4;

  if(!show) return null

  return(
    <>
      <section className={isMobile ? 'overlay-gallery-mobile overlay-darker' : 'overlay-shift overlay-darker'}>
        <div className={isMobile ? 'gallery-mobile-wrapper' :  "gallery-wrapper"}>
          <button className={isMobile ? 'close-gallery-button-mobile' : "close-gallery-button"} onClick={() => handleGalleryClose(onClose)}></button>
          {isMobile ? (
            <img src={shownImage} alt="choosen gallery item"  className="active-image" width={300}/>
            ) : (
            <img src={shownImage} alt="choosen gallery item"  className="active-image" height={355}/>
          )
          }
              <ul className={isMobile ? 'inactive' : "gallery-list"}>
                {showNavigation && (
                  <li>
                    <button onClick={handlePrevClick} className="gallery-desktop-button-back" disabled={startIndex === 0}></button>
                  </li>
                  )
                }
                {
                  imagesArray.slice(startIndex, startIndex + 4).map((image, index) => {
                    const img = image.value;
                    return(
                    <li className="gallery-item" key={index}>
                      <a href={img} className={activePreview === img ? 'active' : ''} onClick={(event) => handleImageClick(event, img)}>
                        <img src={img} alt={image.value} width={100} height={80}/>
                      </a>
                    </li>
                    )
                  })
                }
                {showNavigation && (
                  <li>
                    <button onClick={handleNextClick} className="gallery-desktop-button-forth" disabled={startIndex + 4 >= imagesArray.length}></button>
                  </li>
                  )
                }
              </ul>
          {isMobile && (
            <div className="navigation-buttons-gallery">
              <button onClick={() => handleIndexChange(index - 1)} className="gallery-mobile-button-back" disabled={isPrevDisabled}></button>
              <button onClick={() => handleIndexChange(index + 1)} className="gallery-mobile-button-forth" disabled={isNextDisabled}></button>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default GalleryPopUp;
