// projectSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentProject: null, // Данные текущего проекта
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProject(state, action) {
      state.currentProject = action.payload; // Устанавливаем данные проекта
    },
    clearProject(state) {
      state.currentProject = null; // Очищаем данные проекта
    },
  },
});

export const { setProject, clearProject } = projectSlice.actions;
export default projectSlice.reducer;