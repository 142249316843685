import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'


export const GotoArrowBtn = (props) => {
  const { style, onClick, gotoLink = "", rotation, params = {} } = props
  const [rotationStyle, setRotationStyle] = useState('')
  let linkWithQuery = ''
  
  useEffect(() => {
    switch(rotation) {
      case ("rb"):
        setRotationStyle("goto-arrow-btn_rotated-right-bottom");
        break;
      case ("lt"):
        setRotationStyle("goto-arrow-btn_rotated-left-top");
        break;
      default:
        setRotationStyle("");
        break;
    }
  }, [rotation])

  // console.log('да не легко ', params)
  if(Object.keys(params).length !== 0) {
    const queryString = new URLSearchParams(params).toString()
    linkWithQuery = `${gotoLink}?${queryString}`
    // console.log('ну это то ты сделаешь? ', linkWithQuery)
  }

  return (
    <button type="button"
    style={style}
    className={`goto-arrow-btn ${rotationStyle}`}
    // onClick={() => onClick(gotoLink, data)}>
    onClick={onClick}>
      {gotoLink.length > 0 &&
        <Link
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
          color: 'black',
          textDecoration: 'none',
          width: '100%',
          height: '100%',
          borderRadius:'30px'
        }}
        to={linkWithQuery === '' ? gotoLink : linkWithQuery}
        // state={{state: state}}
        />
      }
    </button>
  ) 
}