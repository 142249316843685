import { Link } from 'react-router-dom'
import { useState } from 'react'

export const ExpandBtn = (props) => {
  const { style, onClick, blackOrWhite = null } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const onRotate = () => {
    setIsExpanded(p => !p)
  }

  return (
    <button type="button" style={style}
    className={`
      expand-btn
        ${ blackOrWhite === "white" ? 'expand-btn_white' : blackOrWhite === "black" ? "expand-btn_black" : "black" }
        ${isExpanded && "expand-btn--expanded"}  
      `}
    onClick={() => {onClick(); onRotate()}}>
    </button>
  ) 
}