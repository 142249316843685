import apiConfig from "../../../apiConfig.json"

export const getAllEquipments = (accessToken) => {
    return fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_EQUIPMENT}`, {
    method: "GET",
    headers: {
      'Authorization' : `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
      'Content-Type': 'application/json'
    }
  })
  .then (response => response.json())
  .then (data => {
    // console.log(data)
    return data
  })
  .catch (error => {
    console.log('An error occurred while trying to get equipment data: ', error)
    return []
  })
}