import { useState, useCallback, useEffect } from "react";
import { getDirectionsAndPlaces } from "@entities/districts/index";
// import { useAuth } from '@app/store/AuthContext';
import { GotoArrowBtn, CloseBtn } from "@widgets/buttons/index";
import "./_district-list.scss";

export const DistrictsList = (props) => {
  const {
    accessToken,
    district,
    setDistrict,
    arePlacesRequired = true,
  } = props;
  // const { accessToken } = useAuth()
  const [directions, setDirections] = useState([]);
  // const [places, setPlaces] = useState([])
  // const [district, setDistrict] = useState({name: '', type: ''})
  const [isDistrictsListOpened, setIsDistrictsListOpened] = useState(false);
  const [placesListSates, setPlacesListSates] = useState({});
  // const placesListRefs = ({})

  const fetchAllDistricts = useCallback(() => {
    const allDistricts = getDirectionsAndPlaces(accessToken);
    setDirections(allDistricts.dirs);
    // setPlaces(allDistricts.places)
  }, [accessToken]);
  
  useEffect(() => {
    fetchAllDistricts();
  }, [fetchAllDistricts]);

  const onToggleDistrictsList = () => {
    setIsDistrictsListOpened((p) => !p);
  };

  const onTogglePlacesList = (index) => {
    if (!placesListSates[index]) {
      setPlacesListSates((prevStates) => ({
        ...prevStates,
        [index]: true,
      }));
    } else {
      setPlacesListSates((prevStates) => ({
        ...prevStates,
        [index]: false,
      }));
    }
  };

  const onSelectDistrict = (type, name, dirname) => {
    if (type === "direction") {
      setDistrict({ name: name, type: "direction" });
    }

    if (type === "place") {
      setDistrict({ name: name, dirname: dirname, type: "place" });
    }

    setIsDistrictsListOpened(false);

    setPlacesListSates((prevStates) => {
      const newStates = {};
      Object.keys(prevStates).forEach((key) => {
        newStates[key] = false;
      });
      return newStates;
    });
  };

  return (
    <>
      <div className="dropdown-list">
        <div className="dropdown-list__field">
          <p className="districts__chosen-district">
            {district.name ? (
              <>
                {district.type === "direction"
                  ? "Выбранное направление: "
                  : "Выбранный участок: "}
                {district.name}
              </>
            ) : (
              "Выберите направление либо участок"
            )}
          </p>

          {!isDistrictsListOpened ? (
            <GotoArrowBtn onClick={onToggleDistrictsList} rotation="rb" />
          ) : (
            <CloseBtn blackOrWhite="white" onClick={onToggleDistrictsList} />
          )}
        </div>

        <div
          className={`dropdown-list__list ${
            isDistrictsListOpened ? "dropdown-list__list_opened" : ""
          } `}>
          <ul className="dropdown-list__list-list">
            {directions.map((dir, index) => {
              return (
                <>
                  <div key={dir.name} className="dropdown-list__list-container">
                    <li
                      className={`dropdown-list__item ${
                        district.name === dir.name ? "chosen" : ""
                      } `}
                      onClick={() => onSelectDistrict("direction", dir.name)}
                    >
                      <span className="dropdown-list__value">{dir.name}</span>
                    </li>

                    {arePlacesRequired && (
                      <button
                        className={`
                      dropdown-list__expand-button
                      ${district.name === dir.name ? "chosen" : ""}
                      ${placesListSates[index] ? "rotated" : ""}
                      `}
                        type="button"
                        onClick={() => onTogglePlacesList(index)}
                      />
                    )}
                    {arePlacesRequired && (
                      <>
                        {placesListSates[index] && (
                          <div
                            className={`dropdown-list-inner-list ${
                              placesListSates[index]
                                ? "dropdown-list__list_opened"
                                : ""
                            } expand-animation`}
                          >
                            <ul className="dropdown-list__list-list">
                              {dir.places.length > 0 ? (
                                <>
                                  {dir.places.map((place) => {
                                    return (
                                      <li
                                        key={place.name}
                                        className={`dropdown-list-inner-list__item districts-list__item ${
                                          district.name === place.name
                                            ? "chosen"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          onSelectDistrict(
                                            "place",
                                            place.name,
                                            dir.name
                                          )
                                        }
                                      >
                                        <span className="districts-list__name">
                                          {place.name}
                                        </span>
                                      </li>
                                    );
                                  })}
                                </>
                              ) : (
                                <p className="districts-list__note">
                                  У этого направления нет участков.
                                </p>
                              )}
                            </ul>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
