import { useState, useRef, useEffect, useCallback } from 'react'
import { createPortal } from "react-dom"
import { CloseBtn, ExpandBtn } from '@widgets/buttons/index'
import { checkEpmtyValues } from '@features/forms/index'
import { getImageForTaskType } from "@entities/task/index"
import { statuses } from "@entities/task/index"

export const EditProjectForm = (props) => {
  const { onClose, accessToken } = props

  const [name, setName] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [description, setDescription] = useState("")
  const [type, setType] = useState("planned")
  const [isStatusListExpanded, setIsStatusListExpanded] = useState(false)

  const [errors, setErrors] = useState({})
  
  const rootElement = document.querySelector('.body-wrapper')

  const checkIfEmpty = (value, field) => {
    const error = checkEpmtyValues(value)
    if (error.state) {
      setErrors(p => ({
        ...p,
        [field]: error
      }))
    }
  }

  const onChangeFieldValue = (value, field, setter) => {
    setter(value)
    checkIfEmpty(value, field)
  }

  const onToggleList = () => {
    setIsStatusListExpanded(p => !p)
  }

  return (
    <> 
      {
        createPortal (
          <div className="edit-project-form modal-container">
            <form action="POST" className='edit-project-form-form modal-container__wrapper'>
              <div className="edit-project-form__header">
                <span className="edit-project-form__header-text">Редактирование</span>
                <CloseBtn blackOrWhite="black" style={{position: "absolute", top: "25%", right: "3%"}} onClick={onClose} />
              </div>
              
              <div className="edit-project-form-main">

                <div className="edit-project-form-field edit-project-form-status">
                  <label htmlFor="name" className="edit-project-form-field__label">Статус</label>
                  <div className="edit-project-form-status-field">
                    <img src={getImageForTaskType(type)} alt="" className="edit-project-form-status-field__img" />
                    <span className='edit-project-form-status-field__text'>Запланировано</span>
                    <ExpandBtn onClick={onToggleList} style={{width: '2.3rem'}} />
                    {/* <button className="expand-button"></button> */}
                  </div>
                    { isStatusListExpanded &&
                      <>
                        <ul className="edit-project-form-status-field__list">
                          {Object.keys(statuses).map(item => (
                            <>
                              {type !== item &&
                                <li key={item} className="edit-project-form-status-field__item">
                                  <img src={getImageForTaskType(item)} alt="" className="edit-project-form-status-field__img" />
                                  {statuses[item]}
                                </li>
                              }
                            </>
                          ))}
                        </ul>
                      </>                   
                    }
                  
                </div>

                <div className="edit-project-form-field">
                  <label htmlFor="name" className="edit-project-form-field__label">Название</label>
                  <input
                    className='edit-project-form-field__input'
                    id="name"
                    placeholder='Введите название проекта'
                    value={name}
                    onChange={(e) => onChangeFieldValue(e.target.value, name, setName)}
                  />
                </div>

                <div className='edit-project-form-field'>
                  <label className='edit-project-form-field__label'>Период выполнения</label>
                  <div className="edit-project-form-row-container">
                    <div className="edit-project-form-field_date-wrapper">
                      <input
                        className='edit-project-form-field__input'
                        id="date"
                        placeholder='Дата начала'
                        value={startDate}
                        onChange={(e) => onChangeFieldValue(e.target.value, startDate, setStartDate)}
                        />
                    </div>
                    <div className="edit-project-form-field_date-wrapper">
                      <input
                        className='edit-project-form-field__input'
                        id="date"
                        placeholder='Дата окончания'
                        value={endDate}
                        onChange={(e) => onChangeFieldValue(e.target.value, endDate, setEndDate)}
                        />
                    </div>
                  </div>
                </div>

                <div className='edit-project-form-field edit-project-form-extra-info'>
                  <label className='edit-project-form-field__label'>Дополнительная информация</label>
                  <textarea className='edit-project-form-field__textarea edit-project-form-extra-info__textarea'
                    value={description}
                    onChange={(e) => onChangeFieldValue(e.target.value, description, setDescription)}
                    placeholder='Опишите проект'
                    ></textarea>
                </div>

                <div className="edit-project-form__button-wrapper">
                  <button type="submit" className="edit-project-form__create-project-button">Сохранить</button>
                </div>
              </div>

            </form>
          </div>, rootElement   
        )
      }
    </>
  )
}