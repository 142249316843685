import { Link } from 'react-router-dom';
import HeaderChart from './HeaderChart.jsx';
import { HeaderDocumentation } from './HeaderDocumentation.jsx';

const Header = ({projectName, projectId}) => {
    return (
        <>
          <div className="tracker-header__wrapper">
            <div className="tracker-header__item tracker-header__my-tasks">
            <HeaderDocumentation projectName={projectName}
            projectId={projectId} />
            </div>


            <div className="tracker-header__item tracker-header__diagram">
              <Link>
               <button className="tracker-goto-button tracker-goto-arrow-button rotated-right-bottom"></button>
              </Link>
            </div>

            <div className="tracker-header__item tracker-header__gant-chart">
              <HeaderChart projectName={projectName}
            projectId={projectId} />
              {/* <Link to="/gantt-chart">
               <button className="tracker-goto-button tracker-goto-arrow-button rotated-right-bottom"></button>
              </Link> */}
            </div>
          </div>
        </>
    )
};

export default Header;
