import { useState } from 'react'

const useTaskFields = () => {
  const [name, setName] = useState('')
  const [qualifier, setQualifier] = useState('')
  const [qualifierId, setQualifierId] = useState('')
  const [district, setDistrict] = useState({name: '', type: ''})
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [evaluators, setEvaluators] = useState([])
  const [performers, setPerformers] = useState([])
  const [facility, setFacility] = useState('')
  const [type, setType] = useState('')
  const [description, setDescription] = useState('')
  const [equipments, setEquipments] = useState('')
  const [estimatedVolume, setEstimatedVolume] = useState('')
  const [volumeMetrics, setVolumeMetrics] = useState('')
  const [workNumber, setWorkNumber] = useState('')
  const [metricCost, setMetricCost] = useState('')
  const [totalCost, setTotalCost] = useState('')
  const [extraInfo, setExtraInfo] = useState('')
  const [planInNumericalTerms, setPlanInNumericalTerms] = useState(0)
  const [planInMonetaryTerms, setPlanInMonetaryTerms] = useState('')
  
  return {
    name: {value: name, set: setName},
    qualifier: {value: qualifier, set: setQualifier },
    qualifierId: {value: qualifierId, set: setQualifierId },
    district: { value: district, set: setDistrict },
    start: {value: start, set: setStart},
    end: {value: end, set: setEnd},
    evaluators: {value: evaluators, set: setEvaluators},
    performers: {value: performers, set: setPerformers},
    facility: {value: facility, set: setFacility},
    type: {value: type, set: setType},
    description: {value: description, set: setDescription},
    equipments: {value: equipments, set: setEquipments},
    estimatedVolume: {value: estimatedVolume, set: setEstimatedVolume},
    volumeMetrics: {value: volumeMetrics, set: setVolumeMetrics},
    workNumber: {value: workNumber, set: setWorkNumber},
    metricCost: {value: metricCost, set: setMetricCost},
    totalCost: {value: totalCost, set: setTotalCost},
    extraInfo: {value: extraInfo, set: setExtraInfo},
    planInNumericalTerms: {value: planInNumericalTerms, set: setPlanInNumericalTerms},
    planInMonetaryTerms: {value: planInMonetaryTerms, set: setPlanInMonetaryTerms},
    allValues: {
      name: name,
      qualifier: qualifier,
      qualifierId: qualifierId,
      // district: district,
      start: start,
      end: end,
      evaluators: evaluators,
      performers: performers,
      // facility: facility,
      // type: type,
      description: description,
      equipments: equipments,
      estimatedVolume: estimatedVolume,
      volumeMetrics: volumeMetrics,
      workNumber: workNumber,
      metricCost: metricCost,
      totalCost: totalCost,
      extraInfo: extraInfo,
      // planInNumericalTerms: planInNumericalTerms,
      // planInMonetaryTerms: planInMonetaryTerms
    }
  }
}

export { useTaskFields }