// import { useRef } from "react"
import { Link } from 'react-router-dom'

export const CloseBtn = (props) => {
  const { bg="", style, onClick, gotoLink = "", blackOrWhite = null, ref } = props
  // const btnRef = useRef(null)
  // console.log(onClick)
  return (
    <button type="button" style={style} ref={ref}
    className={`
      close-btn
      ${ blackOrWhite === "white" ? 'close-btn_white' : blackOrWhite === "black" ? "close-btn_black" : "black" }
      ${ bg === 'dark' ? "close-btn_bg-dark" : bg === "white" ? "close-btn_bg-white" : ""} `}
    onClick={onClick}>
      {gotoLink.length > 0 &&
        <Link
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
          color: 'black',
          textDecoration: 'none',
          width: '100%',
          height: '100%',
          borderRadius:'30px'
        }}
        to={gotoLink}
        />
      }
    </button>
  ) 
}