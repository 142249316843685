import { createContext, useContext } from "react";

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const accessToken = localStorage.getItem('accessToken') || ""
  // const accessToken = 1
  
  return <AuthContext.Provider value={accessToken}>{children}</AuthContext.Provider>
}

const useAuth = () => { return useContext(AuthContext) }

export { AuthProvider, useAuth }