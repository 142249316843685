import { useState } from "react";
import { GotoArrowBtn, CloseBtn } from "@widgets/buttons";

export const DropdownList = (props) => {
  const { style, isDropdownAllowed = true, heading, ifContentEpmtyMessage, content, contentName, selectedItem, setSelectedItem, idName } = props
  const [isListOpened, setIsListOpened] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState()
  
   const onToggleList = () => {
    setIsListOpened(p => !p)
  }

  const onSelect = (item, id, index) => {
    setSelectedItem({id: id, name: item})
    setSelectedItemId(index)
    onToggleList()
  }
  // console.log(selectedItem)
  return (
    <>
      <div className="districts" style={style}>
        <div className="districts-field">
          <p className="districts__chosen-district">
            {selectedItem.name ? <>{selectedItem.name}</> : <>{heading}</>}
          </p>

          {isDropdownAllowed &&
            <>
              {!isListOpened ? (
                <GotoArrowBtn onClick={onToggleList} rotation="rb" />
              ) : (
                <CloseBtn blackOrWhite="black" onClick={onToggleList} />
              )}
            </>
          }
        </div>

        <div
          className={`dropdown-list__list ${
            isListOpened && "dropdown-list__list_opened"
          } `}
        >
          {content.length > 0 ? (
            <ul className="dropdown-list__list-list">
              {content.map((item, index) => {
                // console.log(item)
                return (
                  // <>
                    <div key={index} className="dropdown-list__list-container">
                      <li
                        className={`dropdown-list__item ${
                          // selectedItem === item[contentName] ? "chosen" : ""
                          selectedItemId === index && "chosen"
                        } `}
                        onClick={() => onSelect(item[contentName], item[idName], index)}
                      >
                        <span className="dropdown-list__value">{item[contentName]}</span>
                      </li>
                    </div>
                  // {/* </> */}
                )
              })}
            </ul>
          ) : (
            <div className="dropdown-list__empty-message">
              {ifContentEpmtyMessage}
            </div>
          )
        }
        </div>
      </div>
    </>
  );
};
