import { useState, useEffect, useRef, useCallback } from 'react';
import ModalDelete from '../../Components/ModalDelete';
import ModalNote from '../../Components/ModalNote';
import './tableRow.css';
import apiConfig from "../../apiConfig.json";
import { createPortal } from 'react-dom';
import { subMonths, format, parseISO, subWeeks, isWithinInterval, set } from 'date-fns';
import UserInfoModal from '../ShiftsTable/UserInfoModal';
import EquipInfoModal from '../ShiftsTable/EquipInfoModal';

const TableRow = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditorDetectorOpen, setIsEditorDetectorOpen] = useState(false);
  const [isEditorPasswordOpen, setIsEditorPasswordOpen] = useState(false);
  const [isEditorEquipOpen, setIsEditorEquipOpen] = useState(false);
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
  const [isDeleteEquipOpen, setIsDeleteEquipOpen] = useState(false);
  // const [isDeleteDetectorOpen, setIsDeleteDetectorOpen] = useState(false);
  const [isModalNoteOpened, setIsModalNoteOpened] = useState(false);
  const [isModalDeleteOpened, setIsModalDeleteOpened] = useState(false);

  const [username, setUsername] = useState('');
  const [equipVin, setEquipVin] = useState('');
  const [detector, setDetector] = useState('');
  const [newPassword, setNewPassord] = useState('');
  const [equipmentList, setEquipmentList] = useState([]);
  const [originEquipmentList, setOriginEquipmentList] = useState([]);
  const [isEditorDetectorModalActionEnd, setIsEditorDetectorModalActionEnd] = useState(false)
  const [isDetectorDeletionModalActionEnd, setIsDetectorDeletionModalActionEnd] = useState(false)
  const [isPasswordModalActionEnd, setIsPasswordModalActionEnd] = useState(false)
  const [isEquipsModalActionEnd, setIsEquipsModalActionEnd] = useState(false)
  const [isDeleteUserActionEnd, setIsDeleteUserActionEnd] = useState(false)
  const [isDeleteEquipActionEnd, setIsDeleteEquipActionEnd] = useState(false)
  const [newImei, setNewImei] = useState('');
  // const [isModalAddImeiOpen, setIsModalAddImeiOpen] = useState(false);
  // const [isModalDeleteImeiOpen, setIsModalDeleteImeiOpen] = useState(false);
  const [isModalAddImeiOpen, setIsModalAddImeiOpen] = useState(false);
  const [isModalDeleteImeiOpen, setIsModalDeleteImeiOpen] = useState(false);
  const [isEpmtyError, setIsEmptyError] = useState({text: 'Заполните это поле.', state: false});
  const [isFetchError, setIsFetchError] = useState({text: "", state: false});
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [showEquipInfoModal, setShowEquipInfoModal] =useState(false);
  const [equip, setEquip] = useState('');
  const [warrantyNum, setWarrantyNum]= useState('');
  const [equipmentType, setEquipmentType] = useState('');

  // const [errorMessage, setErrorMessage] = useState({isOpened: false, message: 'Что-то пошло не так...'})
  // const errorMessageRef = useRef(null);
  const modalRef = useRef(null);
  const modalDeleteRef = useRef(null);

  const [userState, setUserState] = useState({
    loading: false,
    userInfo: null,
  })

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const EQUIPS = [
    'EXCAVATOR',
    'BULLDOZER',
    'CRANE',
    'GRADER',
    'RINK',
    'TRUCK',
    'REFUELER',
    'AUTO',
    'POOKER'
  ]

  const getImageForEquipmentType = (equipmentType) => {
    // console.log(equipmentType)
    switch (equipmentType) {
      case 'EXCAVATOR':
        return require('../../Images/Equipment/excavator_32.png');
      case 'BULLDOZER':
        return require('../../Images/Equipment/bulldozer_32.png');
      case 'CRANE':
        return require('../../Images/Equipment/crane_32.png');
      case 'GRADER':
        return require('../../Images/Equipment/grader_32.png');
      case 'RINC':
        return require('../../Images/Equipment/rinc_32.png');
      case 'RINK':
        return require('../../Images/Equipment/rink_32.png');
      case 'TRUCK':
        return require('../../Images/Equipment/truck_32.png');
      case 'POOKER':
        return require('../../Images/Equipment/camera_10.png');
      case 'AUTO':
        return require('../../Images/Equipment/auto_32.png');
      case 'REFUELER':
        return require('../../Images/Equipment/refueler_512.png');
      case 'BUS':
        return require('../../Images/Equipment/bus_512.png');
      default:
        return require('../../Images/Equipment/excavator_32.png');
    }
  };

  const data = props.data;
  const page = props.page;
  const user = props.user;
  const imei = props.imei;
  const accessToken = props.accessToken;
  const activeColumn = props.activeColumn;
  const mac = data[5]
  const currId = data[6]
  const placeModalElem = document.querySelector('.body-wrapper');
  const onFormSubmit = props.onFormSubmit;
  
  // console.log(data)
  // const handleClickOutside = useCallback((event) => {
  //   console.log(event.target);
  //   // console.log(modalRef.current)
  //   console.log(modalDeleteRef.current)
  //   // console.log(!modalRef.current.contains(event.target))
  //   if (modalDeleteRef.current && event.target) {
  //     console.log(!modalDeleteRef.current.contains(event.target))
  //   }
  //   // console.log(modalRef.current && !modalRef.current.contains(event.target))
  //   console.log(modalDeleteRef.current && !modalDeleteRef.current.contains(event.target))
  //   if(modalDeleteRef.current && !modalDeleteRef.current.contains(event.target)) {
  //     console.log('Зашли закрыть модалку для удаления')
  //     handleCloseDeleteModal();
  //     return;
  //   }
  //   else if(modalRef.current && !modalRef.current.contains(event.target)) {
  //     console.log('Зашли закрыть модалку обычную')
  //     handleCloseModal();
  //   }

  // }, []);

  // useEffect(() => {
  //   if (isModalOpen || isModalDeleteOpened) {
  //     console.log("test")
  //     document.addEventListener('mousedown', handleClickOutside);
  //     console.log(modalRef.current);
  //   }
  //   else {
  //     console.log("test2")
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   }
  // }, [isModalOpen, isModalDeleteOpened, handleClickOutside]);


  useEffect(() => {
    const handleCloseFilterByEscape = (e) => {
      if (isModalOpen && e.key === 'Escape') {
        handleCloseModal()
      }
    };
    document.body.addEventListener('keydown', handleCloseFilterByEscape);

    return () => {
      document.body.removeEventListener('keydown', handleCloseFilterByEscape);
    };
  }, [isModalOpen]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleOpenDetectorEditor = () => setIsEditorDetectorOpen(true);

  // const handleLogout = () => {
  //   localStorage.removeItem('accessToken');
  //   navigate('/');
  //   window.location.reload();
  // }

  const handleCloseNoteModal = (setCurrentAction) => {
    setCurrentAction(false);
    setIsModalNoteOpened(false);
  }
  // const handleOpenDeleteModal = () => {
  //   console.log('зашла в handleOpenDeleteModal')
  //   setIsModalDeleteOpened(true);
  //   console.log('а это какое условие: ', isModalDeleteOpened)
  //   // handleCloseModal();
  // }

  const handleCloseDeleteModal = () => {
    setIsModalDeleteOpened(false);
    // setIsDeleteUserOpen(false);
  }

  const handleOpenPasswordModal = () => {
    // setIsModalNoteOpened(true);
    setIsEditorPasswordOpen(true);
    setUsername(data[4]);
  }

  const handleOpenEquipModal = () => {
    setIsEditorEquipOpen(true);
    setUsername(data[4]);
  }

  const handleOpenAddImeiModal = () => {
    setIsModalAddImeiOpen(true);
  }

  const handleOpenDeleteImeiModal = () => {
    setIsModalDeleteImeiOpen(true);
  }
  // const handleCloseEquipModal = () => {
  //   setIsEditorEquipOpen(false);
  // }
  const handleOpenDeleteUser = () => {
    setIsDeleteUserOpen(true);
    setIsModalDeleteOpened(true);
    setUsername(data[4]);
    // handleCloseModal();
    // console.log('а это какое условие: ', isModalDeleteOpened)
  }

  const handleOpenDeleteEquipment = () => {
    setIsDeleteEquipOpen(true);
    setIsModalDeleteOpened(true);
    setEquipVin(data[2]);
  }

  const handleIsEmptyError = (value, setError) => {
    // console.log(value)
    if(!value.length) {
      setError(prevError => ({...prevError, state: true}));
      return true;
    } else {
      setError(prevError => ({...prevError, state: false}));
      return false;
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setIsEditorDetectorOpen(false);
    setIsEditorPasswordOpen(false);
    setIsEditorEquipOpen(false);
    setIsEditorDetectorModalActionEnd(false);
    setIsDetectorDeletionModalActionEnd(false);
    setIsPasswordModalActionEnd(false);
    setIsEquipsModalActionEnd(false);
    setIsDeleteUserActionEnd(false);
    setIsDeleteEquipActionEnd(false);
    setIsModalDeleteOpened(false);
    setIsModalNoteOpened(false);
    setIsModalAddImeiOpen(false);
    setEquipmentList([]);
    setOriginEquipmentList([]);
    setNewPassord('');
    setIsFetchError({text: '', state: false})
  }

  useEffect(() => {
    // if(isEditorDetectorModalActionEnd || isPasswordModalActionEnd || isEquipsModalActionEnd || isDeleteEquipActionEnd || isDeleteUserActionEnd) {
    if(isEquipsModalActionEnd || isDeleteEquipActionEnd || isDeleteUserActionEnd) {
      const timer = setTimeout(() => {
        setIsModalOpen(false)
        setIsEditorDetectorOpen(false);
        setIsEditorPasswordOpen(false);
        setIsEditorEquipOpen(false);
        setIsModalDeleteOpened(false);
        setIsModalNoteOpened(false);
        // setIsEditorDetectorModalActionEnd(false);
        setIsDetectorDeletionModalActionEnd(false);
        // setIsPasswordModalActionEnd(false);
        setIsEquipsModalActionEnd(false);
        setEquipmentList([]);
        setIsFetchError({text: '', state: false})
        setNewPassord('');
        // handleCloseModal();
        onFormSubmit();
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [isEditorDetectorModalActionEnd, isPasswordModalActionEnd, isEquipsModalActionEnd, isDeleteEquipActionEnd, isDeleteUserActionEnd, onFormSubmit]);

  const handleDetectorChange = (e) => {
    if (e.target.value === '') {
    setDetector('');
      return;
    }
    let inputValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '')
    let maskedValue = '';
    for (let i = 0; i < inputValue.length; i++ ) {
      if(i % 2 === 0 && i !== 0) {
        maskedValue += ':';
      }
      maskedValue +=inputValue[i];
      if(!inputValue) {
        maskedValue = ''
      }

    setDetector(maskedValue);
  }
  }
  const handleSwitchEquipment = (type) => {
    setEquipmentList(prevList => {
      if(!prevList.includes(type)) {
        return [...prevList, type];
      } else {
        return prevList.filter(item => item !== type );
      }
    });
  };

  const handleDeleteUser = () => {
    const name = username;
    // console.log(name)
    // console.log(name)
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.DELETE_USER}${name}`, {
      method: "DELETE",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
    .then (response => {
      if(response.ok) {
        console.log('user was deleted successfully!');
        setIsDeleteUserOpen(false);
        handleCloseDeleteModal();
        setIsDeleteUserActionEnd(true);
        setIsModalNoteOpened(true);
        onFormSubmit();
      }
    })
    .catch (error => {
      setIsDeleteUserOpen(false);
        handleCloseDeleteModal();
        setIsDeleteUserActionEnd(true);
        setIsModalNoteOpened(true)
        setIsFetchError({text: error, state: true})
      console.log('error deleting user: ', error);
    });
  }

  const handleDeleteEquipment = () => {
    const vin = equipVin;
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.DELETE_EQUIPMENT}${vin}`, {
      method: "DELETE",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
    .then (response => {
      if(response.ok) {
        console.log('equipment was deleted successfully!');
        setIsDeleteEquipOpen(false);
        handleCloseDeleteModal();
        setIsDeleteEquipActionEnd(true);
        setIsModalNoteOpened(true)
      }
    })
    .catch (error => {
      setIsDeleteEquipOpen(false);
      handleCloseDeleteModal();
      setIsDeleteEquipActionEnd(true);
      setIsModalNoteOpened(true)
      setIsFetchError({text: error, state: true});
      console.log('error deleting user: ', error);
    });
  }

  useEffect(() => {
    if (isEditorEquipOpen) {
      setOriginEquipmentList(data[3] || []);
      setEquipmentList(data[3] || []);
    }
  }, [isEditorEquipOpen, data]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmitNewPassword();
      handleSubmitNewDetector();
    }
    // if (event.key === 'Escape') {
    //   handleCloseModal();
    // }
  };

  // const handleBlur = (event) => {
  //   switch (event.target.value) {
  //     case !event.target.value:
  //       setIsEmptyError(true);
  //       break;
  //     default:
  //       setIsEmptyError(false);
  //   }
  // }

  const handleSubmitNewPassword = () => {
    if (handleIsEmptyError(newPassword, setIsEmptyError) === true) {
      return;
    };
    const formData = {
      password: newPassword,
      username: username
    }

    // console.log(formData)

    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.CHANGE_PASSWORD}`, {
      method: "POST",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(response => {
      if(response.ok) {
        console.log('password changed succesfully!');
      }
      setIsEditorPasswordOpen(false)
      setIsPasswordModalActionEnd(true)
      setIsModalNoteOpened(true)
      // console.log('is password end: ', isPasswordModalActionEnd, 'is modal opened: ', isModalNoteOpened)
    })
    .catch(error => {
      setIsEditorPasswordOpen(false)
      setIsPasswordModalActionEnd(true)
      setIsModalNoteOpened(true)
      setIsFetchError({text: error, state: true});
      console.log('error changing password: ', error);
    });
    // console.log(isPasswordModalActionEnd, isEquipsModalActionEnd)
  }

  const handleSubmitNewEquips = () => {
    // const userId = data[5];
    const username = data[4]
    const currentList = equipmentList;
    const originList = originEquipmentList;
    const deletedEquips = originList.filter(item => !currentList.includes(item));
    const addedEquips = currentList.filter(item => !originList.includes(item));
    const urlAdd = `${apiConfig.BASE_URL}.${apiConfig.ENDPOINTS.ADD_EQUIPMENTS}`;
    const urlDelete = `${apiConfig.BASE_URL}.${apiConfig.ENDPOINTS.DELETE_EQUIPMENTS}`
    let formData;

    if(deletedEquips.length && !addedEquips.length) {
      formData = {
        username: username,
        types: deletedEquips
      }
    fetch(urlDelete, {
        method: "POST",
        headers: {
          'Authorization' : `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(response => {
        if(response.ok) {
          console.log('extra equips were deleted successfully!')
        }
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true);
      })
      .catch(error => {
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true);
        setIsFetchError({text: error, state: true});
        console.log('error fetching new equipment list: ', error);
      })

    } else if (!deletedEquips.length && addedEquips.length) {

      formData = {
        username: username,
        types: addedEquips
      }
    // console.log('зашли добавить чёта: ', formData)
    fetch(urlAdd, {
        method: "POST",
        headers: {
          'Authorization' : `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(response => {
        if(response.ok) {
          console.log('new equips were added successfully!')
        }
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true)
      })
      .catch(error => {
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true)
        setIsFetchError({text: error, state: true});
        console.log('error fetching new equipment list: ', error);
      })
    } else if (deletedEquips.length && addedEquips.length) {
      formData = {
        username: username,
        types: deletedEquips
      }
      fetch(urlDelete, {
        method: "POST",
        headers: {
          'Authorization' : `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(response => {
        if(response.ok) {
          console.log('extra equips were deleted successfully!')
        }
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true)
      })
      .catch(error => {
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true)
        setIsFetchError({text: error, state: true});
        console.log('error fetching new equipment list: ', error);
      })

      formData = {
        username: username,
        types: addedEquips
      }
      // console.log('зашли добавить чёта: ', formData)
      fetch(urlAdd, {
        method: "POST",
        headers: {
          'Authorization' : `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(response => {
        if(response.ok) {
          console.log('new equips were added successfully!')
        }
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true)
      })
      .catch(error => {
        setIsEditorEquipOpen(false)
        setIsEquipsModalActionEnd(true)
        setIsModalNoteOpened(true)
        setIsFetchError({text: error, state: true});
        console.log('error fetching new equipment list: ', error);
      })
    }
    else {
      // setErrorMessage({isOpened : true, message : 'Внесите изменения в список, чтобы продолжить.'});
      return;
    }
    // console.log(isPasswordModalActionEnd, isEquipsModalActionEnd)
  }

  const handleSubmitNewDetector = () => {
    const formData = {
      id: currId,
      mac: detector
    }

    // console.log(formData)
    fetch(`${apiConfig.BASED_URL}${apiConfig.ENDPOINTS.EDIT_MAC}`, {
      method: "POST",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
  if(response.ok) {
        console.log('detector deleted succesfully!');
        setIsEditorDetectorOpen(false);
        setIsEditorDetectorModalActionEnd(true);
        setIsModalNoteOpened(true);
        onFormSubmit();
      }
    })
    .catch(error => {
      setIsEditorDetectorOpen(false);
        setIsEditorDetectorModalActionEnd(true);
        setIsModalNoteOpened(true);
      setIsFetchError({text: error, state: true})
      console.log('error changing password: ', error);
    });
  }

  const handleSubmiteDeleteDetector = () => {
    // const url = `${apiConfig.BASED_URL}${'...'}`
    // fetch(url, {

    // })
    // .then(response => {
    //   response.json();
    // if(response.ok) {
    }
    // })
    // .then(response => {
    //   if(response.ok) {
    //     console.log('detector deleted succesfully!');
        // setIsDeleteDetectorOpen(false);
        // setIsDetectorDeletionModalActionEnd(true);
        // setIsModalNoteOpened(true);
    //   }
    // })
    // .catch(error => {
      // setIsDeleteDetectorOpen(false);
        // setIsDetectorDeletionModalActionEnd(true);
        // setIsModalNoteOpened(true);
    //   setIsFetchError({text: error, state: true})
    //   console.log('error changing password: ', error);
    // });
  // }

const handleAddImei = () => {

}

const handleDeleteImei = () => {

}

const toggleUserInfoModal = (user) => {
  setShowUserInfoModal(!showUserInfoModal);
}

const toggleEquipInfoModal = (equip, warrantyNum, equipmentType) => {
  setShowEquipInfoModal(!showEquipInfoModal);
  setEquip(equip);
  setWarrantyNum(warrantyNum);
  setEquipmentType(equipmentType);
}

  return(
    <>
    <UserInfoModal show={showUserInfoModal} onClose={toggleUserInfoModal} userName={user} accessToken={accessToken}/>
    <EquipInfoModal show={showEquipInfoModal} onClose={toggleEquipInfoModal} equip={equip} warrantyNum={warrantyNum} equipmentType={equipmentType} accessToken={accessToken}/>
      {
        page === 'users' ? (
          <>
            <div className='table-row table-row-users'>
              <ul className="table-row-list">
                <li className={isMobile ? 'table-row-item table-row-item-mobile' : "table-row-item"}>
                  {
                    imei !== null ? (
                      <div className="table-column-warranty table-row-item__imei-exists">
                        <button className="table-row-item__button--user" onClick={() => toggleUserInfoModal()}>
                          <p>{data[0]}</p>
                        </button>
                      </div>
                    ) : (
                      <div className="table-column-warranty">
                        <button className="table-row-item__button--user" onClick={() => toggleUserInfoModal()}>
                          <p>{data[0]}</p>
                        </button>
                      </div>
                    )
                  }
                </li>
                <li className={isMobile ? activeColumn === 0 ? 'table-row-item table-row-item-mobile' : 'inactive' : "table-row-item"}>
                  <p>{data[1]}</p>
                </li>
                <li className={isMobile ? activeColumn === 1 ? 'table-row-item table-row-item-mobile' : 'inactive' : "table-row-item"}>
                  <p>{data[2]}</p>
                </li>
                <li className={isMobile ? activeColumn === 2 ? 'table-row-item table-row-item-mobile' : 'inactive' : "table-row-item"}>
                  <ul className='users-equip-list'>
                      {
                        data[3].map((equip, index) => {
                          return(
                            // <>
                              <li key={index} className='users-equip-item'>
                                <img src={getImageForEquipmentType(equip)} alt="warrantyNum" width={30} height={30}/>
                              </li>
                            // </>
                          )
                        })
                      }
                  </ul>
                </li>
              </ul>
              <button className="table-row__button addition-change__button" onClick={handleOpenModal} />

                  {isModalOpen &&
                    <>
                      <div className="addition-change-modal" ref={modalRef}>
                          <button className="addition-close-modal__button" onClick={handleCloseModal}/>

                        {isEditorPasswordOpen ? (
                          <>
                          <label className="addition-label">
                          {(isEpmtyError.state) &&
                            <p className="validation-error-message">{isEpmtyError.text}</p>
                          }
                          <input
                            type="text"
                            className="addition-modal__input new-password__input"
                            value={newPassword}
                            onChange={(e) => {setNewPassord(e.target.value); handleIsEmptyError(e.target.value, setIsEmptyError)}}
                            onKeyDown={handleKeyPress}
                            // onBlur = {(e) => handleBlur(e)}
                            // minLength={10}
                            // maxLength={20}
                            placeholder='Введите новый пароль' />
                          <button className="addition-modal__button addition-users-add-password" onClick={handleSubmitNewPassword}>
                            Добавить
                          </button>
                          </label>
                          </>
                        ) : (isPasswordModalActionEnd && isModalNoteOpened) ? (
                          !isFetchError.state ? (
                            createPortal (
                              <ModalNote
                              note="Пароль успешно изменён! Можете скопировать свой новый пароль:"
                              type="success"
                              onClose={()=> handleCloseNoteModal(setIsPasswordModalActionEnd)}
                              password={newPassword} />,
                              placeModalElem
                            )
                            ) : (
                              createPortal (
                                <ModalNote
                                note= {`Возникла ошибка при замене пароля: ${isFetchError.text}`}
                                type="error"
                                onClose={()=> handleCloseNoteModal(setIsPasswordModalActionEnd)}
                                />,
                                placeModalElem
                              )
                            )
                          // <div className="detector-editor-note__wrapper">
                          //   <p className='detector-editor-note'>Пароль успешно сменён!</p>
                          // </div>

                        ) : isEditorEquipOpen ? (
                          <>
                          <label className="addition-label modal-change-equip">

                            {/* <div id='modal-change-equip' className="button-type-list addition-button-type-list addition-equipment-button-list addition-modal-equipment-list"> */}
                            {(isEpmtyError.state) &&
                              <p className="validation-error-message addition-form__role__error-message">{isEpmtyError.text}</p>
                            }
                            {EQUIPS.map((equip, index) => {
                              return (
                                <button key={index} type='button' className={`addition-button-add-equipment modal-change-equipment technic-type-${equipmentList.includes(equip) ? 'white' : 'black'}-${equip.toLowerCase()} ${equipmentList.includes(equip) ? 'selected' : ''}`}
                                onClick={(e) => {handleSwitchEquipment(e.target.value); }}
                                value={equip}
                                />
                              )
                            })}
                            {/* </div> */}
                            <button className="addition-modal__button addition-users-add-password" onClick={handleSubmitNewEquips}>
                              Изменить
                            </button>
                          </label>
                          </>
                        ) : (isEquipsModalActionEnd  && isModalNoteOpened) ? (
                          !isFetchError.state ? (
                            createPortal (
                              <ModalNote
                                note="Данные об оборудовании успешно сменены!"
                                type="success"
                                onClose={() => handleCloseNoteModal(setIsEquipsModalActionEnd)}
                              />,
                              placeModalElem
                            )

                          ) : (
                            createPortal (
                              <ModalNote
                              note={`Ошибка при смене оборудования: ${isFetchError.text}`}
                              type="error"
                              onClose={() => handleCloseNoteModal(setIsEquipsModalActionEnd)}
                              />,
                              placeModalElem
                            )
                          )
                          // <div className="detector-editor-note__wrapper">
                          //   <p className="detector-editor-note">Данные об оборудовании успешно сменены!</p>
                          // </div>
                        )
                        : (isDeleteUserOpen && isModalDeleteOpened) ? (
                          createPortal (
                            <ModalDelete
                            modalRef={modalDeleteRef}
                            message='Вы уверены, что хотите удалить пользователя? Отменить действие будет невозможно.'
                            actionText='Да, удалить'
                            action={handleDeleteUser}
                            onClose={handleCloseDeleteModal}/>,
                            placeModalElem
                          )

                        //   <>
                        //     <p className="modal-delete-note">Вы уверены, что хотите удалить пользователя? Отменить действие будет невозможно.</p>
                        //     <div className="modal-delete__buttons-wrapper">
                        //     <button className="modal-delete__button modal-delete__button-disgard" onClick={() => {handleOpenModal(); setIsDeleteUserOpen(false)}}>
                        //       Нет, не удалять
                        //     </button>
                        //     <button className="modal-delete__button modal-delete__button-accept" onClick={handleDeleteUser}>
                        //       Да, удалить
                        //     </button>
                        //     </div>
                        //   </>
                        )
                        : (isDeleteUserActionEnd && isModalNoteOpened) ? (
                            !isFetchError.state ? (
                              createPortal (
                                <ModalNote
                                note="Пользователь успешно удалён!"
                                type="success"
                                onClose={() => handleCloseNoteModal(setIsDeleteUserActionEnd)}
                                />,
                                placeModalElem
                              )

                            ) : (
                              createPortal (
                                <ModalNote
                                note={`Ошибка при удалении пользователя: ${isFetchError.text}`}
                                type="error"
                                onClose={() => handleCloseNoteModal(setIsDeleteUserActionEnd)}
                                />,
                                placeModalElem
                              )
                            )
                          // <div className="detector-editor-note__wrapper">
                          //   <p className="detector-editor-note">Пользователь успешно удалён!</p>
                          // </div>
                        )
                        : (isModalAddImeiOpen) ? (
                            <>
                            <label className="addition-label">
                          {(isEpmtyError.state) &&
                            <p className="validation-error-message">{isEpmtyError.text}</p>
                          }
                          <input
                            type="text"
                            className="addition-modal__input"
                            value={newImei}
                            onChange={(e) => {setNewImei(e.target.value); handleIsEmptyError(e.target.value, setIsEmptyError)}}
                            onKeyDown={handleKeyPress}
                            // onBlur = {(e) => handleBlur(e)}
                            // minLength={10}
                            // maxLength={20}
                            placeholder='Введите imei' />
                          <button className="addition-modal__button addition-users-add-password" onClick={handleAddImei}>
                            Добавить
                          </button>
                          </label>
                            </>
                        )
                        : (isModalDeleteImeiOpen && isModalDeleteOpened) ? (
                            <>
                            createPortal (
                                <ModalDelete
                                // modalRef={modalDeleteRef}
                                message='Вы уверены, что хотите удалить imei? Отменить действие будет невозможно.'
                                actionText='Да, удалить'
                                action={handleDeleteImei}
                                onClose={handleCloseDeleteModal}/>,
                                placeModalElem
                              )
                            </>
                        )
                        : (
                          <>
                            <button className="users-modal-option" onClick={handleOpenPasswordModal}>
                              Изменить пароль
                            </button>
                            <button className="users-modal-option" onClick={handleOpenEquipModal}>
                              Изменить оборудование
                            </button>
                            {imei ? (
                            <button className="users-modal-option" onClick={handleOpenDeleteImeiModal}>
                            Удалить imei
                          </button>
                            ) : (
                            <button className="users-modal-option" onClick={handleOpenAddImeiModal}>
                              Добавить imei
                            </button>
                            )}
                            <button className="users-modal-option users-modal-option-delete-user" onClick={handleOpenDeleteUser}>
                              Удалить пользователя
                            </button>
                          </>
                        )

                      }
                    </div>
                  </>
                }
            </div>
          </>
        ) : (
          <>
            <div className='table-row'>
                <ul className="table-row-list">
                  <li className={isMobile ? 'table-row-item table-row-item-mobile' : "table-row-item"}>
                    <div className="table-column-warranty">
                      <button className='table-column-warranty__button' onClick={() => toggleEquipInfoModal(data[2], data[1], data[0])}>
                        <img src={getImageForEquipmentType(data[0])} alt="warrantyNum" width={30} height={30}/>
                        <p>{data[1]}</p>
                      </button>
                    </div>
                  </li>
                  <li className={isMobile ? activeColumn === 0 ? 'table-row-item table-row-item-mobile' : 'inactive' : "table-row-item"}>
                    <p>{data[2]}</p>
                  </li>
                  <li className={isMobile ? activeColumn === 1 ? 'table-row-item table-row-item-mobile' : 'inactive' : "table-row-item"}>
                    <p>{data[3]}</p>
                  </li>
                  <li className={isMobile ? activeColumn === 2 ? 'table-row-item table-row-item-mobile' : 'inactive' : "table-row-item"}>
                    <p>{data[4]}</p>
                  </li>
                </ul>
                <button className="table-row__button addition-change__button" onClick={handleOpenModal} />

                {isModalOpen &&
                  <div className="addition-equip-modal">
                    <div className="addition-change-modal" ref={modalRef}>
                      <button className="addition-close-modal__button" onClick={handleCloseModal}/>
                      {isEditorDetectorOpen ? (
                          <>
                          <label className="addition-label">
                            {(isEpmtyError.state) &&
                              <p className="validation-error-message">{isEpmtyError.text}</p>
                            }
                            <input type="text" className="addition-modal__input new-detector__input"
                              value={detector}
                              onChange={(e) => {handleDetectorChange(e); handleIsEmptyError(e.target.value, setIsEmptyError)}}
                              onKeyDown={handleKeyPress}
                              maxLength={14}
                              placeholder='__:__:__:__:__'
                              />
                            <button className="addition-modal__button addition-detector-add-detector" onClick={handleSubmitNewDetector}>
                              Добавить
                            </button>
                          </label>
                          </>
                          )
                          : (isEditorDetectorModalActionEnd && isModalNoteOpened) ?
                          ( !isFetchError.state ? (
                            createPortal (
                              <ModalNote
                                type="success"
                                note="Датчик успешно добавлен! Можете его скопировать:"
                                onClose={() => handleCloseNoteModal(setIsEditorDetectorModalActionEnd)}
                                detector={detector}
                              />
                            )
                          ) : (
                            createPortal (
                              <ModalNote
                                type="error"
                                note={`Произошла ошибка при добавлении датчика: ${isFetchError.text}`}
                                onClose={() => handleCloseNoteModal(setIsEditorDetectorModalActionEnd)}
                              />
                            )
                          )
                            // <div className="detector-editor-note__wrapper">
                            //   <p className="detector-editor-note">Датчик успешно добавлен!</p>
                            // </div>
                          )
                          : (isDeleteEquipOpen && isModalDeleteOpened) ? (
                            createPortal (
                              <ModalDelete
                              modalRef={modalDeleteRef}
                              message='Вы уверены, что хотите удалить оборудование? Отменить действие будет невозможно.'
                              actionText='Да, удалить'
                              action={handleDeleteEquipment}
                              onClose={handleCloseDeleteModal}/>,
                              placeModalElem
                            )
                              //  <p className="modal-delete-note">Вы уверены, что хотите удалить оборудование? Отменить действие будет невозможно.</p>
                              // <div className="modal-delete__buttons-wrapper">
                              // <button className="modal-delete__button modal-delete__button-disgard" onClick={() => {handleOpenModal(); setIsDeleteEquipOpen(false)}}>
                              //   Нет, не удалять
                              // </button>
                              // <button className="modal-delete__button modal-delete__button-accept" onClick={handleDeleteEquipment}>
                              //   Да, удалить
                              // </button>
                              // </div>
                          )
                          : (isDeleteEquipActionEnd && isModalNoteOpened) ? (
                            !isFetchError.state ? (
                              createPortal (
                                <ModalNote
                                  type="success"
                                  note="Оборудование успешно удалено!"
                                  onClose={() => handleCloseNoteModal(setIsDeleteEquipActionEnd)}

                                />, placeModalElem
                              )
                            ) : (
                              createPortal (
                                <ModalNote
                                  note={`Ошибка при удалении оборудования: ${isFetchError.text}`}
                                  type="error"
                                  onClose={() => handleCloseNoteModal(setIsDeleteEquipActionEnd)}
                                  />,
                                  placeModalElem
                                )
                            )
                            // <div className="detector-editor-note__wrapper">
                            //   <p className="detector-editor-note">Оборудование успешно удалено!</p>
                            // </div>
                          )
                          : (
                            <>
                            {
                              mac === '-' ? (
                                <button className="users-modal-option" onClick={handleOpenDetectorEditor}>
                                Добавить датчик
                                </button>
                              ) : (
                                <button className="users-modal-option" onClick={handleSubmiteDeleteDetector}>Удалить датчик</button>
                              )
                            }
                            <button className="users-modal-option users-modal-option-delete-user" onClick={handleOpenDeleteEquipment}>
                              Удалить оборудование
                            </button>
                          </>
                          )
                        }
                    </div>
                  </div>
                }

              </div>
          </>
        )
      }
    </>
  )
}
export default TableRow;
