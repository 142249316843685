import { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import './_start-menu.scss';
import './_start-navigation.scss';
import { UserProvider} from "../model/userModel";
import { UserInfo } from "./userInfo";
import { OrganizationInfo } from "./organizationInfo";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/slices/authSlice';
import AdminMenu from "./AdminMenu";
import DirectionAdminMenu from "./DirectionAdminMenu";
import PlaceAdminMenu from "./PlaceAdminMenu";

const StartMenu = ({accessToken}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
      localStorage.removeItem('accessToken');
      dispatch(logout());
      navigate('/');
      window.location.reload();
  }

  const user = useSelector((state) => state.auth);

  const getStartMenuComponent = (user) => {
    // console.log(user)
    //   if (user.roles.includes('ADMIN')) {
    //     return <AdminMenu handleLogout={handleLogout} user={user} />;
    //   } else if (user.roles.includes('LOCAL_ADMIN')) {
    //     if (user.place === null) {
    //       return <DirectionAdminMenu handleLogout={handleLogout} user={user} />;
    //     }
    //     if (user.direction && user.place !== null) {
    //       return <PlaceAdminMenu handleLogout={handleLogout} user={user} />;
    //     }
    //   }
    return <AdminMenu  handleLogout={handleLogout} user={user}/>;
  };


  const StartMenuComponent = getStartMenuComponent(user?.user);

  return(
    <>
    {
      user.isLoggedIn === true &&
      <div>
        {StartMenuComponent}
      </div>
    }

    </>
  )
}

export default StartMenu;

