import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store/index';
import { AuthProvider } from './app/store/AuthContext';
import { App } from './app/routes/App';
import './app/styles/index.scss'
// import reportWebVitals from '../../reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Provider>
);

// reportWebVitals();
