  import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import GotoFAQButton from '../../../Components/GotoFAQButton';

export const SwitcherBetweenPages = (props) => {
  const { firstPage, secondPage, currentPage, firstLink, secondLink, faq } = props

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const onSetCategory = (category) => {
    localStorage.setItem("category", category)
  }


  return (
    <div style={{position: 'relative'}} className={isMobile ? "switcher-wrapper switcher-wrapper-mobile" : 'switcher-wrapper'}>
      {
        currentPage === firstPage && (
          <>
          <Link className="link-switch link-switch-users" to={firstLink}>{firstPage}</Link>
          <Link className="link-switch link-switch-equip link-switch-current" to={secondLink}>{secondPage}</Link>
          </>
        )
      }
      {
        currentPage === secondPage && (
          <>
          <Link className="link-switch link-switch-users link-switch-current" to={secondLink}>{firstPage}</Link>
          <Link className="link-switch link-switch-equip" to='/addition-equip'>{secondPage}</Link>
          </>
        )
      }
      {
        faq === 'true' &&
        <>
        {isMobile ?
          <GotoFAQButton
          category="addition"
          top="-0.2rem"
          right="-3.3rem"
          width="35px"
          height="35px"
          fontSize="18px"
          />
          :
          <GotoFAQButton
            category="addition"
            top="0.1rem"
            right="-5rem"
          />
        }
        </>
      }
      {/* {isMobile ?
        <GotoFAQButton
        category="addition"
        top="-0.2rem"
        right="-3.3rem"
        width="35px"
        height="35px"
        fontSize="18px"
        />
        :
        <GotoFAQButton
          category="addition"
          top="0.1rem"
          right="-5rem"
        />
      } */}
    </div>
  )
}
