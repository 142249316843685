import { Link } from 'react-router-dom';
import '../taskTracker.css';

const HeaderChart = (props) => {
  const { projectName = "—", projectId="" } = props;
  let queryString = '';
  if(projectId !== null && projectId.length !== 0) {
    queryString = new URLSearchParams({ projId: projectId, projName: projectName }).toString()
  }
  return (

    <div className="header-chart__wrapper">
    <div className="header-chart__dates">
      <Link to={queryString === '' ? "/gantt-chart" : `/gantt-chart?${queryString}`}>
        <button className="tracker-goto-button tracker-goto-arrow-button rotated-right-bottom"></button>
      </Link>
    </div>
  </div>
  )
};

export default HeaderChart;
