import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import logo from '../Images/Menu/logo.svg';
import '../App.css';
import LoadApkFile from '../Services/LoadApkFile';
import './navigation.css'
import ApkDownload from './ApkDownload';
import { useDispatch } from 'react-redux';
import { logout } from '../store/slices/authSlice';

const NavigationMenu = ({currentPage, accessToken}) => {

    const navigate = useNavigate();
    const classForCurrent = '-current';
    const dispatch = useDispatch();
    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        dispatch(logout());
        navigate('/');
        window.location.reload();
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 1000);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      }
    }, []);

    return (
    <>
      <div className={isMobile ? ' navigation-container navigation-container-mobile' : 'navigation-container'}>
        <nav className='navigation'>
            <Link to='/' className='logo-monstr' >
              <img src={logo} alt='логотип' width={isMobile ? 20 :30} height={isMobile ? 60 : 70}/>
            </Link>
            <ul className='navigation-list'>
              <li className={`navigation-item navigation-item-table${currentPage === 'table' ? `${classForCurrent} current-item` : ''}`}>
                <Link to='/load-data-table' className={`navigation-item-link`}></Link>
              </li>
              <li className={`navigation-item navigation-item-tracker${currentPage === 'tracker' ? `${classForCurrent} current-item` : ''}`}>
                <Link to='/task-tracker' className={`navigation-item-link`}></Link>
              </li>
              {/*
              <li className={`navigation-item navigation-item-service${currentPage === 'service' ? `${classForCurrent} current-item` : ''}`}>
                <Link to='/service' className={`navigation-item-link`}></Link>
              </li> */}

              <li className={`navigation-item navigation-item-addition${currentPage === 'addition' ? `${classForCurrent} current-item` : ''}`}>
                <Link to='/addition-user' className={`navigation-item-link`}></Link>
              </li>

              <li className={`navigation-item navigation-item-documentation${currentPage === 'documentation' ? `${classForCurrent} current-item` : ''}`}>
                <Link to='/documentation' className={`navigation-item-link`}></Link>
              </li>

              <li className={`navigation-item navigation-item-apk${currentPage === 'apk' ? `${classForCurrent} current-item` : ''}`}>
                <LoadApkFile accessToken={accessToken}/>
              </li>
            </ul>
            <button className='button button-logout2' onClick={handleLogout}></button>
        </nav>
      </div>
    </>
    )
}
export default NavigationMenu;
