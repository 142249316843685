import './taskForm.css';
import { useState, useRef, useEffect, useCallback } from 'react';
import apiConfig from '../../apiConfig.json';


const TaskForm = ({accessToken, formStatus}) => {

  const [volume, setVolume] = useState(0);
  const [volumeMetrics, setVolumeMetrics] = useState('');
  const [task, setTask] = useState('');
  const [place, setPlace] = useState('');
  const [direction, setDirection] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isStaffMemberOpen, setIsStaffMemberOpen] = useState(false);
  const [isEquipmentOpen, setIsEquipmentOpen] = useState(false);
  const [isAddingStaffMember, setIsAddingStaffMember] = useState(false);
  const [isAddingEquipment, setIsAddingEquipment] = useState(false);
  const [staffMemberList, setStaffMemberList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState('');
  const [newStaffMember, setNewStaffMember] = useState('');
  const [newEqiupment, setNewEqiupment] = useState('');
  const inputRef = useRef(null);
  const [chosenDirPlace, setChosenDirPlace] = useState('')
  const [directionsIdList, setDirectionsIdList] = useState([]);
  const [placesIdList, setPlacesIdList] = useState([]);


  const formatToLocalDateTime = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const toggleStaffMemberList = () => {
    setIsStaffMemberOpen(!isStaffMemberOpen);
  };

  const toggleEquipmentList = () => {
    setIsEquipmentOpen(!isEquipmentOpen);
  };

  const handleInputStaffMemberChange = (event) => {
    setNewStaffMember(event.target.value);
  };

  const handleInputEquipmentChange = (event) => {
    setNewEqiupment(event.target.value);
  };

  const handleAddStaffMember = () => {
    if (newStaffMember.trim()) {
      setStaffMemberList([...staffMemberList, newStaffMember]);
      setNewStaffMember('');
      setIsAddingStaffMember(false);
    }
  };

  const handleAddEquipment = () => {
    if (newEqiupment.trim()) {
      setEquipmentList([...equipmentList, { number: newEqiupment, type: selectedEquipmentType }]);
      setNewEqiupment('');
      setSelectedEquipmentType('');
      setIsAddingEquipment(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddStaffMember();
      handleAddEquipment();
    }
  };

  useEffect(() => {
    if (isAddingStaffMember && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingStaffMember]);

  useEffect(() => {
    if (isAddingEquipment && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingEquipment]);

  const removeStaffMember = (index) => {
    const updatedList = staffMemberList.filter((_, i) => i !== index);
    setStaffMemberList(updatedList);
  };

  const removeEquipment = (index) => {
    const updatedList = equipmentList.filter((_, i) => i !== index);
    setEquipmentList(updatedList);
  };

  const handleVolumeChange = (event) => setVolume(parseFloat(event.target.value) || 0);
  const handleVolumeMetricsChange = (event) => setVolumeMetrics(event.target.value);
  const handleTaskChange = (event) => setTask(event.target.value);
  const handleStartDateChange = (event) => setStartDate(event.target.value);
  const handleEndDateChange = (event) => setEndDate(event.target.value);
  const handlePlaceChange = (event) => setPlace(event.target.value);
  const handleDescriptionChange = (event) => setDescription(event.target.value);
  const handleDirectionChange = (event) => setDirection(event.target.value);

  // const getDirPlaces = useCallback(() => {
  //   fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_DIRECTIONS}`, {
  //     method: 'GET',
  //     headers: {
  //       'Authorization' : `Bearer ${accessToken}`,
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   .then(response => {
  //     return response.json();
  //   })
  //   .then(data => {
  //     const dirsId = []
  //     const placesId = []

  //     data.forEach(dir => {
  //       const id = dir.id
  //       const name = dir.name
  //       dirsId.push({id, name});

  //       dir.place.forEach(place => {
  //         const id = place.id
  //         const name = place.name
  //         placesId.push({id, name});
  //       });
  //     });
  //     setDirectionsIdList(dirsId)
  //     setPlacesIdList(placesId)
  //   })
  // }, [accessToken]);
  // useEffect(() => {
  //   getDirPlaces();
  // }, [accessToken]);


  const handleSubmit = (event) => {
    event.preventDefault();
    const formattedStartDate = formatToLocalDateTime(startDate);
    const formattedEndDate = formatToLocalDateTime(endDate);
    const header = task;
    const dirtmp = directionsIdList.filter((item) => item.name === direction)
    const placetmp = placesIdList.filter((item) => item.name === place)
    const formData = {
      volume,
      header,
      volumeMetrics,
      text: description,
      start: formattedStartDate,
      end: formattedEndDate,
      directionId: dirtmp[0].id,
      placeId: placetmp[0].id
    };

    // console.log(formData)

    // fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.POST_TASKS}`, {
    //   method: 'POST',
    //   headers: {
    //     'Authorization' : `Bearer ${accessToken}`,
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(formData)
    // })
    // .then(response => {
    //   if(response.ok) {
    //     console.log('task data delivered');
    //   }
    // })
    // .catch(error => {
    //   console.error(error);
    // })
  };

  return(
    <>
      <div className={`task-form-container ${formStatus}`}>
        <form className='add-task-form' onSubmit={handleSubmit} method='post'>
          <div className='field-group field-group-direction'>
            <label htmlFor="direction">Направление</label>
            <input
              className='task-form-input'
              id="direction"
              placeholder='Введите название направления'
              value={direction}
              onChange={handleDirectionChange}
            ></input>
          </div>
          <div className='field-group field-group-equipment'>
            <label>Техническое обеспечение</label>
            <div className='equipment-item  areas-form-input'>
              <p>Оборудование</p>
              <button type='button' className={'button' && isEquipmentOpen ? 'button-close-analytics' : 'button-open-analytics'} onClick={toggleEquipmentList}>
              </button>
            </div>
            <div className={`equipment-list ${isEquipmentOpen ? 'open' : ''}`}>
              {equipmentList.length > 0 && !isAddingEquipment ? (
                  <ul>
                    {equipmentList.map((equipment, index) => (
                      <li key={index} className='equipment-list-item'>
                        <span className={`added-equipment-icon ${equipment.type}`}></span>
                        {equipment.number}
                      <button
                      className="remove-list-item-button"
                      type="button"
                      onClick={() => removeEquipment(index)}
                    >

                    </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  !isAddingEquipment &&
                  <p>Список оборудования пуст</p>
              )}
              {isAddingEquipment ? (
                <>
                <div className="button-type-list">
                  <button className="button-add-equipment technic-type-white-excavator" onClick={() => setSelectedEquipmentType('technic-type-black-excavator')}></button>
                  <button className="button-add-equipment technic-type-white-bulldozer" onClick={() => setSelectedEquipmentType('technic-type-black-bulldozer')}></button>
                  <button className="button-add-equipment technic-type-white-crane" onClick={() => setSelectedEquipmentType('technic-type-black-crane')}></button>
                  <button className="button-add-equipment technic-type-white-grader" onClick={() => setSelectedEquipmentType('technic-type-black-grader')}></button>
                  <button className="button-add-equipment technic-type-white-rink" onClick={() => setSelectedEquipmentType('technic-type-black-rink')}></button>
                  <button className="button-add-equipment technic-type-white-truck" onClick={() => setSelectedEquipmentType('technic-type-black-truck')}></button>
                  <button className="button-add-equipment technic-type-white-auto" onClick={() => setSelectedEquipmentType('technic-type-black-auto')}></button>
                </div>
                  <input
                    ref={inputRef}
                    className='service-form-input service-form-input-list'
                    type='text'
                    value={newEqiupment}
                    onChange={handleInputEquipmentChange}
                    onKeyDown={handleKeyPress}
                    placeholder='Введите гаражный номер'
                  />
                  <button
                    className='button-add-list-item'
                    type='button'
                    onClick={handleAddEquipment}
                  >
                    Добавить
                  </button>
                </>
                ) : (
                  <button
                    className='button-add-list-item'
                    type='button'
                    onClick={() => setIsAddingEquipment(true)}
                  >
                    Добавить оборудование
                  </button>
              )}
            </div>
          </div>
          <div className='field-group field-group-staff-members'>
            <label>Исполнители</label>
            <div className='analytics-item service-form-input '>
              <p>Сотрудники</p>
              <button type='button' className={'button' && isStaffMemberOpen ? 'button-close-analytics' : 'button-open-analytics'} onClick={toggleStaffMemberList}>
              </button>
            </div>
            <div className={`staff-members-list ${isStaffMemberOpen ? 'open' : ''}`}>
              {staffMemberList.length > 0 && !isAddingStaffMember ? (
                  <ul>
                    {staffMemberList.map((member, index) => (
                      <li key={index} className='staff-member-list-item'>
                        {member}
                      <button
                      className="remove-list-item-button"
                      type="button"
                      onClick={() => removeStaffMember(index)}
                    >

                    </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  !isAddingStaffMember && (<p>Список сотрудников пуст</p>)
              )}
              {isAddingStaffMember ? (
                <>
                  <div className="button-type-list">
                    <button className="button-add-staff-member-type button-add-member-analytic">Аналитики</button>
                    <button className="button-add-staff-member-type button-add-member-personal">Работники с персоналом</button>
                    <button className="button-add-staff-member-type button-add-member-worker">Работники</button>
                  </div>
                  <input
                    ref={inputRef}
                    className='service-form-input service-form-input-list'
                    type='text'
                    value={newStaffMember}
                    onChange={handleInputStaffMemberChange}
                    onKeyDown={handleKeyPress}
                    placeholder='Введите ФИО'
                  />
                  <button
                    className='button-add-list-item'
                    type='button'
                    onClick={handleAddStaffMember}
                  >
                    Добавить
                  </button>
                </>
                ) : (
                  <button
                    className='button-add-list-item'
                    type='button'
                    onClick={() => setIsAddingStaffMember(true)}
                  >
                    Добавить сотрудника
                  </button>
              )}
            </div>
          </div>
          <div className='field-group field-group-date'>
            <label>Период выполнения</label>
            <div className='input-field-wrapper'>
              <input
              className='task-form-input task-form-input-date'
              placeholder='Дата начала'
              type='date'
              value={startDate}
              onChange={handleStartDateChange}
              ></input>
              <input
              className='task-form-input task-form-input-date'
              placeholder='Дата окончания'
              type='date'
              value={endDate}
              onChange={handleEndDateChange}
              ></input>
            </div>
          </div>
          <div className='field-group field-group-area'>
            <label>Участок</label>
            <input
              className='task-form-input'
              placeholder='Введите название участка'
              value={place}
              onChange={handlePlaceChange}
            ></input>
          </div>
          <div className='field-group field-group-description'>
            <label>Описание задачи</label>
            <textarea
              value={description}
              onChange={handleDescriptionChange}
              placeholder='Введите описание задачи'
            ></textarea>
          </div>
          <div className='field-group field-group-task'>
            <label>Задача</label>
            <input
            className='task-form-input'
            placeholder='Введите задачу'
            value={task}
            onChange={handleTaskChange}></input>
          </div>
          <div className='field-group field-group-task-сapacity'>
            <label>Объем задачи</label>
            <div className='input-field-wrapper'>
              <input
              className='task-form-input task-form-input-task-capacity'
              placeholder='Числовое значение'
              value={volume}
              onChange={handleVolumeChange}></input>
              <input
              className='task-form-input task-form-input-task-capacity'
              placeholder='Единицы измерения'
              value={volumeMetrics}
              onChange={handleVolumeMetricsChange}></input>
            </div>
          </div>
          <div className='field-group field-group-submit'>
            <div className='input-field-wrapper'>
              <button className='button-submit-task' type='submit'>+ Новая задача</button>
              <button className='button-open-tasks-list'></button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
export default TaskForm;
