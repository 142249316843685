import apiConfig from "../../../apiConfig.json"

export const deleteTask = (props) => {
  const { accessToken, taskId } = props

  fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.DELETE_TASK}${taskId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`
    }
  })
  .then(response => {
    if (response.ok) {
      return "success"
    }
  })
  .catch(error => {
    return error
  })
}