import { useState, useRef, useEffect } from "react";
import apiConfig from '../../apiConfig.json'

const AreasForm = ({accessToken}) => {
  const [placeName, setPlaceName] = useState('');
  const [isStaffMemberOpen, setIsStaffMemberOpen] = useState(false);
  const [isEquipmentOpen, setIsEquipmentOpen] = useState(false);
  const [staffMemberList, setStaffMemberList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [isAddingStaffMember, setIsAddingStaffMember] = useState(false);
  const [isAddingEquipment, setIsAddingEquipment] = useState(false);
  const [newStaffMember, setNewStaffMember] = useState('');
  const [newEqiupment, setNewEqiupment] = useState('');
  // const [selectedStaffMemberType, setSelectedStaffMemberType] = useState('');
  const [selectedEquipmentType, setSelectedEquipmentType] = useState('');
  const inputRef = useRef(null);

  const toggleStaffMemberList = () => {
    setIsStaffMemberOpen(!isStaffMemberOpen);
  };

  const toggleEquipmentList = () => {
    setIsEquipmentOpen(!isEquipmentOpen);
  }

  const handlePlaceNameChange = (event) => {
    setPlaceName(event.target.value);
  };

  const handleInputStaffMemberChange = (event) => {
    setNewStaffMember(event.target.value);
  };

  const handleInputEquipmentChange = (event) => {
    setNewEqiupment(event.target.value);
  }

  const handleAddStaffMember = () => {
    if (newStaffMember.trim()) {
      setStaffMemberList([...staffMemberList, newStaffMember]);
      setNewStaffMember('');
      setIsAddingStaffMember(false);
    }
  };

  const handleAddEquipment = () => {
    if (newEqiupment.trim()) {
      setEquipmentList([...equipmentList, { number: newEqiupment, type: selectedEquipmentType }]);
      setNewEqiupment('');
      setSelectedEquipmentType('');
      setIsAddingEquipment(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddStaffMember();
      handleAddEquipment();
    }
  };

  useEffect(() => {
    if (isAddingStaffMember && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingStaffMember]);

  useEffect(() => {
    if (isAddingEquipment && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingEquipment]);

  const removeStaffMember = (index) => {
    const updatedList = staffMemberList.filter((_, i) => i !== index);
    setStaffMemberList(updatedList);
  };

  const removeEquipment = (index) => {
    const updatedList = equipmentList.filter((_, i) => i !== index);
    setEquipmentList(updatedList);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const name = placeName;
    const directionId = 0;
    const formData = [{name, directionId}];
    // console.log(formData)
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.POST_PLACES}`, {
      method: "POST",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      if(response.ok) {
        // onFormSubmit();
        console.log('place data delivered')
      }
    })
    .catch(error => {
      console.log(error);
    });
  };

    // console.logs(selectedEquipmentType)

  return(
    <>
      <section className="areas-form-wrapper">
        <form className='direction-form' onSubmit={handleSubmit} method='post'>
          <label>Название</label>
          <input
          className='service-form-input'
          placeholder='Введите название участка'
          value={placeName}
          onChange={handlePlaceNameChange}
          />
          <div className='analytics-item  service-form-input'>
            <p>Сотрудники</p>
            <button type='button' className={'button' && isStaffMemberOpen ? 'button-close-analytics' : 'button-open-analytics'} onClick={toggleStaffMemberList}>
            </button>
          </div>
          <div className={`staff-members-list ${isStaffMemberOpen ? 'open' : ''}`}>
            {staffMemberList.length > 0 && !isAddingStaffMember ? (
                <ul>
                  {staffMemberList.map((member, index) => (
                    <li key={index} className='staff-member-list-item'>
                      {member}
                    <button
                    className="remove-list-item-button"
                    type="button"
                    onClick={() => removeStaffMember(index)}
                  >

                  </button>
                    </li>
                  ))}
                </ul>
              ) : (
                !isAddingStaffMember && (<p>Список сотрудников пуст</p>)
            )}
            {isAddingStaffMember ? (
              <>
                <div className="button-type-list">
                  <button className="button-add-staff-member-type button-add-member-analytic">Аналитики</button>
                  <button className="button-add-staff-member-type button-add-member-personal">Работники с персоналом</button>
                  <button className="button-add-staff-member-type button-add-member-worker">Работники</button>
                </div>
                <input
                  ref={inputRef}
                  className='service-form-input service-form-input-list'
                  type='text'
                  value={newStaffMember}
                  onChange={handleInputStaffMemberChange}
                  onKeyDown={handleKeyPress}
                  placeholder='Введите ФИО'
                />
                <button
                  className='button-add-list-item'
                  type='button'
                  onClick={handleAddStaffMember}
                >
                  Добавить
                </button>
              </>
              ) : (
                <button
                  className='button-add-list-item'
                  type='button'
                  onClick={() => setIsAddingStaffMember(true)}
                >
                  Добавить сотрудника
                </button>
            )}
          </div>
          <div className='equipment-item  areas-form-input'>
            <p>Оборудование</p>
            <button type='button' className={'button' && isEquipmentOpen ? 'button-close-analytics' : 'button-open-analytics'} onClick={toggleEquipmentList}>
            </button>
          </div>
          <div className={`equipment-list ${isEquipmentOpen ? 'open' : ''}`}>
            {equipmentList.length > 0 && !isAddingEquipment ? (
                <ul>
                  {equipmentList.map((equipment, index) => (
                    <li key={index} className='equipment-list-item'>
                      <span className={`added-equipment-icon ${equipment.type}`}></span>
                      {equipment.number}
                    <button
                    className="remove-list-item-button"
                    type="button"
                    onClick={() => removeEquipment(index)}
                  >

                  </button>
                    </li>
                  ))}
                </ul>
              ) : (
                !isAddingEquipment &&
                <p>Список оборудования пуст</p>
            )}
            {isAddingEquipment ? (
              <>
              <div className="button-type-list">
                <button className="button-add-equipment technic-type-white-excavator" onClick={() => setSelectedEquipmentType('technic-type-black-excavator')}></button>
                <button className="button-add-equipment technic-type-white-bulldozer" onClick={() => setSelectedEquipmentType('technic-type-black-bulldozer')}></button>
                <button className="button-add-equipment technic-type-white-crane" onClick={() => setSelectedEquipmentType('technic-type-black-crane')}></button>
                <button className="button-add-equipment technic-type-white-grader" onClick={() => setSelectedEquipmentType('technic-type-black-grader')}></button>
                <button className="button-add-equipment technic-type-white-rink" onClick={() => setSelectedEquipmentType('technic-type-black-rink')}></button>
                <button className="button-add-equipment technic-type-white-truck" onClick={() => setSelectedEquipmentType('technic-type-black-truck')}></button>
                <button className="button-add-equipment technic-type-white-auto" onClick={() => setSelectedEquipmentType('technic-type-black-auto')}></button>
              </div>
                <input
                  ref={inputRef}
                  className='service-form-input service-form-input-list'
                  type='text'
                  value={newEqiupment}
                  onChange={handleInputEquipmentChange}
                  onKeyDown={handleKeyPress}
                  placeholder='Введите гаражный номер'
                />
                <button
                  className='button-add-list-item'
                  type='button'
                  onClick={handleAddEquipment}
                >
                  Добавить
                </button>
              </>
              ) : (
                <button
                  className='button-add-list-item'
                  type='button'
                  onClick={() => setIsAddingEquipment(true)}
                >
                  Добавить оборудование
                </button>
            )}
          </div>
          <button className='button-submit-direction' type='submit'>Добавить направление</button>
        </form>
      </section>
    </>
  )
}

export default AreasForm;
