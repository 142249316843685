import { useState, useCallback, useEffect } from "react"
import NavigationMenu from "../../../Components/NavigationMenu"
import { SwitcherBetweenPages } from "@widgets/SwitcherBetweenPages/index"
import { Filters } from "@features/filters/index"
import { SearchField } from "@widgets/search-field/index"
import { ProjectCard } from "../index"
import { NewProjectForm } from "@entities/project/index"
import { getAllProjects } from "@entities/project/index"
import ModalNote from "../../../Components/ModalNote"
import { deleteProject } from "@entities/project/api/delete-project"

export const ProjectsMainPage = ({ accessToken }) => {
  // const [filters, setFilters] = useState({});
  const [isNewProjectFormOpened, setIsNewProjectFormOpened] = useState(false)
  const [ projects, setProjects ] = useState([])
  const [projectDeletionStatus, setProjectDeletionStatus] = useState({status: '', message: ''})
  const [isModalOpened, setIsModalOpened] = useState(false)
  
  const fetchProjects = useCallback(async () => {
    try {
      // console.log(accessToken)
      const allProjects = await getAllProjects(accessToken)
      setProjects(allProjects)
    }
    catch (error) {
      console.error("Ошибка при получении проектов:", error)
      setProjects([])
    }
  }, [])

  useEffect(() => {
    fetchProjects()
  }, [fetchProjects])
    
  const onToggleNewProjectForm = () => {
    setIsNewProjectFormOpened(p => !p)
  }

  const handleDeleteProject = async (id, name) => {
    const result = await deleteProject(id)
    if (result !== "success") {
      setProjectDeletionStatus({status: result.status, message: `При попытке удалить проект ${name} произошла ошибка: ${result.status}: ${result.text}`})
    } else {
      fetchProjects()
      setProjectDeletionStatus({status: "success", message: `Проект ${name} успешно удалён`})
    }
    setIsModalOpened(true)
  }

  return (
    <div className="body-wrapper">
      <div className="projects body-wrapper--flex">
      <NavigationMenu currentPage="tracker" accessToken={accessToken} />
      <main className="projects-main addition-main-index">
        <div className="page-container--dark-bg projects__page-container--dark-bg">
          
          <h1 className="projects__header">Проекты</h1>

          <div className="projects-main__upper">
            <SwitcherBetweenPages
              currentPage="Актуальные"
              firstPage="Завершённые"
              secondPage="Актуальные"
              firstLink="/tracker-projects-finished"
              secondLink="/tracker-projects-current"
            />

            <SearchField />
          <div className="projects__button-wrapper">
            <button onClick={onToggleNewProjectForm} type="button" className="projects__create-task-button">Новый проект</button>
          </div>
          </div>

          <div className="projects-main__main">
            <Filters />
            <div className="projects-cards-container">
              {Array.isArray(projects) && projects.map(project => (
                <ProjectCard
                  key={project.id}
                  projId={project.id}
                  projName={project.name}
                  projStart={project.start}
                  projEnd={project.end}
                  projDescription={project.description}
                  projPosition={project.position}
                  projDirectionName={project.direction_name}
                  projStatus={project.status}
                  handleDeleteProject={handleDeleteProject}
                 />
              ))}

              {/* <ProjectCard />
              <ProjectCard />
              <ProjectCard />
              <ProjectCard />
              <ProjectCard />
              <ProjectCard /> */}
            </div>
          </div>
          
        </div>
      </main>

      {isNewProjectFormOpened &&
        <NewProjectForm accessToken={accessToken} onClose={onToggleNewProjectForm} />
      }

      {isModalOpened &&
        <ModalNote onClose={setIsModalOpened(false)} note={projectDeletionStatus.message}/>
      }

      </div>
    </div>
  )
}