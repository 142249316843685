import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  isLoggedIn: !!localStorage.getItem('accessToken'),
  user: JSON.parse(localStorage.getItem('user')) || null,
  accessToken: localStorage.getItem('token') || null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user || null;
      state.accessToken = action.payload.accessToken;

      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token', action.payload.token);
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.token = null;

      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
  },
});

// Экспортируем действия (actions)
export const { login, logout } = authSlice.actions;

// Экспортируем редюсер по умолчанию
export default authSlice.reducer;
