/* eslint-disable react/jsx-pascal-case */
import { useState, useRef, useEffect, useCallback } from "react"
import { checkEpmtyValues } from "@features/forms/index"
// import { formatToLocalDateTime } from '../index
import { DistrictsList } from "@entities/districts/index"; //компонент с выпадающим списком направлений и участко
// import { AddStaffList } from '@entities/staff/index
import { AddStaffList_New } from "@entities/staff/index"
// import { EquipmentsList } from '../../equipments/ui/EquipmentsList
import { EquipmentList_New } from "../../equipments/ui/EquipmentList_New"
import { useTaskFields } from "../index"
import { CloseBtn } from "@widgets/buttons/index"
import { postTask } from "../index"
import { DropdownList } from "@widgets/dropdown-list"
import { getAllProjects } from "../../project"
import { formatToLocalDateTime } from "@features/formatters/index"
export const NewTaskForm = (props) => {
  const { accessToken, receivedQalifier, receivedQualifierId, receivedQualifierName, onClose } = props;

  const {
    name,
    // district,
    start,
    end,
    evaluators,
    performers,
    // facility,
    // type,
    description,
    equipments,
    volumeMetrics,
    metricCost,
    totalCost,
    workNumber,
    extraInfo,
    qualifier,
    qualifierId,
    // planInNumericalTerms,
    // planInMonetaryTerms,
    estimatedVolume,
    allValues
  } = useTaskFields()

  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [projects, setProjects] = useState([])
  const [chapters, setChapters] = useState([])
  const [sections, setSections] = useState([])
  const [projectInfo, setProjectInfo] = useState({ id: "", name: "" });
  const [chapterInfo, setChapterInfo] = useState({ id: "", name: "" });
  const [sectionInfo, setSectionInfo] = useState({ id: "", name: "" });
  // console.log(qualifier, qualifierId, qualifierName)

  const checkIfEmpty = (value, field) => {
    console.log(value, field)
    const error = checkEpmtyValues(value);
    if (error.state) {
      setErrors((p) => ({
        ...p,
        [field]: error,
      }));
    }
  };

  const fetchProjects = useCallback(async () => {
    try {
      const allProjects = await getAllProjects(accessToken);
      //сюда вписать сеттеры секций и разделов
      setProjects(allProjects);
    } catch (error) {
      console.error("Ошибка при получении проектов:", error);
      setProjects([]);
    }
  }, [accessToken]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const onChangeFieldValue = (value, field, setter) => {
    setter(value);
    checkIfEmpty(value, field);
  };

  const onBlur = (value, field) => {
    checkIfEmpty(value, field);
  };

  const checkForQualifier = () => {
    if (sectionInfo.name) {
      // checkIfEmpty()
    }
  }

  const convertToNumber = (number) => {
    if (!isNaN(Number(number))) {
      return Number(number);
    } else {
      return number;
    }
  };

  const calculateMonthDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const yearsDifference = end.getFullYear() - start.getFullYear();
    const monthsDifference = end.getMonth() - start.getMonth();

    let totalMonths = yearsDifference * 12 + monthsDifference;

    if (end.getDate() < start.getDate()) {
      totalMonths -= 1; // Уменьшаем на один месяц, если "конец" раньше "начала" в пределах месяца
    }
    if (totalMonths < 1) {
      totalMonths = 1;
    }
    // console.log(totalMonths)
    return totalMonths;
  };


  const onSubmit = () => {
    // console.log(errors)
    // const allValues = {
    //   name: name.value,
    //   start: start.value,
    //   end: end.value,
    //   evaluators: evaluators.value,
    //   performers: performers.value,
    //   description: description.value,
    //   equipments: equipments.value,
    //   volumeMetrics: volumeMetrics.value,
    //   metricCost: metricCost.value,
    //   totalCost: totalCost.value,
    //   workNumber: workNumber.value,
    //   extraInfo: extraInfo.value,
    //   estimatedVolume: estimatedVolume.value,
    // }
    // console.log(allValues)
    // Object.entries(allValues).map(([key, value]) => {
    //   console.log('checkEpmtyValues(value.value, [value]): ', checkIfEmpty(value, key))
    //   // return chekIfEmpty(value, [value])
    // }) 

    console.log(errors)
    if (errors.length > 0) {
      return;
    }

    const errorsObj = checkEpmtyValues({
      name: name.value,
        start: start.value,
        end: end.value,
        evaluators: evaluators.value,
        performers: performers.value,
        description: description.value,
        equipments: equipments.value,
        volumeMetrics: volumeMetrics.value,
        metricCost: metricCost.value,
        totalCost: totalCost.value,
        workNumber: workNumber.value,
        extraInfo: extraInfo.value,
        estimatedVolume: estimatedVolume.value,
    })

    console.log(errorsObj)


    if (receivedQalifier === "") {
      // if () {
        
      // }
      const qualifierFormatted = "PROJECT";
      // const qualifierIdFormatted =
    }
    /* дописать проверку на ошибки */
    const startDateFormatted = formatToLocalDateTime(start.value);
    const endDateFormatted = formatToLocalDateTime(end.value);
    const estimatedVolumeFormatted = convertToNumber(estimatedVolume.value);
    const metricCostFormatted = convertToNumber(metricCost.value);
    const totalCostFormatted = convertToNumber(totalCost.value);
    const monthlyVolume =
      totalCostFormatted / calculateMonthDifference(start.value, end.value);

    postTask({
      accessToken: accessToken,
      start: startDateFormatted,
      end: endDateFormatted,
      qualifier: qualifier,
      qualifierId: qualifierId,
      header: name.value,
      work_number: workNumber.value,
      description: description.value,
      estimated_volume: estimatedVolumeFormatted,
      monthly_volume: monthlyVolume,
      volume_metrics: volumeMetrics.value,
      metric_cost: metricCostFormatted,
      total_cost: totalCostFormatted,
      equipments: equipments.value, 
      performers: performers.value,
      evaluators: evaluators.value,
      additional_info: { additionalProp1: extraInfo.value },
      // facility: facility.value,
      // type: type.value,
      // planInNumericalTerms: planInNumericalTerms.value,
      // planInMonetaryTerms: planInMonetaryTerms.value,
    });
  };

  return (
    <div className="new-task-form modal-container">
      {/* <div className="modal-container__wrapper"> */}
      <form
        className="new-task-form-form modal-container__wrapper"
        method="post"
      >
        <CloseBtn
          style={{ position: "absolute", top: "2rem", right: "2rem" }}
          blackOrWhite="white"
          onClick={onClose}
        />

        {/* <div className='new-task-form-field'>
        <label className='new-task-form-field__label'>Направление или участок</label>
        <DistrictsList
          accessToken={accessToken}
          district={district.value}
          setDistrict={district.set}
        />
      </div> */}

        <div className="new-task-form-field">
          <label htmlFor="name" className="new-task-form-field__label">
            Наименование задачи
          </label>
          <input
            className="new-task-form-field__input"
            id="name"
            placeholder="Введите наименование задачи"
            value={name.value}
            onChange={(e) =>
              onChangeFieldValue(e.target.value, name.value, name.set)
            }
          />
          {/* {errors.state &&
            <span className="form__error-message">{errors[name.value]}</span>
          } */}
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Глава</label>
          <DropdownList
            style={{ zIndex: "400" }}
            heading={
              receivedQalifier === "CHAPTER" ? `Глава: ${receivedQualifierName}` : "Главы"
            }
            content={receivedQalifier !== "CHAPTER" ? chapters : ""}
            contentName="name"
            idName="id"
            selectedItem={chapterInfo}
            setSelectedItem={setChapterInfo}
            ifContentEpmtyMessage={
              receivedQalifier === "CHAPTER" ? "" : "Список глав пуст"
            }
            isDropdownAllowed={receivedQalifier === "CHAPTER" ? false : true}
            // heading="Глава"
            // content={chapters}
            // contentName="name"
            // selectedItem={chapterName}
            // setSelectedItem={setChapterName}
            // ifContentEpmtyMessage="Список глав пуст"
          />
          {/* {errors[name.value].state &&
            <span className="form__error-message">errors[name.value]</span>
          } */}
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">
            Период выполнения
          </label>
          <div className="new-task-form-row-container">
            <input
              className="new-task-form-field__input"
              type="date"
              id="date"
              placeholder="Дата начала"
              value={start.value}
              onChange={(e) =>
                onChangeFieldValue(e.target.value, start.value, start.set)
              }
            />
            <input
              className="new-task-form-field__input"
              type="date"
              id="date"
              placeholder="Дата окончания"
              value={end.value}
              onChange={(e) =>
                onChangeFieldValue(e.target.value, end.value, end.set)
              }
            />
          </div>
          {/* { (errors[start.value].state || errors[end.value].state) &&
            <span className="form__error-message">{errors[name.value]}</span>
          } */}
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Раздел</label>
          <DropdownList
            heading={
              receivedQalifier === "SECTION" ? `Раздел: ${receivedQualifierName}` : "Разделы"
            }
            content={receivedQalifier !== "SECTION" ? sections : ""}
            contentName="name"
            idName="id"
            selectedItem={sectionInfo}
            setSelectedItem={setSectionInfo}
            ifContentEpmtyMessage={
              receivedQalifier === "SECTION" ? "" : "Список разделов пуст"
            }
            isDropdownAllowed={receivedQalifier === "SECTION" ? false : true}
            style={{ zIndex: "350" }}
            // heading="Раздел"
            // content={sections}
            // contentName="name"
            // selectedItem={sectionName}
            // setSelectedItem={setSectionName}
            // ifContentEpmtyMessage="Список разделов пуст"
          />
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Исполнители</label>
          <AddStaffList_New
            style={{ zIndex: "300" }}
            arePerformers={true}
            selectedStaffArray={performers.value}
            setSelectedStaffArray={performers.set}
          />
          {/* <AddStaffList style={{zIndex: "300"}} performers={performers.value} setPerformers={performers.set} /> */}
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Объём задачи</label>
          <div className="new-task-form-row-container">
            <input
              className="new-task-form-field__input"
              id="volume"
              placeholder="Числовое значение"
              value={estimatedVolume.value}
              onChange={(e) =>
                onChangeFieldValue(
                  e.target.value,
                  estimatedVolume.value,
                  estimatedVolume.set
                )
              }
            />
            <input
              className="new-task-form-field__input"
              id="type"
              placeholder="Единицы измерения"
              value={volumeMetrics.value}
              onChange={(e) =>
                onChangeFieldValue(
                  e.target.value,
                  volumeMetrics.value,
                  volumeMetrics.set
                )
              }
            />
          </div>
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Проверяющие лица</label>
          <AddStaffList_New
            accessToken={accessToken}
            style={{ zIndex: "250" }}
            areEvaluators={true}
            selectedStaffArray={evaluators.value}
            setSelectedStaffArray={evaluators.set}
          />
          {/* <AddStaffList style={{zIndex: "250"}} performers={evaluators.value} setPerformers={evaluators.set} /> */}
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Стоимость задачи</label>
          <div className="new-task-form-row-container">
            <input
              className="new-task-form-field__input"
              id="metricCost"
              placeholder="Стоимость единицы"
              value={metricCost.value}
              onChange={(e) =>
                onChangeFieldValue(
                  e.target.value,
                  metricCost.value,
                  metricCost.set
                )
              }
            />
            <input
              className="new-task-form-field__input"
              id="totalCost"
              placeholder="Стоимость всего"
              value={totalCost.value}
              onChange={(e) =>
                onChangeFieldValue(
                  e.target.value,
                  totalCost.value,
                  totalCost.set
                )
              }
            />
          </div>
        </div>

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">Проект</label>
          <DropdownList
            style={{ zIndex: "200" }}
            heading={
              receivedQalifier === "PROJECT" ? `Проект: ${receivedQualifierName}` : "Проекты"
            }
            content={receivedQalifier !== "PROJECT" ? projects : ""}
            contentName="name"
            idName="id"
            selectedItem={projectInfo}
            setSelectedItem={setProjectInfo}
            ifContentEpmtyMessage={
              receivedQalifier === "PROJECT" ? "" : "Список проектов пуст"
            }
            isDropdownAllowed={receivedQalifier === "PROJECT" ? false : true}
          />
        </div>

        {/* <div className="new-task-form-field">
          <label className="new-task-form-field__label">
            План в числ. и денеж. выражении
          </label>
          <div className="new-task-form-row-container">
            <input
              className="new-task-form-field__input"
              id="planInNumericalTerms"
              placeholder="числовое значение"
              value={planInNumericalTerms.value}
              onChange={(e) =>
                onChangeFieldValue(
                  e.target.value,
                  planInNumericalTerms.value,
                  planInNumericalTerms.set
                )
              }
            />
            <input
              className="new-task-form-field__input"
              id="planInMonetaryTerms"
              placeholder="Денежное значение"
              value={planInMonetaryTerms.value}
              onChange={(e) =>
                onChangeFieldValue(
                  e.target.value,
                  planInMonetaryTerms.value,
                  planInMonetaryTerms.set
                )
              }
            />
          </div>
        </div> */}

        <div className="new-task-form-field">
          <label className="new-task-form-field__label">
            Техническое обеспечение
          </label>
          <EquipmentList_New
            style={{ zIndex: "150" }}
            selectedEquipArray={equipments.value}
            setSelectedEquipArray={equipments.set}
          />
          {/* <DropdownList style={{zIndex: "150"}} heading='Оборудование' content={equipmentsList} contentName="name" selectedItem={projectInfo} setSelectedItem={setProjectInfo} ifContentEpmtyMessage={qualifier === "PROJECT" ? "" : 'Список проектов пуст'} isDropdownAllowed={qualifier === "PROJECT" ? false : true} /> */}
          {/* <EquipmentsList 
          style={{zIndex: "150"}}
          equipments={equipments.value}
          setEquipment={equipments.set}
        /> */}
        </div>

        <div className="new-task-form-field new-task-form-extra-info">
          <label className="new-task-form-field__label">
            Дополнительная информация
          </label>
          <textarea
            className="new-task-form-field__textarea new-task-form-extra-info__textarea"
            value={extraInfo.value}
            onChange={(e) =>
              onChangeFieldValue(e.target.value, extraInfo.value, extraInfo.set)
            }
            placeholder="Введите доп. информацию, если потребуется"
          ></textarea>
        </div>

        <div className="new-task-form-field new-task-form-description">
          <label className="new-task-form-field__label">Описание задачи</label>
          <textarea
            className="new-task-form-field__textarea new-task-form-description__textarea"
            value={description.value}
            onChange={(e) =>
              onChangeFieldValue(
                e.target.value,
                description.value,
                description.set
              )
            }
            placeholder="Опишите задачу"
          ></textarea>
        </div>

        <div className="new-task-form-field">
          <label htmlFor="facility" className="new-task-form-field__label">
            Номер работ из реестра
          </label>
          <input
            className="new-task-form-field__input"
            id="work-number"
            placeholder="Введите номер работ из реестра"
            value={workNumber.value}
            onChange={(e) =>
              onChangeFieldValue(e.target.value, workNumber.value, workNumber.set)
            }
          />
        </div>

        <div className="new-task-form-row-container new-task-form-action-buttons">
          {/* <div className='input-field-wrapper'> */}
          <button
            className="new-task-form-field__button new-task-form-submit-button"
            type="button"
            onClick={(e) => {
              onSubmit();
              // e.preventDefault();
            }}
          >
            + Новая задача
          </button>
          <button className="new-task-form-field__button new-task-form-button new-task-form-open-tasks-button" />
          {/* </div> */}
        </div>
      </form>
      {/* </div> */}
    </div>
  );
};
