import { useState } from "react"

export const SearchField = () => {
  const [searchInput, setSearchInput] = useState("")
  return (
    <div className="search-field">
      <div className="search-field__colored-wrapper">
        <input
          type="text"
          className="search-field__input"
          onChange={(e) => setSearchInput(e.target.value)}
          value={searchInput}
        />
      </div>
    </div>
  )
}
