import "leaflet/dist/leaflet.css";
import './modalShiftTable.css';
import { createPortal } from 'react-dom';
import { useCallback, useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker} from "react-leaflet";
import L, { icon } from "leaflet";
import CalendarHeatmap from 'react-calendar-heatmap';
import { subMonths, format, parseISO, subWeeks, isWithinInterval } from 'date-fns';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ModalDelete from "../../Components/ModalDelete";
import ModalNote from "../../Components/ModalNote";
import MapPopup from "./MapPopup";
import CustomTooltip from "./CustomTooltip";
import apiConfig from '../../apiConfig.json';
import avatar from '../../Images/avatar.svg';
import loading from '../../Images/loading-icon.svg';
import UserChangeForm from "./UserChangeForm";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const UserInfoModal = (props) => {

  const { show, onClose, userName, equipmentType, equipmentWarrantyNum, date, start, end, place, accessToken } = props;
  const modalRef = useRef(null);
  const svgRef = useRef(null);
  // const modalDeleteRef = useRef(null);
  // const [currentShow, setCurrentShow] = useState(show);
  const [editingMode, setEditiongMode] = useState(false);
  const [isModalResetOpen, setIsModalResetOpen] = useState(false);
  const [equipmentList, setEquipmentList] = useState([]);
  const [showMap, setShowMap] = useState(false);
  // const [coordinates, setCoordinates] = useState([]);
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
  const [isModalDeleteOpened, setIsModalDeleteOpened] = useState(false);
  const [isDeleteUserActionEnd, setIsDeleteUserActionEnd] = useState(false);
  const [isModalNoteOpened, setIsModalNoteOpened] = useState(false);
  const [isFetchError, setIsFetchError] = useState({text: "", state: false});
  const [tooltipData, setTooltipData] = useState({});
  const [tooltipPosition, setTooltipPosition] = useState({top: 0, left: 0});
  const [showTooltip, setShowTooltip] = useState(false);
  const [userState, setUserState] = useState({
    loading:false,
    userInfo: null
  })
  const [userShiftsState, setUserShiftsState] = useState({
    loading: false,
    userShifts: null,
    totalElements: 0
  })

  const placeModalElem = document.querySelector('.body-wrapper');

  useEffect(() => {
    if (show && userName) {
      // console.log('Fetching user data for:', userName);
      if (show && userName) {
        const userUrl = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_USER_INFORMATION}${userName}`;
        // console.log('Fetching data from URL:', userUrl);

        fetch(userUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log(data)
            setUserState({
              loading: true,
              userInfo: data,
            });
          })
          .catch((error) => {
            console.error('Error fetching user data:', error);
            setUserState({
              loading: false,
              userInfo: null,
            });
          });
      }
    };
  }, [show, accessToken, userName]);

  const handleFormSubmit = useCallback(() => {
    if (show && userName) {
      // console.log('Fetching user data for:', userName);
      if (show && userName) {
        const userUrl = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_USER_INFORMATION}${userName}`;
        // console.log('Fetching data from URL:', userUrl);

        fetch(userUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setUserState({
              loading: true,
              userInfo: data,
            });
          })
          .catch((error) => {
            console.error('Error fetching user data:', error);
            setUserState({
              loading: false,
              userInfo: null,
            });
          });
      }
    };
  });

  useEffect(() => {
    if (show && userName) {
    const url = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.POST_DATA_TABLE}?page=0&size=400&sortBy=start&sortOrder=desc&operator=and`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        Object({
        "filters": {
          "name": [`${userName}`]
        }
      }))
    })
      .then(response => response.json())
      .then(data => {
        setUserShiftsState({
          loading: true,
          userShifts: data,
          currentPage: data.number,
          totalElements: data.totalElements
        });
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
        setUserShiftsState({
          loading: false,
          userShifts: null,
          currentPage: 0,
          totalPages: 0
        });
      });
  }},[accessToken, userName, show]);

  // console.log(userShiftsState)

  const toggleMap = (coordinates) => {
    setShowMap(!showMap);
  }

  const handleOpenDeleteUser = () => {
    setIsDeleteUserOpen(true);
    setIsModalDeleteOpened(true);
  }

  const handleCloseDeleteModal = () => {
    setIsModalDeleteOpened(false);
    console.log('deleted')
  }

  const handleCloseNoteModal = (setCurrentAction) => {
    setCurrentAction(false);
    setIsModalNoteOpened(false);
    console.log('closed')
  }

  const handleDeleteUser = () => {
    fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.DELETE_USER}${userName}`, {
      method: "DELETE",
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
    .then (response => {
      if(response.ok) {
        console.log('user was deleted successfully!');
        setIsDeleteUserOpen(false);
        handleCloseDeleteModal();
        setIsDeleteUserActionEnd(true);
        setIsModalNoteOpened(true);
      }
    })
    .catch (error => {
        setIsDeleteUserOpen(false);
        handleCloseDeleteModal();
        setIsDeleteUserActionEnd(true);
        setIsModalNoteOpened(true)
        setIsFetchError({text: error, state: true})
      console.log('error deleting user: ', error);
    });
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!show) { return }
      if (event.key === 'Escape'  && show) {
        onClose();
        setEditiongMode(false)
        setUserState({
            loading: false,
            userInfo: null,
        })
      }
      // console.log("escape")
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose, show]);

  const onCloseInfoModal = () => {
    onClose()
    setUserState({
      loading: false,
      userInfo: null,
  })
  }
  // }
  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (modalRef.current && !modalRef.current.contains(event.target)) {
  //       onClose();
  //       setEditiongMode(false)
  //       setUserState({
  //         loading: false,
  //         userInfo: null,
  //       })
  //       console.log(userState)
  //     }
  //   };

  //   document.addEventListener('mousedown', handleOutsideClick);

  //   return () => {
  //     document.removeEventListener('mousedown', handleOutsideClick);
  //   };
  // }, [onClose]);


  const user = userState.userInfo;

  useEffect(() => {
    if (svgRef.current) {
      const svgElement = svgRef.current.querySelector('svg');
      if (svgElement) {
        svgElement.setAttribute('viewBox', '90 0 50 100');
      }
    }
  }, []);

  const getImageForEquipmentType = (equipmentType) => {
    switch (equipmentType) {
      case 'EXCAVATOR':
        return require('../../Images/Equipment/excavator_32.png');
      case 'BULLDOZER':
        return require('../../Images/Equipment/bulldozer_32.png');
      case 'CRANE':
        return require('../../Images/Equipment/crane_32.png');
      case 'GRADER':
        return require('../../Images/Equipment/grader_32.png');
      case 'RINC':
        return require('../../Images/Equipment/rinc_32.png');
      case 'RINK':
        return require('../../Images/Equipment/rink_32.png');
      case 'TRUCK':
        return require('../../Images/Equipment/truck_32.png');
      case 'POOKER':
        return require('../../Images/Equipment/camera_10.png');
      case 'AUTO':
        return require('../../Images/Equipment/auto_32.png');
      case 'REFUELER':
        return require('../../Images/Equipment/refueler_512.png');
      case 'BUS':
        return require('../../Images/Equipment/bus_512.png');
      default:
        return require('../../Images/Equipment/excavator_32.png');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleEditingMode = () => {
    setEditiongMode(true);
  }

  const handleResetModalMode = () => {
    setIsModalResetOpen(true);
  }

  const handleCloseEditingMode = () => {
    setIsModalResetOpen(false);
    setEditiongMode(false);
  }

  const handleCloseResetMode = () => {
    setIsModalResetOpen(false);
  }

  const lastPointIcon = new L.icon({
    iconUrl: require('../../Images/marker.png'),
    iconSize: [15, 20],
    iconAnchor: [1, 20],
  });

  if (!show) return null;

  return (
    <>
    {
    isModalResetOpen && <ModalDelete
    message={'Вы уверены что хотите сбросить изменения?'}
    actionText={'Сбросить'}
    action={handleCloseEditingMode}
    onClose={handleCloseResetMode}/>
    }
    {
      createPortal(
        <section className='modal-wrapper'>
          <div className={editingMode ? 'modal-wrapper__modal modal-wrapper__modal--editing' :'modal-wrapper__modal'} ref={modalRef}>
            {
              userState.loading === false ? (
                <div className="loading-spinner">
                  <img src={loading} width={30} height={30} alt="" />
                </div>
                // <div className='modal__header'>
                //   <p>Данные о пользователе загружаются</p>
                // </div>
              ) : (
                <>
                {
                  (() => {
                    const currentDate = new Date();
                    const startDate = subWeeks(currentDate, 1);
                    const endDate = currentDate;

                    const weekHasShift = user.shiftsInfo.some((shift, index) => {
                        const hasShift = isWithinInterval(parseISO(shift.shiftDate), {start: startDate, end: endDate})
                        return hasShift
                      })

                    return(
                      <>
                        {
                          user.userInfo.imei === null ? (
                            <>
                            {
                              editingMode ? (
                                <div className="modal__header-wrapper">
                                  <span className='modal__header modal__header--dark'>Редактирование</span>
                                  <button className="modal__button--close-modal modal__button--close-modal-white" onClick={handleResetModalMode}></button>
                                </div>
                              ) : (
                                <>
                                  <div className="modal__header">
                                    <div className="modal__info-wrapper">
                                      <p className={weekHasShift ? 'modal__name modal__name--active' : 'modal__name modal__name--imei'}>
                                        {user.userInfo.fullName}
                                      </p>
                                      <div className="modal__button-wrapper">
                                        <button className="modal_button modal_button--edit-modal" onClick={handleEditingMode}></button>
                                        {/* <button className="modal_button modal_button--delete-modal"></button> */}
                                      </div>
                                      <button className="modal__button--close-modal" onClick={onCloseInfoModal}></button>
                                    </div>
                                  </div>
                                </>
                              )
                            }
                            </>
                          ) : (
                            <>
                            {
                              editingMode ? (
                                <div className="modal__header-wrapper">
                                  <span className='modal__header modal__header--dark'>Редактирование</span>
                                  <button className="modal__button--close-modal modal__button--close-modal-white" onClick={handleResetModalMode}></button>
                                </div>
                              ) : (
                                <>
                                  <div className="modal__header">
                                    <div className="modal__info-wrapper">
                                      <p className={weekHasShift ? 'modal__name modal__name--imei modal__name--active' : 'modal__name'}>
                                          {user.userInfo.fullName}
                                      </p>
                                      <div className="modal__button-wrapper">
                                        <button className="modal_button modal_button--edit-modal" onClick={handleEditingMode}></button>
                                        {/* <button className="modal_button modal_button--delete-modal"></button> */}
                                      </div>
                                      <button className="modal__button--close-modal" onClick={onCloseInfoModal}></button>
                                    </div>
                                  </div>
                                </>
                              )
                            }
                            </>
                          )
                        }
                      </>
                    )

                  })()
                }

                {
                  showMap ? (
                    <>
                    <div className='modal__information-container information-container__map'>
                      {
                        user.lastRoute.geo === null ? (
                          <>
                            <MapContainer center={[59.888877, 30.482488]} zoom={5} className='leaflet-map-opened'>
                              <TileLayer
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              zoomControl="false"/>
                              <Marker position={[59.888877, 30.482488]} icon={lastPointIcon} />
                              <button className="information-container__button-open-map" type="button" onClick={() =>toggleMap([59.5597850, 30.0849539])}></button>
                            </MapContainer>

                          </>
                        ) : (
                          <>
                            <MapContainer center={user.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim()))} zoom={5} className='leaflet-map-opened'>
                              <TileLayer
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              zoomControl="false"/>
                              <Marker position={user.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim()))} icon={lastPointIcon} />
                              <button className="information-container__button-open-map" type="button" onClick={() => toggleMap(user.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim())))}></button>
                          </MapContainer>
                        </>
                        )
                      }
                      <button className="information-container__button-map-close" onClick={toggleMap}>Закрыть</button>
                    </div>

                    </>
                  ) : (
                    <>
                    <div className='modal__information-container information-container'>
                      {/* {userState.loading === false ? (
                          <div className="loading-spinner">
                            <img src={loading} alt="" />
                          </div>
                      ) : ( */}
                        <>
                        
                      <div className='information-container__avatar'>
                        {
                          editingMode ? (
                            <>
                                <img src={avatar} alt='avatar'/>
                                <dl>
                                  <div>
                                    <dt className="personal-info__topic">Кол-во нарушений</dt>
                                    <dd className="personal-info__description personal-info__description--emphasized">-</dd>
                                  </div>
                                  <div>
                                    <dt className="personal-info__topic">Обращения в поддержку</dt>
                                    <dd className="personal-info__description personal-info__description--emphasized">-</dd>
                                  </div>
                              </dl>
                            </>
                          ) : (
                            <>
                              <img src={avatar} alt='avatar'/>
                            </>
                          )
                        }
                      </div>
                      {
                        editingMode ? (
                          <>
                            <UserChangeForm accessToken={accessToken} editingMode={handleCloseEditingMode} onFormSubmit={handleFormSubmit} userName={userName} userInfo={user.userInfo}/>
                          </>
                        ) : (
                          <>
                          <div className='information-container__personal-info personal-info'>
                            <dl>
                              <div>
                                <dt className="personal-info__topic">Должность</dt>
                                <dd className="personal-info__description">{user.userInfo.roles[0]}</dd>
                              </div>
                              <div>
                                <dt className="personal-info__topic">Пароль</dt>
                                <dd className="personal-info__description">***********</dd>
                              </div>
                              <div>
                                <dt className="personal-info__topic">Направление</dt>
                                <dd className="personal-info__description">{user.userInfo.directionName}</dd>
                              </div>
                              <div>
                                <dt className="personal-info__topic">Участок</dt>
                                <dd className="personal-info__description">{user.userInfo.placeName}</dd>
                              </div>
                              <div className="information-container__equiment">
                                    <dt className="personal-info__topic">Оборудование</dt>
                                    <dd className="personal-info__description">
                                      {
                                        Array.isArray(user.userInfo.specializations) && user.userInfo.specializations.length > 0 &&
                                        user.userInfo.specializations.map((equip, index) => {
                                          return(
                                            <img key={index} src={getImageForEquipmentType(equip)} alt='equip' width={30} height={30}/>
                                          )
                                        })
                                      }
                                    </dd>
                              </div>
                            </dl>
                          </div>
                          <div className='information-container__other-parameters'>
                              <dl>
                                <div>
                                  <dt className="personal-info__topic">Кол-во нарушений</dt>
                                  <dd className="personal-info__description personal-info__description--emphasized">-</dd>
                                </div>
                                <div>
                                  <dt className="personal-info__topic">Обращения в поддержку</dt>
                                  <dd className="personal-info__description personal-info__description--emphasized">-</dd>
                                </div>
                            </dl>
                            <div>
                              <dt className="personal-info__topic">IMEI</dt>
                              {
                                user.userInfo.imei ? (
                                  <dd className="personal-info__description">{user.userInfo.imei}</dd>
                                ) : (
                                  <dd className="personal-info__description">-</dd>
                                )
                              }
                            </div>
                          </div>
                          </>
                        )
                      }
                      <div className='information-container__maps'>
                          <p className="personal-info__topic">Последняя известная геопозиция</p>
                          <div className='information-container__map-coordinates-wrapper'>
                            <div>
                              {
                                user.lastRoute.geo === null ? (
                                  <>
                                    <MapContainer center={[59.5597850, 30.0849539]} zoom={5} className='user-leaflet-container'>
                                      <TileLayer
                                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                      zoomControl="false"/>
                                      <Marker position={[59.5597850, 30.0849539]} icon={lastPointIcon} />
                                      <button className="information-container__button-open-map" type="button" onClick={() =>toggleMap([59.5597850, 30.0849539])}></button>
                                    </MapContainer>

                                  </>
                                ) : (
                                  <>
                                    <MapContainer center={user.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim()))} zoom={5} className='user-leaflet-container'>
                                      <TileLayer
                                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                      zoomControl="false"/>
                                      <Marker position={user.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim()))} icon={lastPointIcon} />
                                      <button className="information-container__button-open-map" type="button" onClick={() => toggleMap(user.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim())))}></button>
                                  </MapContainer>
                                </>
                                )
                              }
                            </div>
                            <dl className='information-container__coordinates'>
                              <div>
                                <dt className="personal-info__topic">Широта</dt>
                                {
                                  user.lastRoute.geo === null ? (
                                    <dd className="personal-info__description">59.5597850</dd>
                                  ) : (
                                    <dd className="personal-info__description">{user.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim()))[0]}</dd>
                                  )
                                }
                              </div>
                              <div>
                                <dt className="personal-info__topic">Долгота</dt>
                                {
                                  user.lastRoute.geo === null ? (
                                    <dd className="personal-info__description">30.0849539</dd>
                                  ) : (
                                    <dd className="personal-info__description">{user.lastRoute.geo.split(',').map(coord => parseFloat(coord.trim()))[1]}</dd>
                                  )
                                }
                              </div>
                            </dl>
                          </div>
                      </div>
                      {
                        (() => {
                          const groupedData = user.shiftsInfo.reduce((acc, { shiftDate, tripNum }) => {
                            if (!acc[shiftDate]) {
                                acc[shiftDate] = 0;
                            }
                            acc[shiftDate] += tripNum;
                            return acc;
                          }, {});

                          const heatmapData = Object.keys(groupedData).map(date => ({
                              date: date, value: groupedData[date]
                          }));

                          const maxValue = Math.max.apply(null, heatmapData.map((data) => data.value));
                          const averageValue = maxValue / 5;

                          const handleMouseEnter = (event, data) => {
                            setTooltipData(data);
                            setTooltipPosition({ top: event.clientY + 50, left: event.clientX + 10});
                            setShowTooltip(true);
                          };

                          const handleMouseLeave = () => {
                            setShowTooltip(false);
                          };

                          const currentDate = new Date();
                          const startDate = subMonths(currentDate, 4);
                          const endDate = currentDate;

                          const heatmapDataFiltered = heatmapData.filter(item => {
                            const itemDate = new Date(item.date);
                            return itemDate >= startDate && itemDate <= endDate;
                          });

                          const uniqueProductiveDays = [...new Set(Object.keys(groupedData))];

                          const totalDaysInLastThreeMonths = [];
                          for(let i = 0; i < 3; i++) {
                            const month = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
                            const daysInMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate();
                            totalDaysInLastThreeMonths.push(daysInMonth);
                          }

                          const totalDaysThreeMonth = totalDaysInLastThreeMonths.reduce((acc, days) => acc + days, 0);


                          const productivePercent = (uniqueProductiveDays.length / totalDaysThreeMonth) * 100;
                          const unproductivePercent = 100 - productivePercent;

                          const DATA = {
                            labels: ['Продуктивные дни', 'Непродуктивные дни'],
                            datasets: [
                              {
                                label: 'Продуктивность',
                                data: [productivePercent.toFixed(2), unproductivePercent.toFixed(2)],
                                backgroundColor: ['#F0D07E', '#F0D07EB2'],
                                hoverBackgroundColor: ['#F0D07E', '#F0D07EB2'],
                              },
                            ],
                          };

                          const options = {
                            responsive: true,
                            cutout: '65%',
                            plugins: {
                              datalabels: {
                                formatter: (value) => `${value}%`,
                                color: '#211B27CC',
                                font: {
                                  weight: 'normal',
                                  size: 9
                                },
                              },
                              legend: {
                                display: false,
                                position: 'top',
                              },
                            },
                          };

                          const tripsPerDay = heatmapDataFiltered.reduce((acc, { date, value }) => {
                            acc[date] = (acc[date] || 0) + value;
                            return acc;
                          }, {});


                          const totalTrips = Object.values(tripsPerDay).reduce((sum, trips) => sum + trips, 0);
                          const totalDays = Object.keys(tripsPerDay).length;
                          const averageTripsPerDay = totalDays ? (totalTrips / totalDays) : 0;

                          return(
                            <>
                              <div className='information-container__productivity-months' ref={svgRef}>
                                <p className="personal-info__topic">Продуктивность за четыре месяца</p>
                                <CalendarHeatmap
                                  startDate={startDate}
                                  endDate={endDate}
                                  values={heatmapDataFiltered}
                                  showWeekdayLabels={true}
                                  classForValue={(value) => {
                                    if (!value || value.value === 0) {
                                      return 'color-empty calendar-rect';
                                    }
                                    if (value.value > averageValue * 4) {
                                      return 'color-scale-4 calendar-rect';
                                    } else if (value.value > averageValue * 3) {
                                      return 'color-scale-3 calendar-rect';
                                    } else if (value.value > averageValue * 2) {
                                      return 'color-scale-2 calendar-rect';
                                    } else {
                                      return 'color-scale-1 calendar-rect';
                                    }
                                  }}
                                  tooltipDataAttrs={(value) => ({
                                    onMouseEnter: (e) => handleMouseEnter(e, value),
                                    onMouseLeave: handleMouseLeave
                                  })}
                                />
                                {showTooltip && <CustomTooltip data={tooltipData} position={tooltipPosition} averageValue={averageValue}/>}
                              </div>
                              <div className='information-container__productivity'>
                                <div className="information-container__doughnut-wrapper" >
                                  <Doughnut data={DATA} options={options} height={150} width={150}/>
                                  <div className="information-container__doughnut-info doughnut-info">
                                    <p className="doughnut-info__num">{uniqueProductiveDays.length}</p>
                                    <p>дни продуктивности</p>
                                  </div>
                                </div>
                                <dl>
                                  <div>
                                    <dt className="personal-info__topic">Ср. кол-во рейсов в день</dt>
                                    <dd className="personal-info__description">{averageTripsPerDay.toFixed(0)}</dd>
                                  </div>
                                  <div>
                                    <dt className="personal-info__topic">Ср. процент продуктив./3 месяца</dt>
                                    <dd className="personal-info__description">{Math.round(productivePercent)}%</dd>
                                  </div>
                                </dl>
                              </div>
                            </>
                          )
                        })()
                      }
                      {
                        editingMode ? (
                          <>
                            <div className="information-container__previous-shifts--editing previous-shifts">
                              <div className="personal-info__wrapper">
                                <p className="personal-info__topic">Текущая смена:</p>
                              </div>
                              <dl className="current-shift__container">
                                <div>
                                  <dt className="personal-info__topic">Техника</dt>
                                  <dd className="personal-info__description equip-information">
                                    <img src={getImageForEquipmentType(equipmentType)} width={30} height={30} alt="equipment"/>
                                    {equipmentWarrantyNum}
                                  </dd>
                                </div>
                                <div>
                                  <dt className="personal-info__topic">Дата</dt>
                                  <dd className="personal-info__description">{date}</dd>
                                </div>
                                <div>
                                  <dt className="personal-info__topic">Время</dt>
                                  <dd className="personal-info__description">{start}-{end}</dd>
                                </div>
                                <div>
                                  <dt className="personal-info__topic">Участок</dt>
                                  <dd className="personal-info__description">{place}</dd>
                                </div>
                              </dl>
                              <p className="personal-info__topic personal-info__topic--editing">Предыдущие смены:</p>
                              <ul className="previous-shifts__list previous-shift__list--editing">
                              {
                                userShiftsState.loading === false ? (
                                  <p>Смены загружаются</p>
                                ) : (
                                userShiftsState.userShifts.content.map((userShift, index) => {
                                  return(
                                  <li key={index} className="previous-shifts__item">
                                    {
                                      userShift.trips[0]?.equipment === null ? (
                                        <>
                                          <div className="equip-information">
                                            <img src={getImageForEquipmentType('EXCAVATOR')} width={30} height={30} alt="equip" />
                                            <p>000</p>
                                          </div>
                                          <p>{formatDate(userShift.start)}</p>
                                        </>
                                      ) : (
                                        <>
                                          <div className="equip-information">
                                            <img src={getImageForEquipmentType(userShift.trips[0]?.equipment.type.type)} width={30} height={30} alt="equip" />
                                            <p>{userShift.trips[0]?.equipment.warrantyNumber}</p>
                                          </div>
                                          <p>{formatDate(userShift.start)}</p>
                                        </>
                                      )
                                    }
                                  </li>
                                  )
                                })
                              )
                              }
                              </ul>
                              {/* {
                                editingMode && <button className="personal-info__button-safe" onClick={handleEditingMode}>Coxpaнить</button>
                              } */}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="information-container__current-shift personal-info current-shift">
                              <div className="personal-info__wrapper">
                                <p className="personal-info__topic">Текущая смена:</p>
                                {/* <button className="previous-shifts__button"></button> */}
                              </div>
                              <dl className="current-shift__container">
                                <div>
                                  <dt className="personal-info__topic">Техника</dt>
                                  <dd className="personal-info__description equip-information">
                                    <img src={getImageForEquipmentType(equipmentType)} width={30} height={30} alt="equipment"/>
                                    {equipmentWarrantyNum}
                                  </dd>
                                </div>
                                <div>
                                  <dt className="personal-info__topic">Дата</dt>
                                  <dd className="personal-info__description">{date}</dd>
                                </div>
                                <div>
                                  <dt className="personal-info__topic">Время</dt>
                                  <dd className="personal-info__description">{start}-{end}</dd>
                                </div>
                                <div>
                                  <dt className="personal-info__topic">Участок</dt>
                                  <dd className="personal-info__description">{place}</dd>
                                </div>
                              </dl>
                            </div>
                            <div className="information-container__previous-shifts previous-shifts">
                              <p className="personal-info__topic">Предыдущие смены:</p>
                              <ul className="previous-shifts__list">
                              {
                                userShiftsState.loading === false ? (
                                  <p>Смены загружаются</p>
                                ) : (
                                userShiftsState.userShifts.content.map((userShift, index) => {
                                  // console.log(userShift.trips[0]?.equipment)
                                  return(
                                    <li key={index} className="previous-shifts__item">
                                    {
                                      userShift.trips[0]?.equipment === null ? (
                                        <>
                                          <div className="equip-information">
                                            <img src={getImageForEquipmentType('EXCAVATOR')} width={30} height={30} alt="equip" />
                                            <p>000</p>
                                          </div>
                                          <p>{formatDate(userShift.start)}</p>
                                        </>
                                      ) : (
                                        <>
                                          <div className="equip-information">
                                            <img src={getImageForEquipmentType(userShift.trips[0]?.equipment.type.type)} width={30} height={30} alt="equip" />
                                            <p>{userShift.trips[0]?.equipment.warrantyNumber}</p>
                                          </div>
                                          <p>{formatDate(userShift.start)}</p>
                                        </>
                                      )
                                    }
                                    {/* <button className="previous-shifts__button"></button> */}
                                  </li>
                                  )
                                })
                              )
                              }
                              </ul>
                              {/* {
                                editingMode && <button className="personal-info__button-safe" onClick={handleEditingMode}>Coxpaнить</button>
                              } */}
                            </div>
                          </>
                        )
                      }
                      </>
                      {/* )} */}
                      </div>
                    </>
                  )
                }
                </>
              )}
          </div>
          {/* {
            isDeleteUserOpen && isModalDeleteOpened ? (
               createPortal (
                  <ModalDelete
                  modalRef={modalDeleteRef}
                  message='Вы уверены, что хотите удалить пользователя? Отменить действие будет невозможно.'
                  actionText='Да, удалить'
                  action={handleDeleteUser}
                  onClose={handleCloseDeleteModal}/>,
                  placeModalElem
                )
            ) : (
                isDeleteUserActionEnd && isModalNoteOpened && (
                  !isFetchError.state ? (
                    createPortal (
                      <ModalNote
                      note="Пользователь успешно удалён!"
                      type="success"
                      onClose={() => handleCloseNoteModal(setIsDeleteUserActionEnd)}
                      />,
                      placeModalElem
                    )

                  ) : (
                    createPortal (
                      <ModalNote
                      note={`Ошибка при удалении пользователя: ${isFetchError.text}`}
                      type="error"
                      onClose={() => handleCloseNoteModal(setIsDeleteUserActionEnd)}
                      />,
                      placeModalElem
                    )
                  )
                )
          } */}
        </section>,
        placeModalElem
      )
    }
    </>
  )
}

let DefaultIcon = L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  iconSize: [10, 15],
    iconAnchor: [5, 15],
    popupAnchor: [1, -12],
});
L.Marker.prototype.options.icon = DefaultIcon;

export default UserInfoModal;


