import apiConfig from '../../../apiConfig.json'

const getDirectionsAndPlaces = (accessToken) => {
  const dirs = [];
  const places = [];

  fetch(`${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.GET_DIRECTIONS}`, {
    method: 'GET',
    headers: {
      'Authorization' : `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }
  })
 .then(response =>  {
   return response.json();
 })
 .then(data => {
  // console.log(data);/

  data.forEach((dir) => {
    if(dir.places && dir.places.length > 0) {
      dir.places.forEach((place) => {
        places.push(place);
      });
    }
    dirs.push(dir)
  });
 })
 .catch(error => {
   console.error('Error fetching data: ', error);
 });

  return { dirs: dirs, places: places }

}

 export default getDirectionsAndPlaces