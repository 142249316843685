import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from "react";
import LoadDataTable from '../../Services/LoadDataTable';
import LogInAdmin from '../../Services/LogInAdmin';
import Tracker from '../../Pages/TaskTracker/Tracker';
import ApkDownload from '../../Components/ApkDownload';
import DirectionPage from '../../Pages/Service/DirectionPage';
import AreasPage from '../../Pages/Service/AreasPage';
import GanttChart from '../../Pages/TaskTracker/GantChart';
import AdditionEquipPage from '../../Pages/UserEquipAddition/AdditionEquipPage';
import AdditionUserPage from '../../Pages/UserEquipAddition/AdditionUserPage';
import MainQuestionsPage from '../../Pages/FAQ/MainQuestionsPage';
import { ProjectsMainPage } from '../../Pages/projects';
import DocumentationPage from '../../Pages/Documentation/ui/DocumentationPage';
import DocumentationSamplePage from '../../Pages/DocumentationSample/ui/DocumentationSamplePage';
import StartMenu from '../../Pages/StartMenu/ui/StartMenu'
// import './App.css';


export const App = () => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || '');
  const handleLogin = (token) => {
    setAccessToken(token);
    localStorage.setItem('accessToken', token);
  }

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setAccessToken(token);
    }
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={accessToken ? <StartMenu accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>} />
          <Route path="/load-data-table" element={accessToken ? <LoadDataTable accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>} />
          <Route path="/service" element={accessToken ? <DirectionPage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>} />
          <Route path="/service-areas" element={accessToken ? <AreasPage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>} />
          <Route path="/gantt-chart" element={accessToken ? <GanttChart accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/task-tracker" element={accessToken ? <Tracker accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/addition-equip" element={accessToken ? <AdditionEquipPage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/addition-user" element={accessToken ? <AdditionUserPage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/tracker-projects" element={accessToken ? <ProjectsMainPage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/tracker-projects-current" element={accessToken ? <ProjectsMainPage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/tracker-projects-finished" element={accessToken ? <ProjectsMainPage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/documentation" element={accessToken ? <DocumentationPage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/documentation-samples" element={accessToken ? <DocumentationSamplePage accessToken={accessToken}/> : <LogInAdmin onLogin={handleLogin}/>}/>
          <Route path="/apk-download" element={<ApkDownload autoDownload/>}/>
          <Route path="/faq" element={<MainQuestionsPage />}/>
          {/* <Route exact path="/" element={<LoadDataTable accessToken={accessToken} />} />
          <Route path="/load-data-table" element={<LoadDataTable accessToken={accessToken}/>} />
          <Route path="/service" element={<DirectionPage accessToken={accessToken} />} />
          <Route path="/service-areas" element={<AreasPage accessToken={accessToken} />} />
          <Route path="/gantt-chart" element={<GanttChart accessToken={accessToken} />}/>
          <Route path="/task-tracker" element={<Tracker accessToken={accessToken} />}/>
          <Route path="/addition-equip" element={<AdditionEquipPage accessToken={accessToken} />}/>
          <Route path="/addition-user" element={<AdditionUserPage accessToken={accessToken} />}/>
          <Route path="/apk-download" element={<ApkDownload autoDownload />}/>
          <Route path="/faq" element={<MainQuestionsPage accessToken={accessToken} />}/>
          <Route path="/tracker-projects" element={<ProjectsMainPage accessToken={accessToken} />}/>
          <Route path="/tracker-projects-current" element={<ProjectsMainPage accessToken={accessToken} />}/>
          <Route path="/tracker-projects-finished" element={<ProjectsMainPage accessToken={accessToken} />}/>
          <Route path="/documentation" element={ <DocumentationPage accessToken={accessToken} />}/>

          <Route path="/documentation-samples" element={<DocumentationSamplePage accessToken={accessToken} />}/> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
