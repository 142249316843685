import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import '../App.css'
import Main from "../Pages/ShiftsTable/Main";
import Loading from "./Loading";
import Pagination from "../Pages/ShiftsTable/Pagination";
import { useCallback } from "react";
import OverlayShiftMobile from "../Pages/ShiftsTable/Mobile/OverlayShiftMobile";
import NavigationMenu from "../Components/NavigationMenu";
import OverlayVinMobile from "../Pages/ShiftsTable/Mobile/OverlayVinMobile";
import OverlayWarningMobile from "../Pages/ShiftsTable/Mobile/OverlayWarningMobile";
import GalleryPopUp from "../Pages/ShiftsTable/GalleryPopUp";
import Filters from "../Pages/ShiftsTable/Filters";
import MapPopup from "../Pages/ShiftsTable/MapPopup";
import GraphPopup from "../Pages/ShiftsTable/GraphPopup";
import GotoFAQButton from "../Components/GotoFAQButton";
import apiConfig from "../apiConfig.json";
// import TableHead from '../Pages/UserEquipAddition/TableHead';


const LoadDataTable = ({accessToken}) => {
  const MainData = Loading(Main);
  const PaginationComponent = Pagination;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [activeColumn, setActiveColumn] = useState(0);
  const totalColumns = 9;
  const [showShiftInfo, setShowShiftInfo] = useState(false);
  const [showVinInfo, setShowVinInfo] = useState(false);
  const [showWarningInfo, setShowWarningInfo] = useState(false);
  const [showGalleryPopUp, setShowGalleryPopUp] = useState(false);
  const [showMapPopup, setShowMapPopup] = useState(false);
  const [showGraphPopup, setShowGraphPopup] = useState(false);
  const [vin, setVin] = useState('');
  const [model, setModel] = useState('');
  const [warrantyNumber, setWarrantyNumber] = useState('');
  const [technicType, setTechnicType] = useState('');
  const [equipmentType, setEquipmentType] = useState('');
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [place, setPlace] = useState('');
  const [trips, setTrips] = useState({});
  const [imagesArray, setImagesArray] = useState([]);
  const [image, setImage] = useState('');
  const [route, setRoute] = useState({});
  const [mainRoute, setMainRoute] = useState({});
  const [voltage, setVoltage] = useState({});
  const [sortedRoute, setSortedRoute] = useState({});
  const [sortedVoltage, setSortedVoltage] = useState({});
  const [filters, setFilters] = useState({});
  const [pageSize, setPageSize] = useState(10)
  const [tableState, setTableState] = useState({
    loading: false,
    shifts: null,
    currentPage: 0,
    totalPages: 0
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handlePreviousColumn = () => {
    if(activeColumn > 0) {
      setActiveColumn(activeColumn - 1)
    }
  };

  const handleNextColumn = () => {
    if(activeColumn < totalColumns - 1) {
      setActiveColumn(activeColumn + 1)
    }
  };

  const handleFilters = (requestBody) => {
    setFilters(requestBody);
    // console.log(filters)
  }

  const fetchData = useCallback(() => {
    if (typeof tableState.currentPage !== 'undefined') {
      setTableState(prevState => ({
        ...prevState,
        loading: true
      }));

    const url = `${apiConfig.BASE_URL}${apiConfig.ENDPOINTS.POST_DATA_TABLE}?page=`+ String(tableState.currentPage) +'&size=' + String(pageSize) + '&sortBy=start&sortOrder=desc&operator=and';
    // console.log('фильтры уже перед самой отправкой: ', filters, 'фильтры уже перед самой отправкой но в жсоне: ', JSON.stringify(filters))
    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization' : `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(filters)
    })
      .then(response => response.json())
      .then(data => {
        setTableState({
          loading: false,
          shifts: data,
          currentPage: data.number,
          totalPages: data.totalPages
        });
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
        setTableState({
          loading: false,
          shifts: null,
          currentPage: 0,
          totalPages: 0
        });
      });
  }},[accessToken, tableState.currentPage, filters, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (page, url) => {
    setTableState(prevState => ({
      ...prevState,
      currentPage: page
    }));
  };

  const shiftsData = tableState.shifts ? tableState.shifts.content : null;

  const toggleShiftInfoOverlay = (name, vin, date, start, end, place, trips, imagesArray, equipmentType, mainRoute, sortedRoute, sortedVoltage) => {
    setShowShiftInfo(true);
    setName(name);
    setVin(vin);
    setDate(date);
    setStart(start);
    setEnd(end);
    setPlace(place);
    setTrips(trips);
    setImagesArray(imagesArray);
    setEquipmentType(equipmentType);
    setMainRoute(mainRoute);
    setSortedRoute(sortedRoute);
    setSortedVoltage(sortedVoltage);
  }

  const toggleVinInfoOverlay = (vin, model, warrantyNumber, technicType, imagesArray) => {
    setShowVinInfo(true);
    setVin(vin);
    setModel(model);
    setWarrantyNumber(warrantyNumber);
    setTechnicType(technicType);
    setImagesArray(imagesArray)
  }

  const toggleGalleryPopUp = (imagesArray, image) => {
    setShowGalleryPopUp(!showGalleryPopUp);
    if (!showGalleryPopUp) {
      document.body.classList.add('overlay-open');
    } else {
      document.body.classList.remove('overlay-open');
    }
    setImagesArray(imagesArray);
    setImage(image);
  }

  const openMapPopup = (mainRoute, route) => {
    setMainRoute(mainRoute);
    setRoute(route);
    setShowMapPopup(true);
  }

  const closeMapPopup = () => {
    setShowMapPopup(false);
  }

  const toggleGraphPopup = (voltage) => {
    setShowGraphPopup(!showGraphPopup);
    setVoltage(voltage)
  }

  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
  };

  return (
    <>
    <div className="body-wrapper">
      <div className={isMobile ? 'page-flex-wrapper page-flex-table-wrapper-mobile' : "page-flex-wrapper data-table-flex-wrapper"}>
        <GraphPopup show={showGraphPopup} onClose={toggleGraphPopup} voltage={voltage}/>
        <MapPopup show={showMapPopup} onClose={closeMapPopup} mainRoute={mainRoute} route={route}/>
        <GalleryPopUp show={showGalleryPopUp} onClose={toggleGalleryPopUp} imagesArray={imagesArray} image={image}/>
        <OverlayWarningMobile show={showWarningInfo} onClose={() => setShowWarningInfo(false)}/>
        <OverlayVinMobile
          show={showVinInfo}
          onClose={() => setShowVinInfo(false)}
          vin={vin}
          model={model}
          warrantyNumber={warrantyNumber}
          technicType={technicType}
          imagesArray={imagesArray} />
        <OverlayShiftMobile
          show={showShiftInfo}
          onClose={() => setShowShiftInfo(false)}
          name={name}
          vin={vin}
          date={date}
          start={start}
          end={end}
          place={place}
          trips={trips}
          imagesArray={imagesArray}
          equipmentType={equipmentType}
          mainRoute={mainRoute}
          sortedRoute={sortedRoute}
          sortedVoltage={sortedVoltage}
          toggleMapPopup={openMapPopup}
          toggleGraphPopup={toggleGraphPopup} />
          <NavigationMenu currentPage={'table'} accessToken={accessToken}/>
          {/* <Header/> */}
          <div className={isMobile ? "filter-table-wrapper-mobile" : "filter-table-wrapper-desktop"}>
            <div className={'table-head-filter-wrapper'}>
              <div className={isMobile ? 'table-head--dark table-head-mobile' : "table-head--dark"}>
                <ul className="table-head-list">
                  <li className={isMobile ? ' table-head-item table-head-item-mobile' : "table-head-item"}>
                  </li>
                  <li className={isMobile ? ' table-head-item table-head-item-mobile' : "table-head-item"}>
                    <p>
                      Исполнитель
                    </p>
                    <span></span>
                  </li>
                  <li className={isMobile ? activeColumn === 0 ? 'table-head-item table-head-item-mobile' : 'inactive' : "table-head-item"}>
                    <p>
                      VIN
                    </p>
                    <span></span>
                  </li>
                  <li className={isMobile ? activeColumn === 1 ? ' table-head-item table-head-item-mobile' : 'inactive' : "table-head-item"}>
                    <p>
                      Дата
                    </p>
                    <span></span>
                  </li>
                  <li className={isMobile ? activeColumn === 2 ? 'table-head-item table-head-item-mobile' : 'inactive' : "table-head-item"}>
                    <p>
                      Начало
                    </p>
                  </li>
                  <li className={isMobile ? activeColumn === 3 ? 'table-head-item table-head-item-mobile' : 'inactive' : "table-head-item"}>
                    <p>
                      Конец
                    </p>
                  </li>
                  <li className={isMobile ? activeColumn === 4 ? 'table-head-item table-head-item-mobile' : 'inactive' : "table-head-item"}>
                    <p>
                      Направ.
                    </p>
                    <span></span>
                  </li>
                  <li className={isMobile ? activeColumn === 5 ? 'table-head-item table-head-item-mobile' : 'inactive' : "table-head-item"}>
                    <p>
                      Участок
                    </p>
                    <span></span>
                  </li>
                  <li className={isMobile ? 'inactive' : "table-head-item"}>
                    <p>
                      Рейсы
                    </p>
                  </li>
                  <li className={isMobile ? 'inactive' : "table-head-item"}>
                    <p>
                      Тип работы
                    </p>
                    <span></span>
                  </li>
                  <li className={isMobile ? activeColumn === 6 ? 'table-head-item table-head-item-mobile' : 'inactive' : "table-head-item"}>
                    <p>
                      Фото
                    </p>
                  </li>
                  <li className={isMobile ? activeColumn === 7 ? 'table-head-item table-head-item-mobile' : 'inactive' : "table-head-item"}>
                    <p>
                      Маршрут
                    </p>
                  </li>
                  <li className={isMobile ? activeColumn === 8 ? 'table-head-item table-head-item-mobile' : 'inactive' : "table-head-item"}>
                    <p>
                      Напряж.
                    </p>
                  </li>
                  {/* <li className={isMobile ? activeColumn === 9 ? 'table-head-item table-head-item-mobile' : 'inactive' : "table-head-item"}>
                  </li> */}
                </ul>
                {!isMobile &&
                  <GotoFAQButton category='table' top='.9rem' right='1rem' />
                }
                <div className={isMobile ? 'addition-wrapper__button-wrapper' : 'inactive'}>
                  <button className="addition-wrapper__button-prev-white" onClick={handlePreviousColumn}></button>
                  <button className="addition-wrapper__button-next-white" onClick={handleNextColumn}></button>
                </div>
              </div>
            </div>
            <Filters onFilters={handleFilters}/>
            {
              (!shiftsData || shiftsData.length === 0) ? (
                <div className="loading-data-shift-table">
                <p>
                  Данные не были найдены
                </p>
              </div>
              ) : (
                <MainData
                isLoading={tableState.loading}
                shifts={shiftsData}
                pagination={
                <PaginationComponent
                  totalPages={tableState.totalPages}
                  currentPage={tableState.currentPage}
                  onPageChange={handlePageChange}
                  />}
                toggleShiftInfoOverlay={toggleShiftInfoOverlay}
                toggleGalleryPopUp={toggleGalleryPopUp}
                toggleVinInfoOverlay={toggleVinInfoOverlay}
                accessToken={accessToken}
                activeColumn={activeColumn}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
                totalElements={tableState.shifts.totalElements}
              />
              )
            }
          </div>
          {isMobile &&
            <GotoFAQButton
              category='table'
              bottom='4.9rem' left='1.5rem'
              width='32px'
              height="32px"
              fontSize="15px"
            />
          }
      </div>
    </div>
    </>
  );
};
export default LoadDataTable;