import { useState } from 'react'

export const useProjectFields = () => {
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [description, setDescription] = useState('')
  const [district, setDistrict] = useState({name: '', type: ''})
  const [position, setPosition] = useState({})
  
  return {
    id: {value: id, set: setId},
    name: { value: name, set: setName },
    start: { value: start, set: setStart },
    end: { value: end, set: setEnd },
    description: { value: description, set: setDescription },
    position: { value: position, set: setPosition },
    district: { value: district, set: setDistrict }
  }
}