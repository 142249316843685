import { useState, useEffect, useCallback } from "react";
import { GotoArrowBtn, CloseBtn } from "@widgets/buttons";
import { getAllEquipments } from "@entities/equipments/api/get-all-equipments";

export const EquipmentList_New = (props) => {
  const {
    accessToken,
    style,
    selectedEquipArray,
    setSelectedEquipArray
  } = props;

  const [isListOpened, setIsListOpened] = useState(false);
  // const [selectedItemId, setSelectedItemId] = useState();
  const [equipmentsList, setEquipmentsList] = useState([])
  // const [selectedEquipArray, setSelectedEquipArray] = useState([])
  const [filter, setFilter] = useState('')

  const onToggleList = () => {
    setIsListOpened((p) => !p);
  };

  const onSelect = (equip) => {
    setSelectedEquipArray(prevArray => {
      const array = prevArray || [];
      if (!array.includes(equip)) {
        return [...array, +equip]
      } else {
        return array
      }
    })
    // setSelectedItemId(index);
    // onToggleList();
  };

  const fetchEquipment = useCallback(async () => {
    try {
      const allEquips = await getAllEquipments(accessToken);
      let equipsArray = [];
      // console.log(allEquips)
      allEquips.forEach((equip) => {
        if (equip.warrantyNumber && equip.type.type) {
          const equipObj = {
            id: equip.id,
            warrantyNumber: equip.warrantyNumber,
            type: equip.type.type,

          };
          equipsArray.push(equipObj);
        }
      });
      setEquipmentsList(equipsArray);
    } catch (error) {
      console.error("Ошибка при получении оборудования:", error);
      setEquipmentsList([]);
    }
  }, [accessToken]);

  useEffect(() => {
    fetchEquipment()
  }, [fetchEquipment])

  const filteredEquip = equipmentsList.filter(equip => {
    return equip.warrantyNumber.toString().includes(filter.toLowerCase())

  })

  const onDeleteEquipment = (equip) => {
    // console.log('selectedEquipArray', selectedEquipArray, 'id: ', equip)
    setSelectedEquipArray(prevArray => {
      // console.log('prevArray: ', prevArray)
      // const array = prevArray || [];
      const array = prevArray.filter(item => item !== equip)
      // console.log('new array: ', array)
      return array
    })
  }

  // console.log('selectedEquipArray ', selectedEquipArray)

  return (
    <>
      <div className="dropdown-list" style={style}>
        <div className="dropdown-list__field">
          <p className="districts__chosen-district">
            {isListOpened ? (
              <>
                <input type="text" className="equipments-list__search-input" placeholder="Введите гаражный номер" onChange={(e) => setFilter(e.target.value)} />
              </>
            )
              
            : "Оборудование"}
            {/* {selectedItem ? <>{selectedItem}</> : <>{heading}</>} */}
          </p>

          {!isListOpened ? (
            <GotoArrowBtn onClick={onToggleList} rotation="rb" />
          ) : (
            <CloseBtn blackOrWhite="white" bg="dark" onClick={onToggleList} style={{marginLeft: '0.6rem'}} />
          )}
        </div>

        <div
          className={`dropdown-list__list ${
            isListOpened && "dropdown-list__list_opened"
          } `}
        >
          {equipmentsList.length > 0 ? (
            <ul className="dropdown-list__list-list">
              {filteredEquip.map((equip) => {
                return (
                  <>
                    <div className="equipment-list__item-container">
                      <li key={equip.id}
                        className={`dropdown-list__item equipments-list__item ${
                          selectedEquipArray.includes(equip.id) ? "chosen" : ""
                        } `}
                        onClick={() => onSelect(equip.id)}
                      >

                        <div className="equipment-list__item-inner-container">
                          <div className={`dropdown-list__equip-icon equip-${selectedEquipArray.includes(equip.id) ? 'white' : 'black'}-${equip.type.toLowerCase()}`} />
                          <span className="dropdown-list__value">
                            {equip.warrantyNumber}
                          </span>
                        </div>
                    

                      </li>
                        {selectedEquipArray.includes(equip.id) &&
                          <CloseBtn style={{zIndex: '1000', position: 'absolute', top: '13%', right: '2%', width: '2.7rem', height: '2.7rem'}} blackOrWhite="white" onClick={() => onDeleteEquipment(equip.id)} />
                        }
                    </div>
                  </>
                );
              })}
            </ul>
          ) : (
            <div className="dropdown-list__empty-message">
              Не удалось вывести список доступного оборудования.
            </div>
          )}
        </div>
      </div>
    </>
  );
};
