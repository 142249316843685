import { Link } from "react-router-dom";

export const HeaderDocumentation = (props) => {
  const { projectName = "—", projectId="" } = props;
  let queryString = ''
  console.log('да не легко ', projectId)
  if(projectId !== null && projectId.length !== 0) {
    queryString = new URLSearchParams({ projId: projectId, projName: projectName }).toString()
    console.log(queryString)
    // linkWithQuery = `${gotoLink}?${queryString}`
    // console.log('ну это то ты сделаешь? ', linkWithQuery)
  }
  return (
    <div className="header-documentation">
      <div className="header-documentation__upper">
        <h1 className="header-documentation__header">Документация</h1>
        <div className="header-documentation__project">{projectName}</div>
      </div>

      <div className="header-documentation__bottom">
        <button className="header-documentation__button">
          <Link
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              color: "white",
              textDecoration: "none",
              padding: '1rem'
            }}
            to={queryString === '' ? "/documentation" : `/documentation?${queryString}`}
            // state={{state: state}}
          >
            ГПР
          </Link>
        </button>
        <button className="header-documentation__button">
        <Link
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              color: "white",
              textDecoration: "none",
              padding: '1rem'
            }}
            to={queryString === '' ? "/documentation-samples" : `/documentation-samples?${queryString}`}
            // state={{state: state}}
          >
            КС-6
          </Link>
        </button>
      </div>
    </div>
  );
};
