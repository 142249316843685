import apiConfig from "../../../apiConfig.json";

export const postNewProject = async (props) => {
  const { accessToken, name, start, end, description, direction } = props;
  // console.log(props)
  const formData = {
    name: name,
    start: start,
    end: end,
    description: description,
    direction_name: direction.name,
  };

  // const formData = {
  //   name: "string",
  //   start: "2025-01-14T13:38:01.983Z",
  //   end: "2025-01-14T13:38:01.983Z",
  //   description: "string",
  //   position: [
  //     {
  //       "x": 0,
  //       "y": 0
  //     }
  //   ],
  //   "direction_name": "string"
  // }
console.log('данные перед отправкой: ', formData, 'данные перед отправкой в формате json: ', JSON.stringify(formData))
  const response = await fetch(`${apiConfig.BASE_URL}${apiConfig.CRM_ENDPOINTS.POST_PROJECT}`, {
    method: "POST",
    headers: {
      'Authorization': `Bearer eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6WyJBRE1JTiIsIlNFUlZJQ0UiXSwic3ViIjoiQWRtaW5BQSIsImlhdCI6MTczNzAyMTE4MSwiZXhwIjoxOTM3MjgwMzgxfQ._6REvIkm-E6jJtjjKune81dvTX1XWE7Nh0duN5EtwCE`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData),
  });
  console.log(response);
  if (response.ok) {
    return "success";
  } else {
    return response;
  }
};
