import { useState } from "react";
import { createPortal } from 'react-dom'
import { DeleteBtn, GotoArrowBtn, EditBtn } from '@widgets/buttons/index'
import { statusesColors } from '@entities/project/index'
import { getImageForTaskType } from "@entities/task/model/get-image-for-task-type"
import { EditProjectForm } from "@entities/project/index"
// import { useProjectFields } from "@entities/project/index"
import { statuses } from "../model/statuses"
import ModalDelete from "../../../Components/ModalDelete"

import { useDispatch } from "react-redux";
// import { setProject } from "../../../store/slices/projectSlice";
import { useNavigate } from "react-router-dom";

export const ProjectCard = (props) => {
  const { 
    projId,
    projName,
    projStart,
    projEnd,
    projDescription,
    projPosition = [{x:0, y:0}],
    projDirectionName,
    projStatus,
    handleDeleteProject } = props
  
  const rootElement = document.querySelector('.body-wrapper')
  // const { id, name, start, end, description, district, position } = useProjectFields()
  const [isEditProjectFormOpened, setIsEditProjectFormOpened] = useState(false)
  const [isModalDeleteOpened, setIsModalDeleteOpened] = useState(false)
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const onClick = (gotoLink, projectData) => {
  //   dispatch(setProject(projectData)); // Сохраняем данные проекта в Redux
  //   navigate(gotoLink); // Переходим на другую страницу
  // };

  const onToggleEditProjectForm = () => {
    setIsEditProjectFormOpened(p => !p)
  }

  function convertToDate(date) {
    if (!date) return ''
    //пример даты до обработки: 2025-01-09 09:51:03
    const onlyDate = (date.split(" ", 1))[0].split("-") //исключение времени из date и разделение даты на [год, месяц, день]
    const year = onlyDate[0]
    const month = onlyDate[1]
    const day  =onlyDate[2]
    return `${year}-${month}-${day}`
  }

  const onDeleteProject = () => {
    handleDeleteProject(projId, projName)
  }


  return (
    <div className="project-card">
      <section className="project-card__upper-section">
        <span className="project-card__dates">{convertToDate(projStart)} - {convertToDate(projEnd)}</span>
        <div className="project-card__actions">
          {/* <button className="change-status-buton project-card__button" /> */}
          <EditBtn onClick={onToggleEditProjectForm} style={{marginRight: '0.9rem'}}/>
          <DeleteBtn style={{width: "27px", height: "27px"}} onClick={() => setIsModalDeleteOpened(true)} />
        </div>
      </section>

      <section className="project-card__middle-section">
        <p className="project-card__route">{projName}</p>
        <div style={{backgroundColor: statusesColors(projStatus)}}  className="project-card__status">
          <img className="project-card__status-icon" src={getImageForTaskType(projStatus)} alt={projStatus} width={20} height={20}/>
          <p className="">{statuses[projStatus]}</p>
        </div>
        <p className="project-card__description">{projDescription}</p>
      </section>

      <section className="project-card__bottom-section">
        <span className="project-card__tasks-statistic">Выполнено задач: —</span>
        <GotoArrowBtn style={{position: 'absolute', right: '-0.5rem', bottom: '-0.5rem' }} rotation="rb" gotoLink="/task-tracker" state={projId} params={{ projId: projId, projName: projName }} />
      </section>

      {isEditProjectFormOpened &&
        <EditProjectForm
          onClose={onToggleEditProjectForm}
        />
      }

      {isModalDeleteOpened &&
      <> {
        createPortal (
          <ModalDelete onClose={() => setIsModalDeleteOpened(false)} action={onDeleteProject} message={`Вы уверены, что хотите удалить проект ${projName} ?`} actionText="Удалить" />, rootElement
        )
      }
      </>
      }
    </div>
  )
}