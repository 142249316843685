import { useState, useCallback, useEffect } from 'react'
import NavigationMenu from '../../Components/NavigationMenu'
import TrackerHeader from './MainPage/Header.jsx'
import { TasksContainer } from './MainPage/TasksContainer.jsx'
import PeriodSwitcher from './MainPage/PeriodSwitcher.jsx'
import ModalDelete from '../../Components/ModalDelete'
import { statuses } from '@entities/task/index.js'
// import { useLocation } from "react-router-dom"
import { getProject } from '@entities/project/index.js'
import { useSearchParams } from 'react-router-dom'

// import { useSelector } from "react-redux"

// import { useTaskFields } from '../../entities/task/index.js'

const Tracker = ({accessToken}) => {
  // const { qualifier, qualifierId, start, end, status } = useTaskFields()
  const [searchParams] = useSearchParams()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [projectId, setProjectId] = useState('')
  const [projectName, setProjectName] = useState('')
  const [projectInfo, setProjectInfo] = useState({})
  const [qualifierId, setQualifierId] = useState('')
  const [qualifier, setQualifier] = useState('')
  const [qualifierName, setQualifierName] = useState('')
  // const project = useSelector((state) => state.project.currentProject);

  const fetchProject = useCallback(async (id) => {
    try {
      const projectInfo = await getProject(id)
      // console.log(projectInfo)
      setProjectInfo(projectInfo)
      setQualifier("PROJECT")
      setQualifierId(projectInfo.project.id)
      setQualifierName(projectInfo.project.name)
    }
    catch (error) {
      console.error("Ошибка при получении проектов:", error)
      setProjectInfo([])
    }
  }, [])
  
  useEffect(() => {
    // const { id } = location
    const id = searchParams.get("projId")
    const name = searchParams.get("projName")
    if (!id && id !== null && id !== undefined) {
      setProjectId(id)
      fetchProject(id)
    }

    if (!name && name !== null && name !== undefined) {
      setProjectName(name)
    }
    // console.log(id)
    // if (id) {
      // const projectId = localStorage.getItem("projectId") || ''
    // }
    // fetchProject()
  }, [fetchProject, searchParams])
  
  // console.log(qualifier, qualifierId, qualifierName)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const sortTasksByStatuses = (status) => {
    let tasks = []
    if (Object.values(projectInfo).length > 0) {

      projectInfo.tasks.forEach(task => {
        if (task.status === status) {
          tasks.push(task)
        }
      })
    }
    return tasks
  }
  
  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  const handleChangeDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }

 return(
  <>
    <div className='body-wrapper tracker-body-wrapper'>
      <div className={isMobile ? 'page-flex-wrapper page-flex-wrapper-mobile' : 'page-flex-wrapper'}>
        <NavigationMenu currentPage='tracker' accessToken={accessToken}/>
        <main className='tracker-main__wrapper'>
          <TrackerHeader
            projectName={projectName}
            projectId={projectId}
            startDate={startDate}
            endDate={endDate}
          />
          <PeriodSwitcher
            accessToken={accessToken}
            // projectInfo={projectInfo}
            qualifier = {qualifier}
            qualifierId = {qualifierId}
            qualifierName={qualifierName}
            onChangeDates={handleChangeDate}
          />
          <div className="tracker-main-tasks-cards__container">

          {Object.values(statuses).map((item, index) => (
            <TasksContainer
              key={index}
              projectInfo={projectInfo}
              typeOfTasks={item}
              qualifier = {qualifier}
              qualifierId = {qualifierId}
              qualifierName={qualifierName}
              // onOpen={handleOpenModal}
              // startDate={startDate}
              // endDate={startDate}
              accessToken={accessToken}
              tasks={sortTasksByStatuses(item)}
            />
          ))}
          </div>
        </main>
        {
          isModalOpen &&
          <ModalDelete
            message='Удалить направление?'
            action='Удалить'
            onClose={handleCloseModal}
          />
        }
      </div>
      </div>
  </>
 )
}
export default Tracker;
