// export const getImageForTaskType = (status) => {
//   switch (type) {
//     case 'planned':
//       return new URL('../../../shared/assets/tasks-types/planned-icon.svg', import.meta.url).href;
//     case 'inProgress':
//       return new URL('../../../shared/assets/tasks-types/in-progress-icon.svg', import.meta.url).href;
//     case 'underReview':
//       return new URL('../../../shared/assets/tasks-types/under-review-icon.svg', import.meta.url).href;
//     case 'postImprovment':
//       return new URL('../../../shared/assets/tasks-types/post-improvment-icon.svg', import.meta.url).href;
//     case 'done':
//       return new URL('../../../shared/assets/tasks-types/done-icon.svg', import.meta.url).href;
//     default:
//       return new URL('../../../shared/assets/tasks-types/planned-icon.svg', import.meta.url).href;
//   } 
// }

export const getImageForTaskType = (status) => {
  const images = {
    planned: require('@shared/assets/tasks-statuses/planned-icon.svg').default,
    inProgress: require('@shared/assets/tasks-statuses/in-progress-icon.svg').default,
    underReview: require('@shared/assets/tasks-statuses/under-review-icon.svg').default,
    postImprovment: require('@shared/assets/tasks-statuses/post-improvment-icon.svg').default,
    done: require('@shared/assets/tasks-statuses/done-icon.svg').default,
  };
  return images[status] || images.planned;
};